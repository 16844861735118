import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import { subadminstyles } from "../Styles/Contentmanagement_styles";
import Addsubadmin from "../Components/Addsubadmin";
import SubadminInfo from "../Components/SubadminInfo";
import Editsubadmin from "../Components/Editsubadmin";

const SubAdminManagement = () => {
  const subadminstyle = subadminstyles();
  const [editingSubAdmin, setEditingSubAdmin] = useState(null);
  const [isEditingMode, setIsEditingMode] = useState(false);

  const handleEdit = (subAdmin) => {
    setEditingSubAdmin(subAdmin);
    setIsEditingMode(true);
  };

  const handleCloseEdit = () => {
    setEditingSubAdmin(null);
    setIsEditingMode(false);
  };
const [subAdmins, setSubAdmins] = useState(false);
  // Function to handle adding a new subadmin
  const handleAddSubadmin = (newSubadmin) => {
    setSubAdmins(prev => prev ? false :true)
    console.log("New Subadmin added:", newSubadmin);
  };

  return (
    <Grid container maxWidth="xl" sx={{ marginTop: "2rem" }}>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <Grid container spacing={2}>
          <Grid item container spacing={1} md={6.3} p={1} className={subadminstyle.subadminsty} mt={0} ml={0}>
            <SubadminInfo onEdit={handleEdit} refresh={subAdmins} />
          </Grid>
          <Grid item md={5.5} mb={3} marginLeft={"-7px"}>
            {isEditingMode ? (
              <Editsubadmin subAdmin={editingSubAdmin} onClose={handleCloseEdit} onAddSubadmin={handleAddSubadmin}/>
            ) : (
              <Addsubadmin onAddSubadmin={handleAddSubadmin} />
            )}
          </Grid> 
        </Grid>
      </Box>
    </Grid>
  );
};

export default SubAdminManagement;
