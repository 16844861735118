import React from 'react'

const Packageicon = () => {
  return (
    <>
    <svg width="23" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2.61023 2.23755H16.3602L15.4202 1.23755H3.42023L2.61023 2.23755ZM18.0302 2.46755C18.3202 2.80755 18.4902 3.23755 18.4902 3.73755V16.2375C18.4902 16.768 18.2795 17.2767 17.9044 17.6518C17.5294 18.0268 17.0207 18.2375 16.4902 18.2375H2.49023C1.9598 18.2375 1.45109 18.0268 1.07602 17.6518C0.700948 17.2767 0.490234 16.768 0.490234 16.2375V3.73755C0.490234 3.23755 0.660234 2.80755 0.950234 2.46755L2.33023 0.787549C2.61023 0.447549 3.02023 0.237549 3.49023 0.237549H15.4902C15.9602 0.237549 16.3702 0.447549 16.6402 0.787549L18.0302 2.46755ZM3.49023 15.2375H9.49023V12.2375H3.49023V15.2375Z" fill="#FFFFFF"/>
</svg>
    </>
  )
}

export default Packageicon