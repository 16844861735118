export const color_exports = {
    primary_color: "#B8003E",
    input_color: "#F2F2F2",
    btntext_color: "#FFFFFF",
    tex_shade_color: "#333333",
    icon_color: "#9F9F9F",
    iconactive: "#FFFFFF",
    opacity_text_color: "#33333350",
    opacity_text_color2: "#33333380",
    user_details_color: "#333333",
};