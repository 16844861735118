import React, { useState, useEffect, useCallback, useRef } from "react";
import {
  Tabs,
  Tab,
  Grid,
  Typography,
  Menu,
  MenuItem,
  IconButton,
  Box,
} from "@mui/material";
import Modifiedtable from "../../Components/Modifiedtable";
import {
  castingcall_columns,
  regesteredcolumns,
  guestusers_columns,
  existinguserscolumns,
} from "../../Components/Tablecolums";
import Modifiedsearchbar from "../../Components/Modifiedsearchbar";
import ReactPaginate from "react-paginate";
import RightArrow from "../../Components/RightArrow";
import LeftArrow from "../../Components/LeftArrow";
import Modifiedbutton from "../../Components/Modifiedbutton";
import { color_exports } from "../../Styles/Color_exports";
import {
  getusers_api,
  getguestusers_api,
  getregisteredguestusers_api,
} from "../../Apiservices/Adminservices";
import { enqueueSnackbar } from "notistack";
import moment from "moment";
import { saveAs } from "file-saver";
import { debounce } from "lodash";
import Guestusertable from "../../Components/Guestusertable";
import { userdetail_styles } from "../../Styles/Adminuser_details_styles";
import dropdownicon from "../../assets/Images/dropdownicon.png";
import janatauser from "../../assets/Images/janatauser.jpg";
import BlockorUnblock from "../../Models/Block_or_Delet";
import axios from "axios";
import { StorageClient } from "../../Helpers/Helperfunctions";
import Existinguserslist from "../../Components/Existinguserslist";

const AdminUsermanagement = () => {
  const [tabledata, setTabledata] = useState([]);
  const [guestusertabledata, setGuestusertabledata] = useState([]);
  const [registeredtabledata, setRegisteredtabledata] = useState([]);
  const [existinguserstabledata, setExistinguserstabledata] = useState([]);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [searchText, setSearchText] = useState("");
  const [sortValue, setSortValue] = useState("serialNo");
  const [sortBy, setSortBy] = useState("DESC");
  const [tabIndex, setTabIndex] = useState(0);
  const [guestType, setGuestType] = useState("registered");
  const [anchorEl, setAnchorEl] = useState(null);
  const latestSearchText = useRef(searchText);
  const usermanagementstyle = userdetail_styles();
  const [blockPopupOpen, setBlockPopupOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [userCount, setUserCount] = useState(0);
  const [count, setCount] = useState(0);
  const [guestcount, setGuestcount] = useState(0);
  const [existingusercount, setExistingusercount] = useState(0);

  const calculateAge = (dob) => {
    const birthDate = moment(dob, "YYYY-MM-DD");
    const today = moment();
    const age = today.diff(birthDate, "years");
    return age;
  };

  const handleSort = (columnId) => {
    if (columnId === sortValue) {
      setSortBy(sortBy === "ASC" ? "DESC" : "ASC");
    } else {
      setSortValue(columnId);
      setSortBy(sortBy);
    }
  };

  const fetchUserData = useCallback(
    async (pagenumber) => {
      try {
        const req = {
          min: (pagenumber - 1) * pageSize,
          max: pageSize,
          sortValue: sortValue,
          sortBy: sortBy,
          type: 1,
        };

        if (latestSearchText.current.trim()) {
          req.searchText = latestSearchText.current.trim().toLowerCase();
        }

        const response = await getusers_api(req);

        if (response?.message === "Successfully retrived Users data") {
          const formattedData = response.data.rows.map((row, index) => {
            const imageUrl =
              row.profileImages.length && row.profileImages[0]?.image1
                ? `${response.data.imageBaseUrl}${row.profileImages[0].image1}`
                : janatauser;

            const packageStatus =
              moment(new Date()).utc().format("YYYY-MM-DD HH:mm:ss") >
              moment(row.endDate).format("YYYY-MM-DD HH:mm:ss")
                ? "Expired"
                : "Active";

            return {
              "S.NO": (pagenumber - 1) * pageSize + index + 1,
              ID: `JM00100${row.serialNo}`,
              Name: (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <img
                    src={imageUrl}
                    alt="Profile"
                    className={usermanagementstyle.prflimg}
                    onError={(e) => {
                      e.target.onerror = null; // Prevent infinite loop
                      e.target.src = janatauser; // Fallback to default static image
                    }}
                  />
                  <span>{row.firstName}</span>
                </div>
              ),
              Age: calculateAge(row.dateOfBirth),
              Gender: row.gender,
              Religion: row.religion || "Not specified",
              Createdon: moment(row.registrationDate).format("DD-MM-YYYY"),
              Location: row.permanentCity,
              Package: row.packageData?.packageTitle || "Not specified",
              PackageStatus: packageStatus,
              userId: row.userId,
              blockStatus: row.blockStatus,
            };
          });

          setTabledata(formattedData);
          setPageCount(Math.ceil(response.data.count / pageSize));
          setUserCount(response.data.count);
        } else {
          console.error("Failed to fetch users:", response?.message);
          enqueueSnackbar(response?.message || "Failed to fetch users", {
            variant: "error",
          });
        }
      } catch (err) {
        console.error("Error:", err);
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [pageSize, sortBy, sortValue]
  );

  const fetchGuestUserData = useCallback(
    async (pagenumber) => {
      try {
        const req = { min: (pagenumber - 1) * pageSize, max: pageSize };

        if (latestSearchText.current.trim()) {
          req.searchText = latestSearchText.current.trim().toLowerCase();
        }

        const response = await getguestusers_api(req);
        if (response?.message === "Successfully retrived Users data") {
          const formattedData = response.data.rows.map((row, index) => ({
            userId: row.id,
            "S.NO": (pagenumber - 1) * pageSize + index + 1,
            ID: `JM00100${row.serialNo}`,
            Name: `${row.name}`,
            Email: row.email,
            "Phone Number": row.phoneNumber || "Not available",
            Religion: row.religion || "Not available",
            "Looking For": row.lookingFor || "Not available",
            type: 2,
            blockStatus: row.blockStatus,
          }));
          setGuestusertabledata(formattedData);
          setGuestcount(response.data.count);
          setPageCount(Math.ceil(response.data.count / pageSize));
        } else {
          console.error("Failed to fetch guest users:", response?.message);
          enqueueSnackbar(response?.message || "Failed to fetch guest users", {
            variant: "error",
          });
        }
      } catch (err) {
        console.error("Error:", err);
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [pageSize]
  );

  const fetchRegisteredGuestUserData = useCallback(
    async (pagenumber) => {
      try {
        const req = {
          min: (pagenumber - 1) * pageSize,
          max: pageSize,
        };

        if (latestSearchText.current.trim()) {
          req.searchText = latestSearchText.current.trim().toLowerCase(); // Convert search text to lowercase
        }

        const response = await getregisteredguestusers_api(req);

        if (response?.message === "Successfully retrived Users data") {
          const formattedData = response.data.rows.map((row, index) => ({
            userId: row.userId,
            "S.NO": (pagenumber - 1) * pageSize + index + 1,
            ID: `JM00100${row.serialNo}`,
            Name: `${row.firstName}${row.lastName ? ` ${row.lastName}` : ""}`,
            Email: row.email,
            "Phone Number": row.phoneNumber || "Not available",
            Religion: row.religion || "Not available",
            "Looking For": row.lookingFor || "Not available",
            type: 1,
            blockStatus: row.blockStatus,
          }));
          setRegisteredtabledata(formattedData);
          setCount(response.data.count);
          setPageCount(Math.ceil(response.data.count / pageSize));
        } else {
          console.error(
            "Failed to fetch registered guest users:",
            response?.message
          );
          enqueueSnackbar(
            response?.message || "Failed to fetch registered guest users",
            { variant: "error" }
          );
        }
      } catch (err) {
        console.error("Error:", err);
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [pageSize]
  );

  const fetchExistingUserData = useCallback(
    async (pagenumber) => {
      try {
        const req = {
          min: (pagenumber - 1) * pageSize,
          max: pageSize,
        };

        if (latestSearchText.current.trim()) {
          req.searchText = latestSearchText.current.trim().toLowerCase();
        }

        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/getAllExistingUsers`,
          req,
          {
            headers: {
              Authorization: `Bearer ${StorageClient.getItem("token")}`,
            },
          }
        );

        if (
          response?.data?.message === "Existing Users retrieved successFully"
        ) {
          const formattedData = response.data.data.rows.map((row, index) => ({
            "S.NO": (pagenumber - 1) * pageSize + index + 1, // Serial number
            Email: row.email || "Not available", // Email field
            "Created On": moment(row.createdAt).format("DD-MM-YYYY"),
          }));

          setExistinguserstabledata(formattedData); // Set table data
          setExistingusercount(response.data.data.count); // Set count
          setPageCount(Math.ceil(response.data.data.count / pageSize)); // Set pagination count
        } else {
          enqueueSnackbar(
            response?.message || "Failed to fetch existing users",
            { variant: "error" }
          );
        }
      } catch (err) {
        console.error("Error fetching existing users:", err);
        enqueueSnackbar(err.message, { variant: "error" });
      }
    },
    [pageSize]
  );

  const debouncedFetchData = useCallback(
    debounce((pagenumber) => {
      if (tabIndex === 0) {
        fetchUserData(pagenumber);
      } else if (guestType === "registered") {
        fetchRegisteredGuestUserData(pagenumber);
      } else if (guestType === "notregistered") {
        fetchGuestUserData(pagenumber);
      } else if (guestType === "existing") {
        fetchExistingUserData(pagenumber);
      }
    }, 500),
    [
      fetchUserData,
      fetchGuestUserData,
      fetchRegisteredGuestUserData,
      fetchExistingUserData,
      tabIndex,
      guestType,
    ]
  );

  const handleSearch = () => {
    setPageNumber(1); // Ensure that the page number is reset to 1
    debouncedFetchData(1); // Fetch data for the first page
  };

  const handlePageClick = (data) => {
    const selectedPage = data.selected + 1;
    setPageNumber(selectedPage); // Update the pageNumber state to reflect the selected page
    if (tabIndex === 0) {
      fetchUserData(selectedPage);
    } else if (guestType === "registered") {
      fetchRegisteredGuestUserData(selectedPage);
    } else if (guestType === "notregistered") {
      fetchGuestUserData(selectedPage);
    } else if (guestType === "existing") {
      fetchExistingUserData(selectedPage);
    }
  };

  useEffect(() => {
    latestSearchText.current = searchText;
    debouncedFetchData(pageNumber); // Fetch data based on the current page number
  }, [searchText, debouncedFetchData, pageNumber]); // Add pageNumber to the dependency array

  useEffect(() => {
    if (tabIndex === 0) {
      fetchUserData(pageNumber); // For regular user data
    } else if (guestType === "registered") {
      fetchRegisteredGuestUserData(pageNumber); // For registered guest users
    } else if (guestType === "notregistered") {
      fetchGuestUserData(pageNumber); // For guest users
    } else if (guestType === "existing") {
      fetchExistingUserData(pageNumber); // For existing users
    }
  }, [
    pageNumber,
    sortValue,
    sortBy,
    fetchUserData,
    fetchGuestUserData,
    fetchRegisteredGuestUserData,
    fetchExistingUserData, // Add fetchExistingUserData to the dependency array
    tabIndex,
    guestType,
  ]);

  const handleDownloadClick = async () => {
    // Get the authorization token from local storage or your auth system
    const token = StorageClient.getItem("token"); // Replace with your method of fetching the token

    try {
      // Make API request to download file
      const backendUrl = process.env.REACT_APP_BACKEND_URL; // Use env variable for backend URL

      const response = await axios.get(
        `${backendUrl}/downloadUserCsvData`, // Dynamic backend URL
        {
          headers: {
            Authorization: `${token}`, // Use the token from local storage
          },
          responseType: "blob", // Ensure the response type is blob for file downloads
        }
      );

      // Check if the response is successful
      if (response.status === 200) {
        // Create a Blob from the response data
        const blob = new Blob([response.data], {
          type: "text/csv;charset=utf-8",
        });
        // Save the Blob as a file
        saveAs(blob, "users-data.csv");
        enqueueSnackbar("File downloaded successfully", { variant: "success" });
      } else {
        // Handle non-200 status codes
        console.error(
          `Failed to download file. Status: ${response.status} - Status Text: ${response.statusText}`
        );
        enqueueSnackbar(`Failed to download data: ${response.statusText}`, {
          variant: "error",
        });
      }
    } catch (error) {
      // Handle any errors during the API call
      console.error("Error during download:", error);
      enqueueSnackbar(
        `Failed to download data: ${error.message || "Unknown error"}`,
        { variant: "error" }
      );
    }
  };
  const handleGuestTypeChange = (type) => {
    setGuestType(type);
    setAnchorEl(null);
    setPageNumber(1);

    if (type === "registered") {
      fetchRegisteredGuestUserData(1); // Fetch registered guest users
    } else if (type === "notRegistered") {
      fetchGuestUserData(1); // Fetch non-registered guest users
    } else if (type === "existing") {
      fetchExistingUserData(1); // Fetch only existing users
    }
  };

  const handleBlockOrUnblock = (row) => {
    setSelectedRow(row);
    setBlockPopupOpen(true);
  };

  const handleBlockOrUnblockClose = () => {
    setSelectedRow(null);
    setBlockPopupOpen(false);
  };

  const handleDownloadClick1 = async () => {
    const token = StorageClient.getItem("token"); // Replace with your method of token retrieval

    try {
      const backendUrl = process.env.REACT_APP_BACKEND_URL;
      const userType = guestType === "registered" ? 1 : 2; // Determine type dynamically

      const response = await axios.post(
        `${backendUrl}/downloadGuestUsersCsvData`, // POST request
        { type: userType }, // Send type in the body
        {
          headers: {
            Authorization: `Bearer ${token}`, // Attach token in Authorization header
          },
          responseType: "blob", // Expect a blob response for file download
        }
      );

      if (response.status === 200) {
        const blob = new Blob([response.data], {
          type: "text/csv;charset=utf-8",
        });
        saveAs(blob, "users-data.csv");
        enqueueSnackbar("File downloaded successfully", { variant: "success" });
      } else {
        console.error(
          `Failed to download file. Status: ${response.status} - Status Text: ${response.statusText}`
        );
        enqueueSnackbar(`Failed to download data: ${response.statusText}`, {
          variant: "error",
        });
      }
    } catch (error) {
      console.error("Error during download:", error);
      enqueueSnackbar(
        `Failed to download data: ${error.message || "Unknown error"}`,
        { variant: "error" }
      );
    }
  };

  return (
    <>
      <Grid item container justifyContent="space-between" alignItems="center">
        <Grid item>
          <Tabs
            value={tabIndex}
            onChange={(event, newValue) => setTabIndex(newValue)}
            aria-label="User management tabs"
            className={usermanagementstyle.tabstyles}
          >
            <Tab label="Members" />
            <Tab
              label={
                <Box display="flex" alignItems="center">
                  {guestType === "registered"
                    ? "Guest Registered Users"
                    : guestType === "existing"
                    ? "Existing Users"
                    : "Guest Users"}

                  <IconButton
                    aria-controls="guest-menu"
                    aria-haspopup="true"
                    onClick={(e) => setAnchorEl(e.currentTarget)}
                    size="small"
                  >
                    <img src={dropdownicon} alt="dropdownicon" />
                  </IconButton>
                </Box>
              }
            />
          </Tabs>
          <Menu
            id="guest-menu"
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={() => setAnchorEl(null)}
          >
            <MenuItem onClick={() => handleGuestTypeChange("registered")}>
              Guest Registered Users
            </MenuItem>
            <MenuItem onClick={() => handleGuestTypeChange("notRegistered")}>
              Guest Users
            </MenuItem>
            {/* <MenuItem onClick={() => handleGuestTypeChange("existing")}>
              Existing Users
            </MenuItem> */}
          </Menu>
        </Grid>
        <Grid item container md="auto" gap={2}>
          <Grid item>
            {tabIndex === 0 ? (
              <Modifiedsearchbar
                placeholder="Search with Name, JMID, Location"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            ) : (
              <Modifiedsearchbar
                placeholder="Search with Name, Email, Phone Number"
                value={searchText}
                onChange={(e) => setSearchText(e.target.value)}
              />
            )}
          </Grid>
          <Grid item>
            <Modifiedbutton
              data={"Search"}
              sheight="2.5rem"
              swidth="1rem"
              onClick={handleSearch}
            />
          </Grid>
        </Grid>
        <Grid item container md justifyContent="end">
          {tabIndex === 0 ? (
            <Modifiedbutton data={"Download as excel"} sheight="2.5rem" swidth="11rem" onClick={handleDownloadClick} />
          ) : guestType !== "existing" ? ( // Hide button if guestType is "existing"
            <Modifiedbutton data={"Download as Excel"} sheight="2.5rem" swidth="11rem" onClick={handleDownloadClick1} />
          ) : null}
        </Grid>
      </Grid>
      {tabIndex === 0 && (
        <Grid container direction="column">
          <Grid item mt={2} sx={{ maxWidth: "100%!important" }}>
            <Modifiedtable rows={tabledata} columns={castingcall_columns} maxheight="65vh" pageNumber={pageNumber}
              pageSize={pageSize}
              handleBlockOrUnblock={handleBlockOrUnblock}
              handleSort={handleSort}
              sortValue={sortValue}
              sortBy={sortBy}
            />
          </Grid>
          <Typography variant="h6" style={{ marginTop: "20px" }}>
            Total Users Count: {userCount}
          </Typography>
          {pageCount > 1 && (
            <Grid
              item
              container
              justifyContent={"center"}
              textAlign={"center"}
              sx={{
                "& .pageClassName": {
                  backgroundColor: color_exports.btntext_color,
                },
                "& .activeClassName": {
                  backgroundColor: color_exports.primary_color,
                },
              }}
            >
              <ReactPaginate
                breakLabel="..."
                nextLabel={<RightArrow />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<LeftArrow />}
                renderOnZeroPageCount={null}
                containerClassName="mainPageCont"
                activeClassName="activeClassName"
                activeLinkClassName="activeLinkClassName"
                pageClassName="pageClassName"
                forcePage={pageNumber - 1}
              />
            </Grid>
          )}
        </Grid>
      )}

      {tabIndex === 1 && (
        <Grid container direction="column">
          <Grid item mt={2} sx={{ maxWidth: "100%!important" }}>
            {guestType === "registered" ? (
              <div>
                {/* Guest Registered Users */}
                <Guestusertable
                  rows={registeredtabledata}
                  columns={regesteredcolumns}
                  maxheight="65vh"
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                />
                <Typography variant="h6" style={{ marginTop: "20px" }}>
                  Total Guest Registered Users Count: {count}
                </Typography>
              </div>
            ) : guestType === "notRegistered" ? (
              <div>
                {/* Guest Users */}
                <Guestusertable
                  rows={guestusertabledata}
                  columns={guestusers_columns}
                  maxheight="65vh"
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                />
                <Typography variant="h6" style={{ marginTop: "20px" }}>
                  Total Guest Users Count: {guestcount}
                </Typography>
              </div>
            ) : guestType === "existing" ? (
              <div>
                {/* Existing Users */}
                <Existinguserslist
                  rows={existinguserstabledata}
                  columns={existinguserscolumns}
                  maxheight="65vh"
                  pageNumber={pageNumber}
                  pageSize={pageSize}
                />
                <Typography variant="h6" style={{ marginTop: "20px" }}>
                  Total Existing Users Count: {existingusercount}
                </Typography>
              </div>
            ) : null}
          </Grid>

          {/* Pagination */}
          {pageCount > 1 && (
            <Grid
              item
              container
              justifyContent={"center"}
              textAlign={"center"}
              sx={{
                "& .pageClassName": {
                  backgroundColor: color_exports.btntext_color,
                },
                "& .activeClassName": {
                  backgroundColor: color_exports.primary_color,
                },
              }}
            >
              <ReactPaginate
                breakLabel="..."
                nextLabel={<RightArrow />}
                onPageChange={handlePageClick}
                pageRangeDisplayed={5}
                pageCount={pageCount}
                previousLabel={<LeftArrow />}
                renderOnZeroPageCount={null}
                containerClassName="mainPageCont"
                activeClassName="activeClassName"
                activeLinkClassName="activeLinkClassName"
                pageClassName="pageClassName"
                forcePage={pageNumber - 1}
              />
            </Grid>
          )}
        </Grid>
      )}

      <BlockorUnblock
        open={blockPopupOpen}
        onClose={handleBlockOrUnblockClose}
        row={selectedRow}
      />
    </>
  );
};

export default AdminUsermanagement;
