// Envexports.js
export const Envexports = {
  baseurl: process.env.REACT_APP_BACKEND_URL,
  defaultauthkey: process.env.REACT_APP_DEFAULT_AUTHKEY,
  imageurl: process.env.REACT_APP_IMAGE_URL,
  firebase_apikey: process.env.REACT_APP_FIREBASE_APIKEY,
  firebase_authdomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
  firebase_projectid: process.env.REACT_APP_FIREBASE_PROJECTID,
  firebase_storagebucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
  firebase_messagesenderid: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
  firebase_appid: process.env.REACT_APP_FIREBASE_APPID,
  // firebase_measurementid: process.env.REACT_APP_FIREBASE_MEASUREMENTID,
};
