import React, { useEffect, useState } from "react";
import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Container,
  Drawer,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Stack,
  Toolbar,
  Menu,
  MenuItem,
} from "@mui/material";
import { NavLink, useLocation, useNavigate, Outlet } from "react-router-dom";
import NotificationsRoundedIcon from "@mui/icons-material/NotificationsRounded";
import { adminsidebar_val } from "../Components/Adminlist";
import logouticon from "../assets/Images/logouticon.svg";
import sidebarlogo from "../assets/Images/logowithtm.svg";
import defaultAvatar from "../assets/Images/Avatar.svg"; // Default avatar image
import { adminsidebar_style } from "../Styles/Adminsidebarstyles";
import { color_exports } from "../Styles/Color_exports";
import Logout from "../Models/Logout";
import { useSelector } from "react-redux";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';

const Adminlayout = () => {
  const sidebar_styles = adminsidebar_style();
  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(true);
  const [logout, setLogout] = useState(false);
  const [currentMenuItem, setCurrentMenuItem] = useState("");
  const [anchorEl, setAnchorEl] = useState(null);
  const [previousPage, setPreviousPage] = useState("/admin"); // Default to dashboard
  const [profilePic, setProfilePic] = useState(defaultAvatar); // Default avatar

  const admin_data = useSelector((state) => state?.authentication?.data);

  const successStoriesStatus = admin_data?.preferences?.successStories || 0;
  const successStoriesPrivilege = admin_data?.privileges?.successStories || 0;

  const getCurrentMenuItemName = () => {
    switch (location.pathname) {
      case "/admin/userdetails":
        return "User Details";
      case "/admin/notifications":
        return "Notifications";
      default:
        const selectedItem = adminsidebar_val.find(
          (item) => item.link === location.pathname
        );
        return selectedItem ? selectedItem.name : "Dashboard";
    }
  };

  useEffect(() => {
    if (admin_data?.profilePic) {
      setProfilePic(`${admin_data?.imageBaseUrl}${admin_data?.profilePic}`);
    }
    if (location.pathname !== "/admin/notifications") {
      setPreviousPage(location.pathname);
    }
    setCurrentMenuItem(getCurrentMenuItemName());
  }, [location.pathname, admin_data]);

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    setLogout(true);
    handleMenuClose();
  };

  const handleSettings = () => {
    navigate("/admin/settings");
    handleMenuClose();
  };

  const handleBackButtonClick = () => {
    if (location.pathname === "/admin/userdetails") {
      navigate("/admin/userManagement");
    } else {
      navigate(previousPage);
    }
  };
  // useEffect(() => {
  //   // Check privileges for the current path
  //   const checkAccess = () => {
  //     if (!admin_data?.isUserAdmin) {
  //       const currentPage = adminsidebar_val.find(
  //         (item) => item.link === location.pathname
  //       );
  
  //       // If the current page is not allowed, redirect to the first allowed page
  //       if (
  //         !currentPage || !(admin_data?.privileges[currentPage.privilagename] ?? 0)
  //       ) {
  //         const firstAllowedPage = adminsidebar_val.find(
  //           (item) => admin_data?.privileges[item.privilagename] === 1
  //         );
  
  //         // Redirect to the first allowed page
  //         if (firstAllowedPage) {
  //           navigate(firstAllowedPage.link);
  //         }
  //       }
  //     }
  //   };
  
  //   checkAccess();
  // }, [location.pathname, admin_data, navigate]);
  
  return (
    <>
      <Logout open={logout} close={() => setLogout(false)} />
      <Box sx={{ display: "flex" }}>
        <AppBar position="fixed" open={open} sx={{ backgroundColor: color_exports.tex_shade_color }}>
          <Toolbar>
            <Grid container justifyContent={"space-between"} alignItems="center">
              <Grid container item md={!open ? 10.5 : 10} sm={9}>
                <Grid item sx={{ marginLeft: "250px", paddingTop: "0.5rem", display: "flex", alignItems: "center" }}>
                  {(location.pathname === "/admin/userdetails" || location.pathname === "/admin/notifications") && (
                    <ArrowBackIcon sx={{ cursor: "pointer", color: color_exports.btntext_color, marginRight: "8px" }} onClick={handleBackButtonClick} />
                  )}
                  <span style={{ color: color_exports.btntext_color, fontSize: "16px", fontWeight: 700 }}>
                    {currentMenuItem}
                  </span>
                </Grid>
              </Grid>
              <Grid item container md={1} sm={3} justifyContent={"space-between"} alignItems={"center"}>
                <Grid item>
                  {(successStoriesStatus === 1 ||
                    successStoriesPrivilege === 1) && (
                    <Grid item>
                      <Badge badgeContent={0} color="primary" onClick={() => navigate("/admin/notifications")}>
                        <NotificationsRoundedIcon sx={{ color: color_exports.btntext_color }} />
                      </Badge>
                    </Grid>
                  )}
                </Grid>

                <Grid item>
                  <Stack direction={"row"} alignItems={"center"}>
                    <Avatar src={profilePic} onClick={handleMenuClick} sx={{ cursor: "pointer" }} />
                  </Stack>
                  <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                    {admin_data?.isUserAdmin && (
                      <MenuItem onClick={handleSettings}>Settings</MenuItem>
                    )}
                    <MenuItem onClick={handleLogout}>Logout</MenuItem>
                  </Menu>
                </Grid>
              </Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={true} className={sidebar_styles.admindrawer}>
          <span className={sidebar_styles.sidebarlogo}>
            <img src={sidebarlogo} alt="sidebarlogo" width={"225px"} />
          </span>
          <List className={sidebar_styles.adminlist}>
            {adminsidebar_val.map((text, index) => {
              let conditional_check = !admin_data?.isUserAdmin
                ? admin_data?.privileges[text.privilagename] ?? 0
                : 1;

              const isUserManagement = text.link === "/admin/userManagement";
              const isActive = location.pathname.startsWith("/admin/userdetails") || location.pathname === text.link;

              const isNotificationsPage = location.pathname === "/admin/notifications" && text.link === previousPage;

              return (
                conditional_check && (
                  <NavLink
                    key={text.name}
                    to={text.link}
                    className={({ isActive: active }) =>
                      (isUserManagement && isActive) || isNotificationsPage || (active && !isUserManagement)
                        ? sidebar_styles.active
                        : sidebar_styles.inactive
                    }
                    end
                  >
                    <ListItem key={text.name} disablePadding sx={{ display: "block" }}>
                      <ListItemButton
                        sx={{
                          minHeight: 48,
                          justifyContent: "center",
                          px: 2.5,
                        }}
                      >
                        <ListItemIcon
                          sx={{
                            minWidth: 0,
                            mr: 0,
                            justifyContent: "center",
                            color:
                              isActive || isNotificationsPage
                                ? color_exports.iconactive
                                : color_exports.icon_color,
                          }}
                        >
                          {text.icon}
                        </ListItemIcon>
                        <ListItemText primary={text.name} sx={{ marginLeft: "10px" }} />
                      </ListItemButton>
                    </ListItem>
                  </NavLink>
                )
              );
            })}
            <Grid item textAlign={"center"} mt={4}>
              <span className={sidebar_styles.inactive}>
                {" "}
                {/* V 1.1.22 , API V 1.1.8{" "} */}
              </span>
            </Grid>
            <Grid container className={sidebar_styles.logoutgrid} sx={{ cursor: "pointer" }} onClick={() => setLogout(true)}>
              <img src={logouticon} alt="logout" />
              <span style={{ marginLeft: "10px" }} className={sidebar_styles.inactive}>
                Logout
              </span>
            </Grid>
          </List>
        </Drawer>
        <Box component="main" className={sidebar_styles.mainoutlet}>
          <Container maxWidth="xl" sx={{ paddingTop: "1rem", paddingRight: "0rem !important" }}>
            <Outlet />
          </Container>
        </Box>
      </Box>
    </>
  );
};

export default Adminlayout;
