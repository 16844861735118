export const Regex_exports = {
    emailRegex: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
    passwordRegex:
      /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/,
    string_pattern: /[0-9~`!@#$%^&*()\-_=+\[\]{}\\|;:'",.<>\/?]/,
    mobile_regex: /^\d+$/,
    comma_seperated_regex: /^[a-zA-Z, ]*$/,
    extra_space: /\s+/g,
    numberRegex: /^[0-9]+$/,
    urlRegex: /\b(?:https?:\/\/|www\.)[\w-]+(?:\.[\w-]+)*(\/[^"'\s]*)?\b/g,
  };
  