import React from "react";

const Badge = () => {
  return (
    <>
      <svg
        width="16"
        height="20"
        viewBox="0 0 16 20"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M6.64534 10.4493L7.68034 9.66347L8.70576 10.4397C9.0795 10.7176 9.58742 10.363 9.45326 9.91263L9.05076 8.6093L10.2008 7.69888C10.5553 7.43055 10.3541 6.85555 9.89409 6.85555H8.55242L8.14034 5.57138C8.10883 5.47552 8.04786 5.39205 7.96612 5.33287C7.88438 5.27369 7.78604 5.24184 7.68513 5.24184C7.58422 5.24184 7.48588 5.27369 7.40414 5.33287C7.3224 5.39205 7.26143 5.47552 7.22992 5.57138L6.80826 6.85555H5.45701C5.00659 6.85555 4.80534 7.43055 5.15992 7.70847L6.30034 8.61888L5.89784 9.92222C5.76367 10.3726 6.27159 10.7272 6.64534 10.4493ZM1.93034 18.9401C1.93034 19.5918 2.57242 20.0518 3.19534 19.8505L7.68034 18.3555L12.1653 19.8505C12.3097 19.8998 12.4638 19.9137 12.6146 19.8913C12.7655 19.8689 12.9088 19.8107 13.0326 19.7216C13.1564 19.6325 13.2571 19.515 13.3263 19.3791C13.3955 19.2432 13.4312 19.0927 13.4303 18.9401V12.8739C14.666 11.4781 15.3479 9.67809 15.347 7.81388C15.347 3.57805 11.9162 0.147217 7.68034 0.147217C3.44451 0.147217 0.0136719 3.57805 0.0136719 7.81388C0.0136719 9.7593 0.742005 11.5226 1.93034 12.8739V18.9401ZM7.68034 2.06388C10.8524 2.06388 13.4303 4.6418 13.4303 7.81388C13.4303 10.986 10.8524 13.5639 7.68034 13.5639C4.50826 13.5639 1.93034 10.986 1.93034 7.81388C1.93034 4.6418 4.50826 2.06388 7.68034 2.06388Z"
          fill="#FBC504"
        />
      </svg>
    </>
  );
};

export default Badge;
