/** @format */

import {
    Dialog,
    DialogContent,
    Grid,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  import Modifiedbutton 
 from "../Components/Modifiedbutton";
  import EastIcon from "@mui/icons-material/East";
  import WestIcon from "@mui/icons-material/West";
  import CancelIcon from "@mui/icons-material/Cancel";
  
  const FullViewMedia = ({ open, close, data, selectedIndex }) => {
    // let mediaType = data?.type ? data?.type : data?.contentType === 2 ? 1 : 2;
    // let mediaUrl = data?.contentType ? data?.fileUrl : "";
    const [media, setMedia] = useState({
      mediaType: 0,
      mediaUrl: "",
      mediaPosition: null,
    });
  
    const handlePrevNext = (type) => {
      if (type === 1) {
        setMedia((prev) => ({
          ...prev,
          mediaType: data[prev?.mediaPosition - 1]?.type,
          mediaUrl: data[prev?.mediaPosition - 1]?.url,
          mediaPosition: prev?.mediaPosition - 1,
        }));
      } else if (type === 2) {
        setMedia((prev) => ({
          ...prev,
          mediaType: data[prev?.mediaPosition + 1]?.type,
          mediaUrl: data[prev?.mediaPosition + 1]?.url,
          mediaPosition: prev?.mediaPosition + 1,
        }));
      }
    };
  
    useEffect(() => {
      if (selectedIndex === "youtube") {
        setMedia((prev) => ({
          ...prev,
          mediaType: 3,
          mediaUrl: data,
        }));
      } else if (data?.length > 0) {
        setMedia((prev) => ({
          ...prev,
          mediaType: data[selectedIndex]?.type,
          mediaUrl: data[selectedIndex]?.url,
          mediaPosition: selectedIndex,
        }));
      } else {
        setMedia((prev) => ({
          ...prev,
          mediaType: data?.contentType,
          mediaUrl: data?.contentType ? data?.fileUrl : "",
        }));
      }
    }, []);
  
    return (
      <>
        <Dialog
          fullWidth={true}
          maxWidth={media?.mediaType === 3 ? "md" : "lg"}
          open={open}
          onClose={close}
          sx={{
            "& .MuiPaper-root": {
              display: "flex",
              flexDirection: "row",
              overflow: "hidden",
            },
          }}
        >
          {/* <HighlightOffIcon
            sx={{
              color: "rgba(0, 0, 0, 0.6)",
              position: "absolute",
              right: 0,
              top: 0,
              cursor: "pointer",
              zIndex: 100,
            }}
            onClick={close}
          /> */}
          <CancelIcon
            sx={{
              // width: "1.2rem",
              color: "#B8003E",
              position: "absolute",
              right: 0,
              top: 0,
              cursor: "pointer",
              zIndex: 100,
            }}
            onClick={close}
          />
          <DialogContent sx={{ padding: 0, overflow: "hidden" }}>
            <>
              {media?.mediaType === "image" ? (
                <img
                  src={media?.mediaUrl}
                  alt=""
                  style={{
                    minWidth: "100%",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                />
              ) : media?.mediaType === "video" ? (
                <video
                  src={media?.mediaUrl}
                  alt=""
                  style={{
                    minWidth: "100%",
                    maxWidth: "100%",
                    maxHeight: "100%",
                    objectFit: "contain",
                  }}
                  controls
                ></video>
              ) : media?.mediaType === 3 ? (
                <Grid
                  sx={{
                    position: "relative",
                    paddingBottom: "56.25%",
                    height: 0,
                    overflow: "hidden",
                    "& iframe": {
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    },
                  }}
                >
                  <iframe
                    src={media?.mediaUrl}
                    allowFullScreen
                    style={{
                      width: "100%",
                      height: "100%",
                      border: "0px",
                      objectFit: "contain",
                    }}
                  ></iframe>
                </Grid>
              ) : null}
              {data?.length > 0 && selectedIndex !== "youtube" ? (
                <Grid
                  container
                  justifyContent={"center"}
                  gap={1}
                  sx={{
                    position: "absolute",
                    left: "0rem",
                    bottom: "1rem",
                    width: "fit-content",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}
                >
                  <Modifiedbutton 

                    data={"Previous"}
                    onClick={() => handlePrevNext(1)}
                    disabled={media?.mediaPosition === 0}
                    bgcolor={media?.mediaPosition === 0 ? "#7be0ff" : undefined}
                    startIcon={<WestIcon />}
                    swidth={"7rem"}
                  />
                  <Modifiedbutton 

                    data={"Next"}
                    onClick={() => handlePrevNext(2)}
                    disabled={media?.mediaPosition === data?.length - 1}
                    bgcolor={
                      media?.mediaPosition === data?.length - 1
                        ? "#7be0ff"
                        : undefined
                    }
                    endIcon={<EastIcon />}
                    swidth={"7rem"}
                  />
                </Grid>
              ) : null}
            </>
          </DialogContent>
        </Dialog>
      </>
    );
  };
  
  export default FullViewMedia;
  