import { makeStyles } from "@mui/styles";
import { styled } from "@mui/material";
import Switch from "@mui/material/Switch";
import { color_exports } from "./Color_exports";
const input_radius='0.5rem';
export const custominputstyles = makeStyles({
    
    logininputstyle:{
        '& .MuiOutlinedInput-root':{
          borderRadius:`${input_radius}`,
          '& fieldset': {
            border: 'none',
         },
        },
        border:'none!important',
    },
    
})
export const IOSSwitch = styled(Switch)(({ theme,checked, ...props }) => (
  
  {
  width: 38,
  height: props?.height ? props?.height : 22,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#9F9F9F' : '#B8003E',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 18,
    height: 18,
  },
  '& .MuiSwitch-track': {
    borderRadius: 22 / 2,
    backgroundColor: theme.palette.mode === 'light' ? '#9F9F9F' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));

export const chipiconstyle = makeStyles({

  userchat: {
    // padding: "10px",
    display: "flex",
    alignItems: "center",
    gap: "10px",
    color: "black",
    cursor: "pointer",
    marginLeft: "-16px",
    paddingLeft: "16px",

    "&:hover": {
      backgroundColor: "#F1F1F1",

    },
    "&:active": {
      backgroundColor: "#F1F1F1",
    }
  },
  chipStyles: {
    background: "#B8003E !important",
    color: "#ffff !important",
    minWidth: "24px",
    height: "24px !important",
    position: "absolute",
    right: "0%",
    "& span": {
      padding: "0px",
      paddingLeft: "0px",
      paddingRight: "0px",
      margin: "0.3rem",
    },
  },
})
export const livechatstyle = makeStyles({
  liveStyles: {
    color: "#ffff !important",
    height: "30px !important",
    minWidth: "30px",
    background: "#B8003E !important",
    borderRadius: "50%",
    padding: "8px",
    margin: "0px"
  },
})
export const chatboxstyle = makeStyles({
  chatStyles: {
    backgroundColor: "#B8003E !important",
    color: "#ffff !important",
    margin: "0rem !important",
    paddingTop: "0.5rem !important",
    paddingLeft: "0.5rem !important",
    borderTopLeftRadius: "0.5rem",
    borderTopRightRadius: "0.5rem"
  },
})

export const browserfiles_styles = makeStyles({
  lable: {
    fontSize: "14px",
    fontWeight: 700,
    "@media screen and (max-width: 630px) and (min-width: 600px)": {
      fontSize: "14px",
    },
    "@media screen and (max-width: 400px)": {
      fontSize: "14px",
    },
  },
  subhead: {
    fontSize: "30px",
    fontWeight: 700,
  },
  browsebtn: {
    background: `${color_exports.primary_color}`,
    borderRadius: "2rem",
    color: `${color_exports.btntext_color}`,
    padding: "0.5rem 1rem",
    margin: "1rem",
    // width: "108px",
    // height: "36px",
    fontSize: "16px",
    textAlign: "center",
    fontWeight: "500",
    cursor: "pointer",
    "@media screen and (max-width: 599px)": {
      // width: "90px",
      margin: "0rem",
      fontSize: "12px",
    },
  },
  dragtext: {
    "@media screen and (max-width: 390px)": {
      fontSize: "12px",
    },
  },
});
export const userchatstyle= makeStyles({

  userchat: {
    // padding: "10px",
    display: "flex",
    alignItems: "center",
    // gap: "10px",
    color: "black",
    cursor: "pointer",
    marginLeft: "-16px",
    // paddingLeft: "16px",

    "&:hover": {
      backgroundColor: "#F1F1F1",

    },
    "&:active": {
      backgroundColor: "#F1F1F1",
    }
  },
  chipStyles: {
    background: "#B8003E !important",
    color: "#ffff !important",
    minWidth: "24px",
    height: "24px !important",
    position: "absolute",
    right: "0%",
    "& span": {
      padding: "0px",
      paddingLeft: "0px",
      paddingRight: "0px",
      margin: "0.3rem",
    },
  },
})