import React, { useState } from "react";
import { Grid, Paper, Stack, InputAdornment } from "@mui/material";
import Modifiedinputfield from "../Components/Modifiedinputfield";
import { color_exports } from "../Styles/Color_exports";
import Modifiedbutton from "../Components/Modifiedbutton";
import { admin_changepwd } from "../Apiservices/Adminservices";
import Vieweye from "../Components/Vieweye";
import Hideeye from "../Components/Hideeye";
import { enqueueSnackbar } from "notistack";
import { reporteditorstyles } from "../Styles/Contentmanagement_styles";
import { useSelector } from "react-redux";

const Changepwd = ({ email }) => {
  const reporteditorstyle = reporteditorstyles();
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [oldPasswordVisible, setOldPasswordVisible] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const admin_mail = useSelector((state) => state.authentication.email);
  const admin_data = useSelector((state) => state.authentication.data);

  const handleOldPasswordChange = (event) => {
    setOldPassword(event.target.value);
  };

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  const isStrongPassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    return regex.test(password);
  };

  const handlechangepwd = async () => {
    try {
      if (!oldPassword) {
        enqueueSnackbar("The field Old Password is not allowed to be empty", { variant: "error" });
        return;
      }
      if (!newPassword) {
        enqueueSnackbar("The field New Password is not allowed to be empty", { variant: "error" });
        return;
      }
      if (!confirmPassword) {
        enqueueSnackbar("The field Confirm Password is not allowed to be empty", { variant: "error" });
        return;
      }
      if (!isStrongPassword(newPassword)) {
        enqueueSnackbar("Please enter a strong password that contains numbers, alphabets, special characters, and is at least 8 characters long.", { variant: "error" });
        return;
      }
      if (newPassword === oldPassword) {
        enqueueSnackbar("The new password you had entered is already in use", { variant: "error" });
        return;
      }
      if (newPassword !== confirmPassword) {
        enqueueSnackbar("Passwords do not match", { variant: "error" });
        return;
      }

      const req = { email: admin_mail, oldPassword: oldPassword, newPassword: newPassword };
      const changepwdres = await admin_changepwd(req);

      if (changepwdres?.message === "Successfully changed password") {
        enqueueSnackbar(changepwdres?.message, { variant: "success" });
        // Clear the input fields after successful password change
        setOldPassword("");
        setNewPassword("");
        setConfirmPassword("");
      } else {
        console.log("Reset password failed:", changepwdres?.message);
        enqueueSnackbar(changepwdres?.message, { variant: "error" });
      }
    } catch (err) {
      console.error("Error:", err);
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    }
  };

  return (
    <>
      <Paper elevation={5} className={reporteditorstyle.searchpaper}>
        <Grid container direction={"column"} spacing={2}>
          <Grid
            item
            fontSize={"16px"}
            sx={{ color: color_exports.primary_color }}
          >
            Change Password
          </Grid>
          <Grid item>
            <Stack spacing={1}>
              <Grid
                item
                fontSize={"14px"}
                sx={{ color: color_exports.primary_color }}
              >
                Old Password
              </Grid>
              <Grid item>
                <Modifiedinputfield
                  size={"medium"}
                  placeholder="Enter here"
                  name="oldPassword"
                  value={oldPassword}
                  onChange={handleOldPasswordChange}
                  style={{ width: "365px" }}
                  type={oldPasswordVisible ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() =>
                          setOldPasswordVisible(!oldPasswordVisible)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {oldPasswordVisible ? <Vieweye /> : <Hideeye />}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Stack>
          </Grid>
          {/* New Password */}
          <Grid item>
            <Stack spacing={1}>
              <Grid
                item
                fontWeight={"400"}
                sx={{ color: color_exports.primary_color }}
              >
                New Password
              </Grid>
              <Grid item>
                <Modifiedinputfield
                  size={"medium"}
                  placeholder="Enter here"
                  name="newPassword"
                  value={newPassword}
                  onChange={handleNewPasswordChange}
                  style={{ width: "365px" }}
                  type={newPasswordVisible ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() =>
                          setNewPasswordVisible(!newPasswordVisible)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {newPasswordVisible ? <Vieweye /> : <Hideeye />}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Stack>
          </Grid>

          <Grid item>
            <Stack spacing={1}>
              <Grid item fontWeight={"400"} sx={{ color: color_exports.primary_color }}>
                Confirm New Password
              </Grid>
              <Grid item>
                <Modifiedinputfield
                  size={"medium"}
                  placeholder="Enter here"
                  name="confirmPassword"
                  value={confirmPassword}
                  onChange={handleConfirmPasswordChange}
                  style={{ width: "365px" }}
                  type={confirmPasswordVisible ? "text" : "password"}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        onClick={() =>
                          setConfirmPasswordVisible(!confirmPasswordVisible)
                        }
                        style={{ cursor: "pointer" }}
                      >
                        {confirmPasswordVisible ? <Vieweye /> : <Hideeye />}
                      </InputAdornment>
                    ),
                  }}
                />
              </Grid>
            </Stack>
          </Grid>

          <Grid item container alignItems={"center"} mt={2}>
            <Grid item container md={8} justifyContent={"flex-end"} mb={2}>
              <Modifiedbutton
                sheight={"2.5rem"}
                swidth={"6rem"}
                data={"Confirm"}
                onClick={handlechangepwd}
              />
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </>
  );
};

export default Changepwd;
