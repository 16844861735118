import React from 'react';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isLogin = () => {
    // Check if the user is logged in by verifying the presence of a token in localStorage
    return localStorage.getItem('token') !== null;
  };

  if (isLogin()) {
    return <Component {...rest} />;
  }
  return <Navigate to="/" />;
};

export default PrivateRoute;
