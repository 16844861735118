import { makeStyles } from "@mui/styles";
import { color_exports } from "./Color_exports";
import loginimage from "../assets/Images/loginimage.png";

export const adminloginstyles = makeStyles({
  rolesty: {
    background: "#F2F2F2",
  },
  imglogin: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    justifyContent: "center",
  },
  imglogindiv: {
    backgroundImage: `url(${loginimage})`,
    backgroundSize: "cover",
    backgroundPosition: "center" /* Center the background image */,
    backgroundRepeat: "no-repeat",
    position: "relative" /* Make the container a positioned element */,
    width: "100%" /* Ensure it takes up the full width */,
    height: "100%" /* Ensure it takes up the full height */,
  },

  imglogin1: {
    position: "absolute" /* Position the logo on top of the background */,
    top: "50%" /* Center it vertically */,
    left: "50%" /* Center it horizontally */,
    transform: "translate(-50%, -50%)" /* Adjust positioning */,
    width: "200px !important",
  },
  forgotsty: {
    color: color_exports.primary_color,
    fontWeight: "700",
    cursor: "pointer",
  },
  // imglogindiv: {
  //   justifyContent: "center",
  //   display: "flex",
  //   width: "100%",
  //   height: "100%",
  // },
  iconrelative: {
    position: "relative",
    justifyContent: "center",
    alignItems: "center",
  },
  loginbtn: {
    swidth: "13rem",
    sheight: "3rem",
    fontWeight: 600,
    fontSize: "20px",
  },
  loginnicongrid: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
  loginiconstyle: {
    width: "100%",
  },
  logincontainer: {
    width: "100vw",
    height: "100vh",
    overflow: "hidden",
  },
  loginText: {
    display: "inline-block",
    padding: "0 10px 10px 0px",
    textAlign: "center",
    position: "relative",
    borderRadius: "0.5rem",

    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: "43%",
      transform: "translateX(-50%)",
      width: "21px", // Adjust the width of the border as needed
      borderBottom: `5px solid ${color_exports.primary_color}`,
      borderRadius: "50px",
    },
  },
  backgroundlogo: {
    position: "absolute",
    top: "10px",
    right: "10px",
    zIndex: "-9",
  },
});
