import React from "react";
import { Dialog, Grid, Typography } from "@mui/material";
import { color_exports } from "../Styles/Color_exports";

const Deletefaq = (props) => {
  const { open, onClose, onConfirm } = props;

  return (
    <Dialog open={open} onClose={onClose}>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        p={2}
        gap={1}
      >
        <Typography variant="body1" sx={{ color: color_exports.text_shade_color }}>
          Are you sure you want to delete this FAQ?
        </Typography>
        <Grid container item justifyContent="flex-end" gap={1} mt={1}>
          <Typography
            variant="body1"
            component="div"
            sx={{
              color: color_exports.primary_color,
              fontWeight: 700,
              cursor: "pointer",
            }}
            onClick={onClose}
            tabIndex={0}
            role="button"
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                onClose();
              }
            }}
          >
            No
          </Typography>
          <Typography
            variant="body1"
            component="div"
            sx={{
              color: color_exports.primary_color,
              fontWeight: 700,
              cursor: "pointer",
            }}
            onClick={onConfirm}
            tabIndex={0}
            role="button"
            onKeyPress={(e) => {
              if (e.key === 'Enter' || e.key === ' ') {
                onConfirm();
              }
            }}
          >
            Yes
          </Typography>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Deletefaq;
