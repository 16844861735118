import React from 'react';

const Messageicon = ({ active }) => {
  return (
    <>
      <svg
        width="25"
        height="25"
        viewBox="0 0 25 25"
        style={{ fill: active ? '#FFFFFF' : 'lightgray' }}
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M19.4902 3.59424C20.2859 3.59424 21.0489 3.91031 21.6116 4.47292C22.1742 5.03553 22.4902 5.79859 22.4902 6.59424V16.5942C22.4902 17.3899 22.1742 18.153 21.6116 18.7156C21.0489 19.2782 20.2859 19.5942 19.4902 19.5942H15.7932L13.1832 21.3342C12.7632 21.6142 12.2172 21.6142 11.7972 21.3342L9.18723 19.5942H5.49023C4.69458 19.5942 3.93152 19.2782 3.36891 18.7156C2.8063 18.153 2.49023 17.3899 2.49023 16.5942V6.59424C2.49023 5.79859 2.8063 5.03553 3.36891 4.47292C3.93152 3.91031 4.69458 3.59424 5.49023 3.59424H19.4902ZM8.99023 10.5942C8.59241 10.5942 8.21088 10.7523 7.92957 11.0336C7.64827 11.3149 7.49023 11.6964 7.49023 12.0942C7.49023 12.4921 7.64827 12.8736 7.92957 13.1549C8.21088 13.4362 8.59241 13.5942 8.99023 13.5942C9.38806 13.5942 9.76959 13.4362 10.0509 13.1549C10.3322 12.8736 10.4902 12.4921 10.4902 12.0942C10.4902 11.6964 10.3322 11.3149 10.0509 11.0336C9.76959 10.7523 9.38806 10.5942 8.99023 10.5942ZM15.9902 10.5942C15.5924 10.5942 15.2109 10.7523 14.9296 11.0336C14.6483 11.3149 14.4902 11.6964 14.4902 12.0942C14.4902 12.4921 14.6483 12.8736 14.9296 13.1549C15.2109 13.4362 15.5924 13.5942 15.9902 13.5942C16.3881 13.5942 16.7696 13.4362 17.0509 13.1549C17.3322 12.8736 17.4902 12.4921 17.4902 12.0942C17.4902 11.6964 17.3322 11.3149 17.0509 11.0336C16.7696 10.7523 16.3881 10.5942 15.9902 10.5942Z" />
      </svg>
    </>
  );
};

export default Messageicon;
