import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, Grid, Stack } from "@mui/material";
import Modifiedinputfield from "../Components/Modifiedinputfield";
import Modifiedbutton from "../Components/Modifiedbutton";
import { forget_styles } from "../Styles/Model_styles";
import { color_exports } from "../Styles/Color_exports";
import { admin_forgotpwd } from "../Apiservices/Adminservices";
import { enqueueSnackbar } from "notistack";
import Otp from "./Otp";
import Reset from "./Reset";
import { emailchecking } from "../Validations/Adminvalidations";

const Forget = ({ open, close }) => {
  const forget = forget_styles();
  const [showOtp, setShowOtp] = useState(false);
  const [email, setEmail] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);

  // Reset states when the dialog opens
  useEffect(() => {
    if (open) {
      setShowOtp(false);
      setOtpVerified(false);
      setEmail("");
    }
  }, [open]);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleforgotpwd = async () => {
    try {
      if (emailchecking(email, "Email")) return;

      const req = { email: email, isUserAdmin: true };
      const forgetres = await admin_forgotpwd(req);

      if (forgetres?.message === "OTP Sent Successfully ") {
        setShowOtp(true); // Only indicate that OTP was sent
      } else {
        enqueueSnackbar(forgetres?.message, { variant: "error" });
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  const handleOtpVerification = (verified) => {
    setOtpVerified(verified);
    if (verified) {
      setShowOtp(false);
    }
  };

  const handlePasswordKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleforgotpwd();
    }
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      PaperProps={{
        sx: {
          m: 0,
          width: 600,
          maxWidth: 600,
          borderRadius: "1.5rem",
          "::-webkit-scrollbar": { display: "none" },
          "@media screen and (min-width: 200px) and (max-width: 599px)": {
            width: "90%",
            maxWidth: "90%",
          },
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <Grid item xs={12} textAlign="center">
            <h4 className={forget.underline}>Forgot Password</h4>
          </Grid>
        </Grid>
      </DialogTitle>
      <Grid
        container
        direction={"column"}
        rowSpacing={3}
        alignItems={"center"}
        justifyContent={"center"}
      >
        {!showOtp && !otpVerified && (
          <>
            <Grid item>
              <Stack spacing={1}>
                <Grid
                  item
                  fontWeight={"400"}
                  sx={{ color: color_exports.primary_color }}
                >
                  Email ID
                </Grid>
                <Grid item>
                  <Modifiedinputfield
                    size={"medium"}
                    placeholder="Enter here"
                    name="email"
                    value={email}
                    onChange={handleEmailChange}
                    onKeyDown={handlePasswordKeyPress}
                    style={{ width: "500px" }}
                    autoComplete="off"
                  />
                </Grid>
              </Stack>
            </Grid>
            <Grid item container alignItems={"center"} mt={1}>
              <Grid item container md={12} justifyContent={"center"} mb={2}>
                <Modifiedbutton
                  fontWeight={600}
                  onClick={handleforgotpwd}
                  swidth={"7rem"}
                  sheight={"2.5rem"}
                  style={{ fontSize: "20px" }}
                  data="Submit"
                />
              </Grid>
            </Grid>
          </>
        )}
      </Grid>
      {showOtp && !otpVerified && (
        <Otp
          open={true}
          close={() => setShowOtp(false)}
          email={email}
          onOtpVerification={handleOtpVerification}
        />
      )}
      {otpVerified && (
        <Reset open={true} close={close} email={email} />
      )}
    </Dialog>
  );
};

export default Forget;
