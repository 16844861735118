import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, Grid, MenuItem, Select, Stack } from "@mui/material";
import { color_exports } from "../Styles/Color_exports";
import Modifiedbutton from "../Components/Modifiedbutton";
import Modifiedinputfield from "../Components/Modifiedinputfield";
import Modifiedtextarea from "../Components/Modifiedtextarea";
import { forget_styles } from "../Styles/Model_styles";
import { createpackage_api } from "../Apiservices/Adminservices";
import { enqueueSnackbar } from "notistack";

const Createoreditpackage = (props) => {
  const forget = forget_styles();
  const { open, close } = props;
  const [packageTitle, setPackageTitle] = useState("");
  const [price, setPrice] = useState("");
  const [duration, setDuration] = useState(60); // Default duration in days (2 months)
  const [description, setDescription] = useState("");
  const [packageCount, setPackageCount] = useState(0);
  const [isCreateDisabled, setIsCreateDisabled] = useState(false);

  useEffect(() => {
    const storedCount = localStorage.getItem("packageCount");
    if (storedCount) {
      setPackageCount(parseInt(storedCount, 10));
      setIsCreateDisabled(parseInt(storedCount, 10) >= 4);
    }
  }, []);

  const handleCreatePackage = async () => {
    try {
      // if (packageCount >= 4) {
      //   enqueueSnackbar("You can only create up to 4 packages", { variant: "warning" });
      //   return;
      // }
      const formData = {
        packageTitle,
        price,
        duration,
        description,
      };

      const response = await createpackage_api(formData);

      console.log("API Response:", response);

      if (response.message === "Package created") {
        enqueueSnackbar("Package created", { variant: "success" });
        const newCount = packageCount + 1;
        setPackageCount(newCount);
        localStorage.setItem("packageCount", newCount.toString());
        setIsCreateDisabled(newCount >= 4);
      } else {
        enqueueSnackbar(response.message || "Failed to create package", { variant: "error" });
      }
    } catch (err) {
      console.error("Error:", err);
      enqueueSnackbar(err.message || "Failed to create package", { variant: "error" });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      PaperProps={{
        sx: {
          m: 0,
          width: 400,
          maxWidth: 400,
          borderRadius: "1.5rem",
          "::-webkit-scrollbar": { display: "none" },
          "@media screen and (min-width: 200px) and (max-width: 599px)": {
            width: "90%",
            maxWidth: "90%",
          },
        },
      }}
    >
      <DialogTitle>
        <Grid container direction={"column"} spacing={2}>
          <Grid item className={forget.modaltitle}>
            Create Package
          </Grid>
        </Grid>
      </DialogTitle>
      <Grid container direction="column" gap={2} p={2}>
        <Grid item>
          <Stack spacing={1}>
            <Grid item sx={{ color: color_exports.primary_color }}>
              Package Title
            </Grid>
            <Grid item>
              <Modifiedinputfield
                fullWidth
                placeholder="Enter here"
                size="small"
                value={packageTitle}
                onChange={(e) => setPackageTitle(e.target.value)}
              />
            </Grid>
          </Stack>
        </Grid>
        <Grid item>
          <Stack spacing={1}>
            <Grid item sx={{ color: color_exports.primary_color }}>
              Price in Rupees
            </Grid>
            <Grid item>
              <Modifiedinputfield
                fullWidth
                placeholder="Enter here"
                size="small"
                value={price}
                onChange={(e) => setPrice(e.target.value)}
              />
            </Grid>
          </Stack>
        </Grid>
        <Grid item>
          <Stack spacing={1}>
            <Grid item sx={{ color: color_exports.primary_color }}>
              Duration (in days)
            </Grid>
            <Grid item>
              <Select
                fullWidth
                value={duration}
                onChange={(e) => setDuration(e.target.value)}
                size="small"
              >
                <MenuItem value={60}>2 months (60 days)</MenuItem>
                <MenuItem value={90}>3 months (90 days)</MenuItem>
                <MenuItem value={120}>4 months (120 days)</MenuItem>
              </Select>
            </Grid>
          </Stack>
        </Grid>
        <Grid item>
          <Stack spacing={1}>
            <Grid item sx={{ color: color_exports.primary_color }}>
              Description
            </Grid>
            <Grid item>
              <Modifiedtextarea
                rows={3}
                fullWidth
                placeholder="Enter here"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              />
            </Grid>
          </Stack>
        </Grid>
        <Grid item container justifyContent={"center"} gap={2}>
          <Modifiedbutton
            data={"Cancel"}
            bgcolor={"#F2F2F2"}
            styles={{ color: "#9F9F9F" }}
            onClick={close}
          />
          <Modifiedbutton data={"Create"} onClick={handleCreatePackage}  />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Createoreditpackage;
