import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, Grid, MenuItem, Select, Stack } from "@mui/material";
import Modifiedbutton from "../Components/Modifiedbutton";
import Modifiedinputfield from "../Components/Modifiedinputfield";
import Modifiedtextarea from "../Components/Modifiedtextarea";
import { editpackage_api } from "../Apiservices/Adminservices";
import { enqueueSnackbar } from "notistack";
import { forget_styles } from "../Styles/Model_styles";
import { color_exports } from "../Styles/Color_exports";

const Editpackage = (props) => {
  const { open, close, packageData } = props;

  const [formData, setFormData] = useState({
    packageId: "",
    packageTitle: "",
    price: "",
    duration: 61, // Default duration in days (61 days = 2 months)
    description: "",
  });

  useEffect(() => {
    if (packageData) {
      setFormData({
        packageId: packageData.packageId,
        packageTitle: packageData.packageTitle,
        price: packageData.price,
        duration: packageData.duration,
        description: packageData.description,
      });
    }
  }, [packageData]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleEditPackage = async () => {
    try {
      // Convert duration to a number before sending to the API
      const formDataWithNumberDuration = {
        ...formData,
        duration: parseInt(formData.duration)
      };
  
      const response = await editpackage_api(formDataWithNumberDuration);
  
      if (response?.message === "Package updated") {
        enqueueSnackbar("Package updated", { variant: "success" });
        setFormData({
          packageId: "",
          packageTitle: "",
          price: "",
          duration: "", // Reset duration to empty string
          description: "",
        });
        close();
      } else {
        enqueueSnackbar(response?.message || "Failed to update package", {
          variant: "error",
        });
      }
    } catch (err) {
      enqueueSnackbar(err.message || "Failed to update package", {
        variant: "error",
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      PaperProps={{
        sx: {
          m: 0,
          width: 500,
          maxWidth: 500,
          borderRadius: "1.5rem",
          "::-webkit-scrollbar": { display: "none" },
          "@media screen and (min-width: 200px) and (max-width: 599px)": {
            width: "90%",
            maxWidth: "90%",
          },
        },
      }}
    >
      <DialogTitle>
        <Grid container direction={"column"} spacing={2}>
          <Grid item textAlign={"center"} fontSize={"16px"} fontWeight={600}>
            Edit Package
          </Grid>
        </Grid>
      </DialogTitle>

      <Grid container direction="column" gap={2} p={2}>
        <Grid item>
          <Stack spacing={1}>
            <Grid
              item
              fontSize={"14px"}
              sx={{ color: color_exports.primary_color }}
            >
              Package Title
            </Grid>
          </Stack>
        </Grid>
        <Grid item md={12}>
          <Modifiedinputfield
            name="packageTitle"
            value={formData.packageTitle}
            onChange={handleInputChange}
            fullWidth
          />
        </Grid>
        
        <Grid item>
          <Stack spacing={1}>
            <Grid
              item
              fontSize={"14px"}
              sx={{ color: color_exports.primary_color }}
            >
              Price in Rupees
            </Grid>
          </Stack>
        </Grid>
        <Grid item md={12}>
          <Modifiedinputfield
            name="price"
            fullWidth
            value={formData.price}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item>
          <Stack spacing={1}>
            <Grid
              item
              fontSize={"14px"}
              sx={{ color: color_exports.primary_color }}
            >
              Duration
            </Grid>
          </Stack>
        </Grid>
        <Grid item md={12}>
          <Select
            fullWidth
            name="duration"
            value={formData.duration}
            onChange={handleInputChange}
            size="small"
          >
            <MenuItem value={61}>61 days</MenuItem>
            <MenuItem value={92}>92 days</MenuItem>
            <MenuItem value={123}>123 days</MenuItem>
          </Select>
        </Grid>
        <Grid item>
          <Stack spacing={1}>
            <Grid
              item
              fontSize={"14px"}
              sx={{ color: color_exports.primary_color }}
            >
              Description
            </Grid>
          </Stack>
        </Grid>
        <Grid item md={12}>
          <Modifiedtextarea
            name="description"
            fullWidth
            value={formData.description}
            onChange={handleInputChange}
          />
        </Grid>
        <Grid item container justifyContent={"center"} gap={2}>
          <Modifiedbutton
            data={"Cancel"}
            bgcolor={"#F2F2F2"}
            styles={{ color: "#9F9F9F" }}
            onClick={close}
          />
          <Modifiedbutton data={"Save"} onClick={handleEditPackage} />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Editpackage;
