import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer} from "recharts";

const Modifiedgraph2 = ({ data, reportBy }) => {
  if (!data) return null; 
  const { maleUsers, femaleUsers, recentRegistrations, guestUsers } = data;
  const mapDatePartToDay = (datePart) => {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return daysOfWeek[(datePart-1) % 7];
  };

  const mapDatePartToDate = (datePart) => {
    return `${datePart}`;
  };
  const mapDatePartToMonth = (datePart) => {
    const monthsOfYear = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    return monthsOfYear[datePart - 1];
  };
  const getCombinedDataTemplate = () => {
    if (reportBy === 2) {
      return [
        {
          name: "Sun",
          Male: 0,
          Female: 0,
          "Recent Registrations": 0,
          "Guest Users": 0,
        },
        {
          name: "Mon",
          Male: 0,
          Female: 0,
          "Recent Registrations": 0,
          "Guest Users": 0,
        },
        {
          name: "Tue",
          Male: 0,
          Female: 0,
          "Recent Registrations": 0,
          "Guest Users": 0,
        },
        {
          name: "Wed",
          Male: 0,
          Female: 0,
          "Recent Registrations": 0,
          "Guest Users": 0,
        },
        {
          name: "Thu",
          Male: 0,
          Female: 0,
          "Recent Registrations": 0,
          "Guest Users": 0,
        },
        {
          name: "Fri",
          Male: 0,
          Female: 0,
          "Recent Registrations": 0,
          "Guest Users": 0,
        },
        {
          name: "Sat",
          Male: 0,
          Female: 0,
          "Recent Registrations": 0,
          "Guest Users": 0,
        },
        
      ];
    } else if (reportBy === 4) {
      return [
        {
          name: "Jan",
          Male: 0,
          Female: 0,
          "Recent Registrations": 0,
          "Guest Users": 0,
        },
        {
          name: "Feb",
          Male: 0,
          Female: 0,
          "Recent Registrations": 0,
          "Guest Users": 0,
        },
        {
          name: "Mar",
          Male: 0,
          Female: 0,
          "Recent Registrations": 0,
          "Guest Users": 0,
        },
        {
          name: "Apr",
          Male: 0,
          Female: 0,
          "Recent Registrations": 0,
          "Guest Users": 0,
        },
        {
          name: "May",
          Male: 0,
          Female: 0,
          "Recent Registrations": 0,
          "Guest Users": 0,
        },
        {
          name: "Jun",
          Male: 0,
          Female: 0,
          "Recent Registrations": 0,
          "Guest Users": 0,
        },
        {
          name: "Jul",
          Male: 0,
          Female: 0,
          "Recent Registrations": 0,
          "Guest Users": 0,
        },
        {
          name: "Aug",
          Male: 0,
          Female: 0,
          "Recent Registrations": 0,
          "Guest Users": 0,
        },
        {
          name: "Sep",
          Male: 0,
          Female: 0,
          "Recent Registrations": 0,
          "Guest Users": 0,
        },
        {
          name: "Oct",
          Male: 0,
          Female: 0,
          "Recent Registrations": 0,
          "Guest Users": 0,
        },
        {
          name: "Nov",
          Male: 0,
          Female: 0,
          "Recent Registrations": 0,
          "Guest Users": 0,
        },
        {
          name: "Dec",
          Male: 0,
          Female: 0,
          "Recent Registrations": 0,
          "Guest Users": 0,
        },
      ];
    } else {
      return Array.from({ length: 31 }, (_, i) => ({
        name: `${i + 1}`,
        Male: 0,
        Female: 0,
        "Recent Registrations": 0,
        "Guest Users": 0,
      }));
    }
  };

  // Prepare combined data array
  const combinedData = getCombinedDataTemplate();

  // Function to update combinedData with actual data counts where available
  const updateDataCounts = (dataArray, usersArray, dataKey, mapFunction) => {
    usersArray.forEach((item) => {
      const label = mapFunction(item.date_part);
      const index = dataArray.findIndex((dataItem) => dataItem.name === label);
      if (index !== -1) {
        dataArray[index][dataKey] = item.count;
      }
    });
  };

  if (reportBy === 2) {
    updateDataCounts(combinedData, maleUsers, "Male", mapDatePartToDay);
    updateDataCounts(combinedData, femaleUsers, "Female", mapDatePartToDay);
    updateDataCounts(combinedData, recentRegistrations, "Recent Registrations", mapDatePartToDay);
    updateDataCounts(combinedData, guestUsers, "Guest Users", mapDatePartToDay);
  } else if (reportBy === 4) {
    updateDataCounts(combinedData, maleUsers, "Male", mapDatePartToMonth);
    updateDataCounts(combinedData, femaleUsers, "Female", mapDatePartToMonth);
    updateDataCounts(combinedData, recentRegistrations, "Recent Registrations", mapDatePartToMonth);
    updateDataCounts(combinedData, guestUsers, "Guest Users", mapDatePartToMonth);
  } else {
    updateDataCounts(combinedData, maleUsers, "Male", mapDatePartToDate);
    updateDataCounts(combinedData, femaleUsers, "Female", mapDatePartToDate);
    updateDataCounts(combinedData, recentRegistrations, "Recent Registrations", mapDatePartToDate);
    updateDataCounts(combinedData, guestUsers, "Guest Users", mapDatePartToDate);
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        data={combinedData}
        margin={{ top: 20, right: 30, left: 20, bottom: 10 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        {maleUsers && (
          <Line type="monotone" dataKey="Male" stroke="#5CBFF6" dot={false} />
        )}
        {femaleUsers && (
          <Line type="monotone" dataKey="Female" stroke="#EF82F9" dot={false} />
        )}
        {recentRegistrations && (
          <Line type="monotone" dataKey="Recent Registrations" stroke="#66EC62" dot={false} />
        )}
        {guestUsers && (
          <Line type="monotone" dataKey="Guest Users" stroke="#6F5AF4" dot={false} />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Modifiedgraph2;
