import React, { useState } from "react";
import * as XLSX from "xlsx";
import { Button, Typography, Box, CircularProgress } from "@mui/material";
import axios from "axios";
import { StorageClient } from "../Helpers/Helperfunctions";
import { enqueueSnackbar } from "notistack";

const Existusersmanagement = ({  }) => {
  const [file, setFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);

  const styles = {
    container: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      padding: "20px",
      backgroundColor: "#f4f4f9",
      borderRadius: "8px",
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    fileInput: {
      display: "none",
    },
    label: {
      backgroundColor: "black",
      color: "#fff",
      padding: "10px 20px",
      borderRadius: "5px",
      cursor: "pointer",
      marginBottom: "15px",
    },
    error: {
      color: "red",
      marginTop: "10px",
      fontSize: "14px",
    },
    fileName: {
      fontSize: "16px",
      fontStyle: "italic",
      color: "#555",
      marginTop: "10px",
    },
    button: {
      backgroundColor: "#b8003e",
      color: "#fff",
      "&:hover": {
        backgroundColor: "#218838",
      },
      padding: "10px 20px",
      borderRadius: "5px",
    },
    loadingSpinner: {
      marginTop: "20px",
    },
  };

  const handleFileChange = (e) => {
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      const fileType = selectedFile.name.split(".").pop().toLowerCase();
      if (fileType !== "csv") {
        setErrorMessage("Invalid file type. Please upload a CSV file only.");
        e.target.value = "";
        setFile(null);
        return;
      }
      setErrorMessage("");
      setFile(selectedFile);
    }
  };

  const handleFileUpload = () => {
    if (!file) {
      enqueueSnackbar("Please select a file to upload.", { variant: "error" });
      return;
    }
  
    setLoading(true);
  
    const reader = new FileReader();
    reader.onload = (e) => {
      try {
        const binaryStr = e.target.result;
        const workbook = XLSX.read(binaryStr, { type: "binary" });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet);
  
        if (parsedData.length === 0) {
          throw new Error("The uploaded file is empty or invalid.");
        }
  
        // Find the email column dynamically
        const columnNames = Object.keys(parsedData[0]);
        const emailColumnName = columnNames.find((col) =>
          col.toLowerCase().includes("email")
        );
  
        if (!emailColumnName) {
          throw new Error(
            // `No column matching 'email' found. Available columns: ${columnNames.join( ", " )}`
            "Please upload a valid CSV file that includes an Email column"
          );
        }
  
        // Extract email addresses
        const emailData = parsedData
          .map((row) => row[emailColumnName])
          .filter((email) => email);
  
        if (emailData.length === 0) {
          throw new Error("No valid email addresses found in the file.");
        }
  
        // Send email data to backend
        sendDataToBackend(emailData);
      } catch (error) {
        setErrorMessage(error.message);
        enqueueSnackbar(error.message, { variant: "error" }); // Show error notification
        setLoading(false);
      }
    };
  
    reader.readAsBinaryString(file);
  };
  
  const sendDataToBackend = async (emails) => {
    const token = StorageClient.getItem("token");
  
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/uploadExistingUsers`,
        { email: emails },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
  
      if (
        response.status === 200 &&
        response.data?.message === "Existing User Data Added Successfully"
      ) {
        enqueueSnackbar("Existing User Data Added Successfully, You can see the User management page, Existing users tab", {
          variant: "success",
        });
        // modalClose(); // Close modal on success
      } else {
        throw new Error(
          response.data?.message || "Unexpected error from the server."
        );
      }
    } catch (error) {
      console.error("Error during API call:", error);
      enqueueSnackbar(
        error.message || "Failed to upload some or all emails. Please try again.",
        { variant: "error" }
      );
    } finally {
      setLoading(false); // Ensure loading stops
    }
  };
  
  return (
    <Box style={styles.container}>
      <input type="file" accept=".csv" onChange={handleFileChange} style={styles.fileInput} id="fileInput" />
      <label htmlFor="fileInput" style={styles.label}>
        Choose CSV File
      </label>
      {file && <Typography style={styles.fileName}>{file.name}</Typography>}
      {loading && <CircularProgress size={24} style={styles.loadingSpinner} />}
      <Button style={styles.button} onClick={handleFileUpload} disabled={loading}>
        {loading ? "Uploading..." : "Upload CSV File"}
      </Button>
    </Box>
  );
};

export default Existusersmanagement;