import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";

const Modifiedgraph3 = ({ data, reportBy }) => {
  if (!data || !Array.isArray(data.memberShipCounts)) return null; // Ensure data is valid

  // Aggregate data based on reportBy value
  const aggregateData = () => {
    const result = [];

    if (reportBy === 2) {
      // Weekly view: Initialize data for days of the week
      const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
      daysOfWeek.forEach(day => {
        result.push({
          name: day,
          Diamond: 0,
          Silver: 0,
          "Gold Plus": 0,
          Gold: 0,
        });
      });

      data.memberShipCounts.forEach((item) => {
        const { datePart, packageTitle, total } = item;
        const dayIndex = (new Date(2024, 7, datePart).getDay());
        const dayName = daysOfWeek[dayIndex];

        const entry = result.find((r) => r.name === dayName);

        entry[packageTitle] = (entry[packageTitle] || 0) + parseInt(total, 10);
      });

    }  else if (reportBy === 3) {
      // Monthly view: Initialize data for days 1 to 31
      for (let i = 1; i <= 31; i++) {
        result.push({
          date: i,
          Diamond: 0,
          Silver: 0,
          "Gold Plus": 0,
          Gold: 0,
        });
      }

      data.memberShipCounts.forEach((item) => {
        const { datePart, packageTitle, total } = item;
        const day = parseInt(datePart, 10);

        const entry = result.find((r) => r.date === day);
        if (entry) {
          entry[packageTitle] += parseInt(total, 10);
        }
      });

    } else if (reportBy === 4) {
      // Cumulative view: Initialize data for months January to December
      const months = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
      months.forEach((month, index) => {
        result.push({
          name: month,
          Diamond: 0,
          Silver: 0,
          "Gold Plus": 0,
          Gold: 0,
        });
      });

      data.memberShipCounts.forEach((item) => {
        const { monthNumber, packageTitle, total } = item;
        const monthIndex = monthNumber - 1;

        if (result[monthIndex]) {
          result[monthIndex][packageTitle] += parseInt(total, 10);
        }
      });
    }

    return result;
  };

  const combinedData = aggregateData();

  // Format data for the graph
  const formattedData = combinedData.map((item) => ({
    name: reportBy === 3 ? item.date.toString() : item.name,
    Diamond: item.Diamond || 0,
    Silver: item.Silver || 0,
    "Gold Plus": item["Gold Plus"] || 0,
    Gold: item.Gold || 0,
  }));

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={formattedData} margin={{ top: 20, right: 30, left: 20, bottom: 10 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis 
          dataKey="name" 
          label={{ 
            // value: reportBy === 4 ? 'Month' : (reportBy === 3 ? 'Date' : 'Day'), 
            position: 'insideBottomRight', 
            offset: -5 
          }} 
        />
        <YAxis />
        <Tooltip />
        <Legend />
        {formattedData.some((item) => item.Silver > 0) && (
          <Line type="monotone" dataKey="Silver" stroke="rgb(0, 136, 254)" dot={false} />
        )}
        {formattedData.some((item) => item.Gold > 0) && (
          <Line type="monotone" dataKey="Gold" stroke="rgb(0, 196, 159)" dot={false} />
        )}
        {formattedData.some((item) => item["Gold Plus"] > 0) && (
          <Line type="monotone" dataKey="Gold Plus" stroke="rgb(255, 187, 40)" dot={false} />
        )}
        {formattedData.some((item) => item.Diamond > 0) && (
          <Line type="monotone" dataKey="Diamond" stroke="rgb(255, 128, 66)" dot={false} />
        )}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Modifiedgraph3;
