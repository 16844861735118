import { Container, Divider, Grid, Paper, Typography, Avatar } from "@mui/material";
import React, { useState, useEffect } from "react";
import { notificationstyles } from "../Styles/Contentmanagement_styles";
import { notifications_api } from "../Apiservices/Adminservices";
import { enqueueSnackbar } from "notistack";

const Notifications = () => {
  const notificationstyle = notificationstyles();
  const [notifications, setNotifications] = useState([]);
  const [imageBaseUrl, setImageBaseUrl] = useState("");

  useEffect(() => {
    const fetchNotifications = async () => {
      try {
        const response = await notifications_api({ min: 0, max: 50 });

        if (response && response.message === "Successfully retrived Notifications data") {
          // Set imageBaseUrl from the API response
          setImageBaseUrl(response.data.imageBaseUrl);
          setNotifications(response.data.rows);
        } else {
          console.error(response?.message || "Failed to fetch notifications");
          enqueueSnackbar(response?.message || "Failed to fetch notifications", {
            variant: "error",
          });
        }
      } catch (err) {
        console.error("Error:", err);
        enqueueSnackbar(err.message || "Failed to fetch Notifications", {
          variant: "error",
        });
      }
    };

    fetchNotifications();
  }, []);

  return (
    <Container maxWidth={"lg"}>
      <Paper elevation={5} className={notificationstyle.mainpaper1}>
        <Grid container md={12} direction={"column"} spacing={2}>
          {notifications.length === 0 ? (
            <Grid item>
              <Typography variant="h6" align="center">
                No data available
              </Typography>
            </Grid>
          ) : (
            notifications.map((item) => (
              <Grid key={item.id} item container direction={"column"} spacing={1}>
                <Grid item container justifyContent={"space-between"}>
                  <Grid item container md={4} spacing={1}>
                    <Grid item md={2}>
                      {/* Display the first profile image */}
                      <Avatar
                        src={`${imageBaseUrl}${item.profileImages[0]?.image1}`}
                        alt={`${item.firstName} ${item.lastName}`}
                        sx={{ width: 50, height: 50 }}
                      />
                    </Grid>
                    <Grid item md={10} container direction={"column"}>
                      <Grid item container spacing={1}>
                        <Grid item md={"auto"} fontWeight={700}>
                          {`${item.firstName} ${item.lastName}`} 
                        </Grid>
                        <Grid item md>
                          {/* <Verify />  */}
                        </Grid>
                      </Grid>
                      <Grid item sx={{ color: "#9D9D9D" }}>
                        {item.message}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md={"auto"} sx={{ color: "#9D9D9D" }}>
                    {new Date(item.updatedAt).toLocaleString()} 
                  </Grid>
                </Grid>
                <Grid item>
                  <Divider />
                </Grid>
              </Grid>
            ))
          )}
        </Grid>
      </Paper>
    </Container>
  );
};

export default Notifications;
