import React, { useState, useEffect } from "react";
import { chatmessagestyles } from "../../Styles/Chatstyles";
import Chats from "./Chats";
import { Grid, IconButton, Popover, List, ListItem, ListItemText } from "@mui/material";
import Modifiedbtnsearch from "../Modifiedbtnsearch";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { color_exports } from "../../Styles/Color_exports";
import { enqueueSnackbar } from "notistack";
import { clearchat, delete_chatrooms, fetchBlockedUsers, handleblockusers } from "../../Firebase/Firebaseservices";
import Modifiedbutton from "../Modifiedbutton";
import { useLocation } from "react-router-dom";

const Sidebar = ({ selectChat, selectedroom }) => {
  const chatstyle = chatmessagestyles();
  const location = useLocation();
  const [receiversearch, setReceiversearch] = useState("");
  const [receiver_name, setReceiver_name] = useState("");
  const [selectrooms, setSelectrooms] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [selected_chatrooms, setSelected_chatrooms] = useState(new Map());
  const [blockedUsers, setBlockedUsers] = useState("");
  const [showBlockedList, setShowBlockedList] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  useEffect(() => {
    const loadBlockedUsers = async () => {
      setLoading(true);
      const result = await fetchBlockedUsers();
      setLoading(false);
      if (result.status === 1) {
        setBlockedUsers(result.data);
      } else {
        setError(result.message);
      }
    };

    loadBlockedUsers();
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const getreceiverquery = (receiver_data) => {
    setReceiversearch(receiver_data);
  };

  const handleUnblockUser = async (room) => {
    const { roomid, userId } = room;
    const result = await handleblockusers([roomid]);
    if (result.status) {
      enqueueSnackbar(result.message, { variant: "success" });
      selectChat(null); // Reset the selected chat
      setBlockedUsers((prevBlockedUsers) =>
        prevBlockedUsers.filter((user) => user.userId !== userId)
      );
      setSelected_chatrooms(new Map());
      setSelectrooms(false);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  const getSelected_chatrooms = (newRoom) => {
    setSelected_chatrooms((prevMap) => {
      const map = new Map(prevMap);
      if (map.has(newRoom.roomId)) {
        map.delete(newRoom.roomId);
      } else {
        map.set(newRoom.roomId, newRoom);
      }
      return map !== prevMap ? map : prevMap;
    });
  };

  const handledeleterooms = async () => {
    try {
      if (selected_chatrooms.size === 0) {
        enqueueSnackbar("Please select at least one user profile", {
          variant: "warning",
        });
        return;
      }
      let delete_res = await delete_chatrooms([...selected_chatrooms.keys()]);
      if (delete_res?.status) {
        enqueueSnackbar(delete_res?.message, { variant: "success" });
        setSelected_chatrooms(new Map());
        setSelectrooms(false);
        selectChat(null); // Reset the selected chat
        handleClose();
      } else {
        enqueueSnackbar(delete_res?.message, { variant: "error" });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const clearchatrooms = async () => {
    try {
      if (selected_chatrooms.size === 0) {
        enqueueSnackbar("Please select at least one user profile", {
          variant: "warning",
        });
        return;
      }
      let delete_res = await clearchat([...selected_chatrooms.keys()]);
      if (delete_res?.status) {
        enqueueSnackbar(delete_res?.message, { variant: "success" });
        setSelected_chatrooms(new Map());
        setSelectrooms(false);
        selectChat(null); // Reset the selected chat
      } else {
        enqueueSnackbar(delete_res?.message, { variant: "error" });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleselection = () => {
    if (selected_chatrooms.size > 0) {
      setSelected_chatrooms(new Map());
    }
    setSelectrooms((prev) => !prev);
    selectChat(null); // Reset the selected chat
    handleClose();
  };

  const handleBlockedListClick = async () => {
    const result = await fetchBlockedUsers();
    if (result.status) {
      setBlockedUsers(result.data);
      setShowBlockedList(true);
    } else {
      enqueueSnackbar(result.message, { variant: "error" });
    }
  };

  return (
    <Grid style={{ position: "relative" }} className={chatstyle.sidebar}>
      {location.pathname !== "/admin" ? (
        <>
          <Grid item container sx={{ backgroundColor: "white" }} className={chatstyle.navbar}>
            <Grid item md mb={2}>
              <Modifiedbtnsearch width={"100%"} value={receiver_name} onChange={(e) => {
                  setReceiver_name(e.target.value);
                  if (e.target.value === "") {
                    getreceiverquery("");
                  }
                }}
                buttonclick={() => getreceiverquery(receiver_name)}
              />
            </Grid>
            <Grid container item md={1.5} mb={2} pr={1} justifyContent={"flex-end"} alignItems={"center"}>
              <IconButton onClick={handleClick}>
                <MoreVertIcon sx={{ color: color_exports.primary_color }} />
              </IconButton>
              <Popover id={id} open={open} sx={{ borderRadius: "10px" }} anchorEl={anchorEl} onClose={handleClose} anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Grid
                  container
                  p={2}
                  direction={"column"}
                  rowSpacing={1.5}
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Grid
                    item
                    fontSize={"16px"}
                    fontWeight={400}
                    sx={{ cursor: "pointer" }}
                    onClick={handleselection}
                  >
                    {selectrooms ? "Deselect" : "Select"} {/* Toggle text */}
                  </Grid>
                  <Grid
                    item
                    fontSize={"16px"}
                    fontWeight={400}
                    sx={{ cursor: "pointer" }}
                    onClick={clearchatrooms}
                  >
                    Clear
                  </Grid>
                  <Grid
                    item
                    fontSize={"16px"}
                    fontWeight={400}
                    sx={{ cursor: "pointer" }}
                    onClick={handledeleterooms}
                  >
                    Delete
                  </Grid>
                  <Grid
                    item
                    fontSize={"16px"}
                    fontWeight={400}
                    sx={{ cursor: "pointer" }}
                    onClick={handleBlockedListClick}
                  >
                    Blocked List
                  </Grid>
                </Grid>
              </Popover>
            </Grid>
          </Grid>
        </>
      ) : null}

      {/* Rest of the Sidebar component */}
      <Chats
        selectChat={selectChat}
        receiverquery={receiversearch}
        handleselected_chatrooms={getSelected_chatrooms}
        selected_chatrooms={selected_chatrooms}
        selectchatrooms={selectrooms}
        selectedroom={selectedroom}
      />

      {/* Blocked users popover */}
      {showBlockedList && (
        <Popover open={showBlockedList} anchorEl={anchorEl} onClose={() => setShowBlockedList(false)} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
          <List>
            <ListItem>
              <ListItemText primary="Blocked Users" primaryTypographyProps={{ variant: "h6" }} />
            </ListItem>
            {loading && <p>Loading...</p>}
            {error && <p>Error: {error}</p>}
            {blockedUsers.length > 0 ? (
              blockedUsers.map((user) => (
                <Grid container key={user.userId}>
                  <Grid item xs={6} style={{ padding: "10px" }}>
                    <span>{user.name}</span>
                  </Grid>
                  <Grid item xs={6}>
                    <Modifiedbutton
                      data={"Unblock"}
                      onClick={() => handleUnblockUser(user)}
                    />
                  </Grid>
                </Grid>
              ))
            ) : (
              <p>No blocked users found.</p>
            )}
          </List>
        </Popover>
      )}
    </Grid>
  );
};

export default Sidebar;
