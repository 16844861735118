import React from 'react';
import { Navigate } from 'react-router-dom';

const PublicRoute = ({ component: Component, restricted, ...rest }) => {
  const isLogin = () => {
    // Check if the user is logged in by verifying the presence of a token in localStorage
    return localStorage.getItem('token') !== null;
  };

  if (isLogin() && restricted) {
    return <Navigate to="/admin" />;
  }
  return <Component {...rest} />;
};

export default PublicRoute;
