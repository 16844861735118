import React, { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  Grid,
  Stack,
  Box,
  TextField,
} from "@mui/material";
import Modifiedbutton from "../Components/Modifiedbutton";
import { forget_styles } from "../Styles/Model_styles";
import { color_exports } from "../Styles/Color_exports";
import { admin_verify_otp } from "../Apiservices/Adminservices";
import { enqueueSnackbar } from "notistack";
import Reset from "./Reset";

const Otp = ({ open, close, email, verificationCode }) => {
  // Add verificationCode prop
  const forget = forget_styles();
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [showReset, setShowReset] = useState(false);

  // useEffect to autofill the verificationCode when it is received
  useEffect(() => {
    if (verificationCode) {
      const otpArray = verificationCode.split("");
      setOtp(otpArray);
    }
  }, [verificationCode]);

  const handleOtpChange = (element, index) => {
    const value = element.value;
    if (/^[0-9]$/.test(value) || value === "") {
      let otpArray = [...otp];
      otpArray[index] = value;
      setOtp(otpArray);
      if (value !== "" && index < 5) {
        document.getElementById(`otp-input-${index + 1}`).focus();
      }
    }
  };

  const handleotp = async () => {
    const otpValue = otp.join("");
    try {
      if (otpValue.length !== 6) {
        enqueueSnackbar("Please enter the 6-digit OTP", { variant: "warning" });
        return;
      }
      console.log("Email:", email);

      const verifyRes = await admin_verify_otp({ email, otp: otpValue });

      if (verifyRes?.message === "Otp Verified Successfully") {
        enqueueSnackbar(verifyRes?.message, { variant: "success" });
        setShowReset(true);
      } else {
        enqueueSnackbar(verifyRes?.message, { variant: "error" });
      }
    } catch (err) {
      console.error("Error:", err);
      enqueueSnackbar(err.message, {
        variant: "error",
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      PaperProps={{
        sx: {
          m: 0,
          width: 600,
          maxWidth: 600,
          borderRadius: "1.5rem",
          "::-webkit-scrollbar": { display: "none" },
          "@media screen and (min-width: 200px) and (max-width: 599px)": {
            width: "90%",
            maxWidth: "90%",
          },
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <Grid item xs={12} textAlign="center">
            <h4 className={forget.underline}>Verify Email</h4>
          </Grid>
          <Grid
            item
            alignSelf={"center"}
            sx={{
              color: color_exports.tex_shade_color,
              opacity: 0.5,
              fontSize: "14px",
            }}
          >
            We have sent an OTP to your email address. Please verify
          </Grid>
        </Grid>
      </DialogTitle>

      <Grid
        container
        direction={"column"}
        rowSpacing={3}
        alignItems={"center"}
        justifyContent={"center"}
        position="relative"
      >
        <Grid item xs={12}>
          <Stack spacing={1}>
            <Grid
              item
              fontWeight={"400"}
              sx={{ color: color_exports.primary_color }}
            >
              OTP
            </Grid>
            <Grid item xs={12} md={12} textAlign={"center"}>
              <Box display="flex" justifyContent="center" gap={1}>
                {otp.map((data, index) => (
                  <TextField
                    key={index}
                    id={`otp-input-${index}`}
                    type="text"
                    inputProps={{
                      maxLength: 1,
                      style: {
                        textAlign: "center",
                        fontSize: "20px",
                        width: "40px",
                        height: "30px",
                        backgroundColor: "#F2F2F2",
                      },
                    }}
                    value={data}
                    onChange={(e) => handleOtpChange(e.target, index)}
                    variant="outlined"
                    sx={{
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          border: "none",
                        },
                      },
                    }}
                  />
                ))}
              </Box>
            </Grid>
          </Stack>
        </Grid>
        <Grid item container alignItems={"center"} mt={0}>
          <Grid item container md={8} justifyContent={"flex-end"} mb={2}>
            <Modifiedbutton
              fontWeight={600}
              onClick={handleotp}
              swidth={"10rem"}
              sheight={"3rem"}
              style={{ fontSize: "20px" }}
              data="Submit"
            />
          </Grid>
        </Grid>
      </Grid>
      {showReset && <Reset open={true} close={close} email={email} />}
    </Dialog>
  );
};

export default Otp;
