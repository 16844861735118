import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const Modifiedgraph = ({ data, reportBy }) => {
  if (!data) return null;

  const { maleAcceptedCount = [], femaleAcceptedCount = [], totalCount = [] } = data;

  // Mapping functions for different x-axis data representations
  const mapDatePartToDay = (day) => {
    // Assuming day 20 is Tuesday and day 21 is Wednesday
    const daysOfWeek = ["Tue", "Wed", "Thu", "Fri", "Sat", "Sun", "Mon"];
    // Adjust day to match the correct index; map based on given day 20 -> Tuesday, etc.
    return daysOfWeek[(day - 20) % 7]; // Adjusted based on your mapping
  };

  const mapDatePartToDate = (day) => `${day}`;

  const mapDatePartToMonth = (month) => {
    const monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
    return monthsOfYear[month - 1];
  };

  // Generate the data structure for the chart
  const getCombinedDataTemplate = () => {
    if (reportBy === 2) { // Daily
      return [  "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(day => ({
        name: day, Male: 0, Female: 0, Total: 0,
      }));
    } else if (reportBy === 4) { // Monthly
      return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"].map((month, index) => ({
        name: mapDatePartToMonth(index + 1), Male: 0, Female: 0, Total: 0,
      }));
    } else { // Weekly (assuming each day of the month)
      return Array.from({ length: 31 }, (_, i) => ({
        name: mapDatePartToDate(i + 1), Male: 0, Female: 0, Total: 0,
      }));
    }
  };

  const combinedData = getCombinedDataTemplate();

  // Update counts in combinedData
  const updateDataCounts = (dataArray = [], dataArrayName, mapFunction) => {
    if (!Array.isArray(dataArray)) return;
    dataArray.forEach((item) => {
      const label = mapFunction(item.date_part);
      const index = combinedData.findIndex((dataItem) => dataItem.name === label);
      if (index !== -1) {
        combinedData[index][dataArrayName] += item.count;
      }
    });
  };

  if (reportBy === 2) { // Daily
    updateDataCounts(maleAcceptedCount, "Male", mapDatePartToDay);
    updateDataCounts(femaleAcceptedCount, "Female", mapDatePartToDay);
    updateDataCounts(totalCount, "Total", mapDatePartToDay);
  } else if (reportBy === 4) { // Monthly
    updateDataCounts(maleAcceptedCount, "Male", mapDatePartToMonth);
    updateDataCounts(femaleAcceptedCount, "Female", mapDatePartToMonth);
    updateDataCounts(totalCount, "Total", mapDatePartToMonth);
  } else { // Weekly
    updateDataCounts(maleAcceptedCount, "Male", mapDatePartToDate);
    updateDataCounts(femaleAcceptedCount, "Female", mapDatePartToDate);
    updateDataCounts(totalCount, "Total", mapDatePartToDate);
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={combinedData} margin={{ top: 20, right: 20, left: 0, bottom: 10 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="Male" stroke="#8884d8" dot={false} />
        <Line type="monotone" dataKey="Female" stroke="#82ca9d" dot={false} />
        <Line type="monotone" dataKey="Total" stroke="#ffc658" dot={false} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Modifiedgraph;
