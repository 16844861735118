import axios from "axios";
import { StorageClient } from "../Helpers/Helperfunctions";
import { Envexports } from "../Exports/Envexports";



/**Refresh token api call */
export const refreshtokenApi = async (payload) => {
  const headers = {
    "Content-Type": "application/json",
    Authorization:  StorageClient.getItem("refreshtoken"),
  };
  return axios
    .get(`${Envexports.baseurl}/adminRefreshToken`,  { headers })
    .then((response) => {
      // Handle the response
      const data = response?.data;
      if (response?.status === 200) {
        StorageClient.setItem("token", data.data.accessToken);
        return data.data.accessToken;
      }
    })
    .catch((error) => {
      if (error?.response?.status === 401) {
        StorageClient.clear();
        localStorage.removeItem("persist:root"); 
        window.location.replace("/");
        return;
      }
      console.error("Error refreshing access token:", error);
    });
};


export default axios;
