import { makeStyles } from "@mui/styles";
import { color_exports } from "./Color_exports";

export const forget_styles = makeStyles({
  underline: {
    display: "inline-block",
    padding: "0 10px 10px 0px",
    textAlign: "center",
    position: "relative",
    borderRadius: "0.5rem",
    fontSize: "20px",
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",
      width: "30px",
      borderBottom: `5px solid ${color_exports.primary_color}`,
      borderRadius: "50px",
    },
  },
  subhead: {
    m: 0,
    width: 600,
    maxWidth: 600,
    borderRadius: "1.5rem",
    "::-webkit-scrollbar": { display: "none" },
    "@media screen and (min-width: 200px) and (max-width: 599px)": {
      width: "90%",
      maxWidth: "90%",
    },
  },
  createpackage: {
    m: 0,
    width: "100%",
    maxWidth: "100%",
    borderRadius: "1.5rem",
    overflow: "hidden", // Hide scrollbar
    "::-webkit-scrollbar": { display: "none" }, // For WebKit browsers
    "@media screen and (min-width: 200px) and (max-width: 599px)": {
        width: "100%",
        maxWidth: "100%",
    },
  },
  modaltitle: {
    textAlign: "center",
     fontSize: "16px",
      fontWeight: 600
  },
    dialogPaper: {
      margin: 0,
      width: 410,
      maxWidth: 410,
      borderRadius: "1rem",
      padding: "1rem 2rem",
      "&::-webkit-scrollbar": { display: "none" },
      "@media screen and (min-width: 200px) and (max-width: 599px)": {
        width: "90%",
        maxWidth: "90%",
      },
    },
    dialogTitle: {
      textAlign: "center",
      fontSize: "16px",
      fontWeight: 600,
    },
    gridItem: {
      fontSize: "14px",
      color: color_exports.primary_color,
    },
    addValue: {
      fontSize: "12px",
      color: color_exports.primary_color,
      paddingTop: "1.5rem",
    },
    addValuePaddingLeft: {
      paddingLeft: "2.5rem",
    },
    addOption: {
      fontSize: "12px",
      color: color_exports.primary_color,
    },
    buttonCancel: {
      backgroundColor: "#F2F2F2",
      color: "#9F9F9F",
    },
    addvaluesty: {
      paddingTop: "25px",
      fontSize: "12px",
    }
});
