import React from "react";

const RightArrow = (props) => {
  return (
    <>
      <svg
        width="11"
        height="19"
        viewBox="0 0 7 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.26955 0.999042L6.0564 5.78588L1.26955 10.5727"
          stroke="#B8003E"
          stroke-width="1.19422"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default RightArrow;
