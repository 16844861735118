import React from 'react';

const Contenticon = ({ active }) => {
  return (
    <>
      <svg
        width="19"
        height="19"
        viewBox="0 0 15 16"
        xmlns="http://www.w3.org/2000/svg"
        style={{ fill: active ? '#FFFFFF' : 'lightgray' }}
      >
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.5611 9.31209C10.3686 9.50531 10.1214 9.63484 9.85299 9.68318L6.6539 10.259C6.44381 10.297 6.22765 10.284 6.02363 10.2211C5.81961 10.1582 5.6337 10.0471 5.48153 9.89738C5.32936 9.74762 5.21539 9.56349 5.14922 9.3605C5.08305 9.15751 5.0666 8.94159 5.10127 8.73092L5.63445 5.48917C5.67971 5.21314 5.81066 4.95834 6.00875 4.76085L10.4224 0.367432H2.69129C2.26707 0.367432 1.86022 0.535955 1.56024 0.835928C1.26027 1.1359 1.09175 1.54275 1.09175 1.96698V11.4779L0.0531096 14.5949C0.022999 14.6854 0.0177497 14.7824 0.0379096 14.8757C0.0580696 14.969 0.1029 15.0551 0.167721 15.1252C0.232541 15.1952 0.314977 15.2466 0.406422 15.2739C0.497868 15.3013 0.594971 15.3035 0.687596 15.2805L4.88907 14.2302H13.3549C13.7792 14.2302 14.186 14.0616 14.486 13.7617C14.7859 13.4617 14.9545 13.0548 14.9545 12.6306V4.89948L10.5611 9.31209ZM13.1619 0.449542C12.9661 0.367919 12.7504 0.34654 12.5424 0.38814C12.3344 0.42974 12.1436 0.532428 11.9942 0.683076L6.95035 5.70565L6.41717 8.94739L9.61626 8.37155L14.6388 3.32766C14.7388 3.22852 14.8181 3.11058 14.8722 2.98064C14.9264 2.85069 14.9543 2.71131 14.9543 2.57054C14.9543 2.42977 14.9264 2.29039 14.8722 2.16044C14.8181 2.03049 14.7388 1.91255 14.6388 1.81342L13.5085 0.683076C13.4096 0.582752 13.2918 0.503017 13.1619 0.448476V0.449542Z"
        />
      </svg>
    </>
  );
};

export default Contenticon;
