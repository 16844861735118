import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import tickmark from "../assets/Images/tickmark.png";
import { CircleCheckboxstyles } from "../Styles/Component_styles";

const CircleCheckbox = ({ checked, onChange }) => {
  const CircleCheckboxstyle = CircleCheckboxstyles();
  return (
    <FormControlLabel
      control={
        <Checkbox
          icon={<span className={CircleCheckboxstyle.icon} />}
          checkedIcon={
            <span className={CircleCheckboxstyle.checkedicon}>
              <img src={tickmark} width={12} height={10} />
            </span>
          }
          checked={checked}
          onChange={onChange}
        />
      }
    />
  );
};

export default CircleCheckbox;
