
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore} from "firebase/firestore";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyAvdpG5KPaBWJjLbm_r69441mXQPzNsQzc",
  authDomain: "janatamatrimony.firebaseapp.com",
  projectId: "janatamatrimony",
  storageBucket: "janatamatrimony.appspot.com",
  messagingSenderId: "142045060080",
  appId: "1:142045060080:web:b6f38d9f757d32c8d65b35"
};

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app);
export const storage = getStorage(app);
