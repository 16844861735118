import { Search } from '@mui/icons-material';
import { Grid, IconButton, InputAdornment, TextField } from '@mui/material';
import React from 'react'

const Modifiedsearchbar = ({height,width,buttonclick,...otherprops}) => {
    const searchButtonStyle = {
        // backgroundColor: "#04BEF8",
        // padding:'1rem',
        "&:hover": {
          backgroundColor: "darkred",
        },
      };
  return (
    <>
     <Grid container direction="column" >
      <Grid item sx={{position:'relative'}}>
      <TextField {...otherprops} size="small" sx={{"& .MuiInputBase-root":{ paddingRight:'0px', paddingLeft:'0px'},
            padding:'0px',
            width: width===undefined?"28rem":width,
            // height: height===undefined?"3rem":height,
            background: "#FFFFFF",
            borderRadius: "3rem",
            "& fieldset": {
              border: "none",
            },
        }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <IconButton style={searchButtonStyle}>
                  <Search sx={{ color: "#B8003E" }}  />
                </IconButton>
              </InputAdornment>
            ),
          }}
        fullWidth
      />
      </Grid>
    </Grid>
    </>
  )
}

export default Modifiedsearchbar