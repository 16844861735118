import React from "react";
import { Dialog, Grid } from "@mui/material";
import { color_exports } from "../Styles/Color_exports";
import { manageUsers_api } from "../Apiservices/Adminservices";
import { enqueueSnackbar } from "notistack";

const Block_or_Delet = ({ open, close, action, selectedRow, onStatusUpdated }) => {
  const handleAction = async () => {
    try {
      if (!selectedRow || selectedRow.userId === undefined) {
        throw new Error("Selected row or userId is undefined");
      }

      let statusValue;
      let actionText;
      switch (action) {
        case "block":
          statusValue = 1;
          actionText = "blocked";
          break;
        case "unblock":
          statusValue = 2;
          actionText = "unblocked";
          break;
        default:
          throw new Error("Invalid action");
      }

      const response = await manageUsers_api({
        userIds: [String(selectedRow.userId)],
        status: statusValue,
      });

      if (response?.message) {
        enqueueSnackbar(`User ${actionText} successfully`, { variant: "success" });
        onStatusUpdated(selectedRow.userId, statusValue); // Notify parent to update status
        close();
      } else {
        throw new Error("Error occurred while processing the request");
      }
    } catch (error) {
      enqueueSnackbar(error.message || "An error occurred", { variant: "error" });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      PaperProps={{
        sx: {
          m: 0,
          width: "90%", // Adjusted width for better responsiveness
          maxWidth: 310,
          borderRadius: "1rem",
          "@media screen and (min-width: 200px) and (max-width: 599px)": {
            width: "90%",
            maxWidth: "90%",
          },
          overflow: "hidden",
        },
      }}
    >
      <Grid
        container
        direction="column"
        m={2}
        justifyContent="center"
        sx={{ overflow: "hidden" }}
      >
        <Grid item sx={{ color: color_exports.tex_shade_color }} ml="1.5rem">
          {selectedRow && (
            <>
              {action === "block" ? (
                <>Are you sure you want to block?</>
              ) : action === "unblock" ? (
                <>Are you sure you want to unblock?</>
              ) : (
                <>Invalid action</>
              )}
            </>
          )}
        </Grid>
        <Grid container item justifyContent={"flex-end"} mt={2}>
          <Grid
            item
            sm={2}
            sx={{
              color: color_exports.primary_color,
              fontWeight: "700",
              cursor: "pointer",
              marginRight: "1rem",
            }}
            onClick={close}
          >
            No
          </Grid>
          <Grid
            item
            sm={2}
            sx={{
              color: color_exports.primary_color,
              fontWeight: "700",
              cursor: "pointer",
            }}
            onClick={handleAction}
          >
            Yes
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Block_or_Delet;
