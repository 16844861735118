import React from "react";
import { Search } from "@mui/icons-material";
import { Grid, IconButton, InputAdornment, TextField } from "@mui/material";
import Modifiedbutton from "./Modifiedbutton";
import { searchbtnstyles } from "../Styles/Component_styles";

const Modifiedbtnsearch = ({ height, width, buttonclick, ...otherprops }) => {
  const handlesearch = () => {
    buttonclick();
  };
  return (
    <>
      <Grid container direction="column">
        <Grid item sx={{ position: "relative" }}>
          <TextField
            {...otherprops}
            size="small"
            sx={{
              "& .MuiInputBase-root": {
                paddingRight: "0px",
                paddingLeft: "0px",
              },
              padding: "0px",
              width: width === undefined ? "28rem" : width,
              background: "#F1F1F1",
              borderRadius: "3rem",
              "& fieldset": {
                border: "none",
              },
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <IconButton className={searchbtnstyles.searchButtonStyle}>
                    <Search sx={{ color: "#B8003E" }} />
                  </IconButton>
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end" onClick={() => handlesearch()}>
                  <Modifiedbutton
                    data={"Search"}
                    // onClick={handlesendmessage}
                    // disabled={message_sending}
                  />
                </InputAdornment>
              ),
            }}
            fullWidth
          />
        </Grid>
      </Grid>
    </>
  );
};

export default Modifiedbtnsearch;
