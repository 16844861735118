import React from "react";

const Verify = () => {
  return (
    <>
      <svg
        width="20"
        height="21"
        viewBox="0 0 20 21"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8.10297 1.99633C8.82579 0.308679 11.1723 0.308677 11.8951 1.99633L12.334 3.02106C12.7435 3.97723 13.7764 4.48468 14.7645 4.21517L15.8234 3.92634C17.5674 3.45065 19.0304 5.32226 18.1878 6.95103L17.6761 7.94002C17.1987 8.86283 17.4538 10.0031 18.2764 10.6232L19.158 11.2877C20.6099 12.3822 20.0877 14.7161 18.3141 15.0595L17.2372 15.268C16.2324 15.4625 15.5176 16.3769 15.5553 17.4197L15.5957 18.5372C15.6622 20.3777 13.5481 21.4164 12.1791 20.2158L11.3478 19.4868C10.5722 18.8066 9.42583 18.8066 8.6502 19.4868L7.81897 20.2158C6.44999 21.4163 4.33589 20.3777 4.40239 18.5372L4.44277 17.4197C4.48045 16.3769 3.76568 15.4625 2.76082 15.268L1.6839 15.0595C-0.089691 14.7161 -0.611831 12.3822 0.840077 11.2877L1.72167 10.6232C2.54427 10.0031 2.79937 8.86283 2.32195 7.94002L1.8103 6.95104C0.967642 5.32226 2.43064 3.45065 4.17464 3.92634L5.23358 4.21517C6.22168 4.48468 7.25454 3.97723 7.66407 3.02106L8.10297 1.99633Z"
          fill="#B8003E"
        />
        <path
          d="M6.38281 10.2182L8.39174 13.1901L13.6152 8.27112"
          stroke="white"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default Verify;
