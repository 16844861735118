import { makeStyles } from "@mui/styles";

export const packageManagementStyles = makeStyles(theme => ({
  
  gridContainer: {
    backgroundColor: "#333333",
    borderRadius: "15px",
    color: "#FFFFFF",
    marginLeft: "-5px !important",
    fontSize: "16px",
    fontWeight: 700,
    width: "100% !important",
  },
  gridContainer1  : {
    backgroundColor: "#ffffff",
    borderRadius: "15px",
    color: "#b8003e",
    marginLeft: "-5px !important",
    fontSize: "16px",
    fontWeight: 400,
    width: "100% !important",
  },
  gridContainer2: {
    backgroundColor: "#FFFFFF",
    borderRadius: "15px",
    fontSize: "14px",
    color: "#333333",
    paddingBottom: "2px",
    marginLeft: "-5px !important",
    fontWeight: 400,
    width: "100% !important",
    // paddingLeft: "1rem !important",
  },
  gridItem: {
    // Add a right border
    borderRight: "1px solid #ffffff",
    paddingRight: theme.spacing(2), // Add padding for spacing
  },
  gridItem1: {
    color: "#B8003E",
    borderRight: "1px solid #dfd8d8",
    // paddingTop: "2rem !important",
    paddingLeft: "1rem !important",
    marginTop: "2rem"
  },
  groupnamesty: {
 color: "#B8003E",
 borderRight: "1px solid #dfd8d8",
 paddingLeft: "1rem !important",
 paddingTop: "10px",
 fontSize: "16px",
 fontWeight: 700,
 borderRight: "1px solid #ddd",
 paddingTop: '0px !important',
//  marginTop: "2rem"
  },
  itemnamesty: {
    color: "#333333",
    paddingLeft: "1rem !important",
  },
  addvaluesty1: {
    marginLeft: "1.5rem",
  },
  gridItem2: {
    color: "#B8003E",
    borderRight: "1px solid #dfd8d8",
    // paddingTop: "2rem !important",
    paddingLeft: "2rem !important",
    marginTop: "2rem"
  },
  checkbox: {
    borderRadius: "50% !important",
  },
  gridItem2: {
    paddingTop: "20px !important",
    borderRight: "none !important",
    marginLeft: "3px",
    border: "none",
  },
  optiontitle: {
    alignItems: "center",
    borderTop: "1px solid #E0E0E0",
    marginBottom: "-0.5rem",
    marginTop: "-1rem",
  },
  savebtn: {
    display: "flex",
    justifyContent: "flex-end",
  },
  packageList: {
    overflowX: "auto",
    display: "flex",
    flexWrap: "nowrap",
    alignItems: "flex-start",
    
  },
  packageListItem: {
    minWidth: "160px",
    display: "flex",
    alignItems: "center",
    marginTop: "-15px",
    // borderRight: "1px solid #000000",
    // marginRight: theme.spacing(1),
    // padding: theme.spacing(1),
  },
  packageListItem: {
    minWidth: "160px",
    display: "flex",
    alignItems: "center",
    marginTop: "-15px",
    // borderRight: "1px solid #000000",
    // marginRight: theme.spacing(1),
    // padding: theme.spacing(1),
  },
  packageListItem1: {
    color: "black",
    // minWidth: "180px",
    marginTop: "-15px",
  },
  addvaluesty: {
    marginBottom: "-0.5rem",
    marginTop: "-1rem",
    alignItems: "center",
    borderTop: "1px solid #E0E0E0",
  },
}));
