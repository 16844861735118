import { Button } from '@mui/material';
import React from 'react'
import { color_exports } from '../Styles/Color_exports';
// import { color_exports } from '../pages/Styles/Color_exports';

const Modifiedbutton = ({
    data,
    bgcolor,
    swidth,
    sheight,
    styles,
    textColor,
    size,
    ...otherprops
  }) => {
    let button_sizes = {
      nl:'0px',
      sm:'0.4rem 1rem',
      // md:'0.5rem 1.5rem',
      lg:'0.5rem 2rem',
      xl:'0.5rem 2.5rem'
    }
    return (
      <Button disableRipple {...otherprops}
        sx={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "center",
          cursor: "pointer",
          backgroundColor: `${
            bgcolor !== undefined ? bgcolor : color_exports.primary_color
          }!important`,
          size: "auto",
          color: `${
            textColor ? textColor : color_exports.btntext_color
          }!important`,
          borderRadius: "1.5rem",
          width: swidth,
          height: sheight,
          textTransform: "none",
          cursor: "pointer",
          "&:hover": {
            backgroundColor:
              bgcolor !== undefined ? bgcolor : color_exports.primary_color,
          },
          padding:button_sizes[size??'md'],
          ...styles,
        }}
      >
        {data}
      </Button>
    );
}

export default Modifiedbutton