import React, { useState, useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Grid,
  IconButton,
  Tab,
} from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { color_exports } from "../Styles/Color_exports";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import editicon from "../assets/Icons/editicon.png";
import deleteicon from "../assets/Icons/deleteicon.png";
import { enqueueSnackbar } from "notistack";
import {
  createfaq_api,
  getfaq_api,
  updatefaq_api,
  deletefaq_api,
  upsertContentManagement_api,
  updateFaqOrders_api,
} from "../Apiservices/Adminservices";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { editorstyles } from "../Styles/Contentmanagement_styles";
import Modifiedbutton from "../Components/Modifiedbutton";
import axios from "axios";
import Deletefaq from "../Models/Deletefaq";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

const Content_management = () => {
  const [value, setValue] = useState(1);
  const [faqs, setFaqs] = useState([]);
  const [faq, setFaq] = useState({
    id: null,
    Question: "",
    Answer: "",
  });
  const [isEditing, setIsEditing] = useState(false);
  const [termsOfService, setTermsOfService] = useState("");
  const [termsContentId, setTermsContentId] = useState(null);
  const [privacyPolicy, setPrivacyPolicy] = useState("");
  const [privacyContentId, setPrivacyContentId] = useState(null);
  // const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  // const [selectedFaq, setSelectedFaq] = useState(null);
  const editorstyle = editorstyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const upsertContentManagement = async (type, content, contentId) => {
    try {
      const req = {
        content: content,
        type: type,
      };
      if (contentId) {
        req.id = contentId;
      }
      const response = await upsertContentManagement_api(req);

      if (contentId) {
        if (response?.message === "content updated successfully") {
          enqueueSnackbar("Content updated successfully", {
            variant: "success",
          });
        } else {
          console.log("Update failed:", response?.message);
          enqueueSnackbar(response?.message, { variant: "error" });
        }
      } else {
        if (response?.message === "content added successfully") {
          enqueueSnackbar("Content added successfully", { variant: "success" });
        } else {
          console.log("Addition failed:", response?.message);
          enqueueSnackbar(response?.message, { variant: "error" });
        }
      }
    } catch (err) {
      console.error("Error:", err);
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  const handleSaveTerms = () => {
    upsertContentManagement(2, termsOfService, termsContentId); // 2 for Terms of Services
  };

  const handleQuestionChange = (value) => {
    setFaq((prevFaq) => ({
      ...prevFaq,
      Question: value, // Ensure this value includes whitespace
    }));
  };

  const handleAnswerChange = (value) => {
    setFaq((prevFaq) => ({
      ...prevFaq,
      Answer: value, // Ensure this value includes whitespace
    }));
  };

  const modules = {
    toolbar: [
      [{ header: [1, 2, false] }],
      ["bold", "italic", "underline", "strike"],
      ["blockquote", "code-block"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ color: [] }],
      ["link", "image"],
    ],
  };

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "code-block",
    "list",
    "bullet",
    "color",
    "link",
    "image",
  ];

  const fetchFaqs = async () => {
    try {
      const req = {
        min: 0,
        max: 50,
      };
      const getfaqres = await getfaq_api(req);

      if (getfaqres?.message === "Successfully retrived Faqs data") {
        setFaqs(getfaqres.data.rows);
        console.log("Fetched FAQs:", getfaqres.data.rows);
      } else {
        console.log("Failed:", getfaqres?.message);
        enqueueSnackbar(getfaqres?.message, { variant: "error" });
      }
    } catch (err) {
      console.error("Error:", err);
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  const createfaq = async () => {
    const parser = new DOMParser();

    // Parse the input strings to DOM elements
    const questionDoc = parser.parseFromString(faq.Question, "text/html");
    const answerDoc = parser.parseFromString(faq.Answer, "text/html");

    // Check if the text content is empty after removing whitespace
    const isQuestionEmpty = !questionDoc.body.textContent.trim();
    const isAnswerEmpty = !answerDoc.body.textContent.trim();

    // If either field is empty, return from the function
    if (isQuestionEmpty || isAnswerEmpty) {
      enqueueSnackbar("Please enter both question and answer", {
        variant: "warning",
      });
      return;
    }
    const trimmedQuestion = faq.Question.trim();
    const trimmedAnswer = faq.Answer.trim();

    if (!trimmedQuestion || !trimmedAnswer) {
      enqueueSnackbar("Please add question and answer", { variant: "error" });
      return;
    }

    try {
      const req = {
        question: faq.Question,
        answer: faq.Answer,
        orderIndex: faqs.length + 1,
      };
      const createqueres = await createfaq_api(req);

      if (createqueres?.message === "Successfully created Faq") {
        enqueueSnackbar("Successfully added FAQ", { variant: "success" });
        fetchFaqs();
        setFaq({ id: null, Question: "", Answer: "" });
        setIsEditing(false);
      } else {
        console.log("Creation failed:", createqueres?.message);
        enqueueSnackbar(createqueres?.message, { variant: "error" });
      }
    } catch (err) {
      console.error("Error:", err);
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  const updatefaq = async () => {
    const parser = new DOMParser();

    // Parse the input strings to DOM elements
    const questionDoc = parser.parseFromString(faq.Question, "text/html");
    const answerDoc = parser.parseFromString(faq.Answer, "text/html");

    // Check if the text content is empty after removing whitespace
    const isQuestionEmpty = !questionDoc.body.textContent.trim();
    const isAnswerEmpty = !answerDoc.body.textContent.trim();

    // If either field is empty, return from the function
    if (isQuestionEmpty || isAnswerEmpty) {
      enqueueSnackbar("Please enter both question and answer", {
        variant: "warning",
      });
      return;
    }
    const trimmedQuestion = faq.Question.trim();
    const trimmedAnswer = faq.Answer.trim();

    if (!trimmedQuestion || !trimmedAnswer) {
      enqueueSnackbar("Please add question and answer", { variant: "error" });
      return;
    }

    try {
      const req = {
        faqId: faq.id,
        question: faq.Question,
        answer: faq.Answer,
      };
      const updatequeres = await updatefaq_api(req);

      if (updatequeres?.message === "Updated Faq Successfully !!!") {
        enqueueSnackbar("Successfully updated FAQ", { variant: "success" });
        fetchFaqs();
        setFaq({ id: null, Question: "", Answer: "" });
        setIsEditing(false);
      } else {
        console.log("Update failed:", updatequeres?.message);
        enqueueSnackbar(updatequeres?.message, { variant: "error" });
      }
    } catch (err) {
      console.error("Error:", err);
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  const handleEditFaq = (faq) => {
    setFaq({
      id: faq.id,
      Question: faq.question,
      Answer: faq.answer,
    });
    setIsEditing(true);
    setValue(1); // Switch to FAQ tab
  };

  const fetchContentManagement = async (type) => {
    try {
      const req = { type };
      const defaultAuthKey = process.env.REACT_APP_DEFAULT_AUTHKEY;  // Use env variable for auth key
      const backendUrl = process.env.REACT_APP_BACKEND_URL;  // Use env variable for backend URL
  
      const headers = {
        "Content-Type": "application/json",
        Authorization: `${defaultAuthKey}`,
      };
  
      const response = await axios({
        method: "POST",
        url: `${backendUrl}/getContentManagement`,  // Dynamic backend URL
        data: req,
        headers: headers,
      });
  
      return response.data;
    } catch (err) {
      console.error("Error:", err);
      enqueueSnackbar(err.message, { variant: "error" });
    }
    return {};
  };
  

  const handleSaveFaq = () => {
    if (isEditing) {
      updatefaq();
    } else {
      createfaq();
    }
  };

  const handleTermsChange = (value) => {
    setTermsOfService(value);
  };

  useEffect(() => {
    // Scroll to top when component mounts
    window.scrollTo(0, 0);

    const fetchInitialContent = async () => {
      const termsData = await fetchContentManagement(2); // 2 for Terms of Services
      if (termsData?.data) {
        setTermsOfService(termsData.data.content);
        setTermsContentId(termsData.data.id);
      }

      const privacyData = await fetchContentManagement(1); // 1 for Privacy Policy
      if (privacyData?.data) {
        setPrivacyPolicy(privacyData.data.content);
        setPrivacyContentId(privacyData.data.id);
      }
    };

    fetchFaqs();
    fetchInitialContent();
  }, []);

  const handlePrivacyChange = (value) => {
    setPrivacyPolicy(value);
  };

  const handleSavePrivacy = () => {
    upsertContentManagement(1, privacyPolicy, privacyContentId); // 1 for Privacy Policy
  };

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [selectedFaq, setSelectedFaq] = useState(null);

  const handleOpenDeleteModal = (faq) => {
    setSelectedFaq(faq);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
    setSelectedFaq(null);
  };
  const deletefaq = async (id) => {
    try {
      const req = { faqId: id };
      const deletequeres = await deletefaq_api(req);

      if (deletequeres?.message === "Delete Faq Successfully !!!") {
        enqueueSnackbar("Successfully deleted FAQ", { variant: "success" });
        fetchFaqs(); // Refresh the FAQ list
        setFaq({ id: null, Question: "", Answer: "" }); // Reset the FAQ form
        setIsEditing(false); // Reset editing state
      } else {
        console.log("Deletion failed:", deletequeres?.message);
        enqueueSnackbar(deletequeres?.message, { variant: "error" });
      }
    } catch (err) {
      console.error("Error:", err);
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  const handleConfirmDelete = async () => {
    if (selectedFaq) {
      await deletefaq(selectedFaq.id);
      handleCloseDeleteModal();
    }
  };
  const handleDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(faqs);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setFaqs(items);
    // Optionally, send the new order to the backend here
  };
  const handleSaveFaqOrder = async () => {
    // Extract the IDs of the FAQs in their new order
    const orderedFaqIds = faqs.map((faq) => faq.id);

    // Construct the payload
    const payload = { faqIds: orderedFaqIds };

    try {
      // Call the API to update FAQ order
      const result = await updateFaqOrders_api(payload);
      console.log("FAQ order updated successfully:", result);

      // Display a success message using enqueueSnackbar
      enqueueSnackbar("FAQ order updated successfully!", {
        variant: "success",
      });

      // Optionally: Update the UI or refresh the FAQ list if needed
    } catch (error) {
      console.error("Error saving FAQ order:", error);
      // Optionally: Show an error message
      enqueueSnackbar("Failed to update FAQ order. Please try again.", {
        variant: "error",
      });
    }
  };

  return (
    <Grid container maxWidth="xl" sx={{ marginTop: "2rem" }}>
      <Box sx={{ width: "100%", typography: "body1" }}>
        <TabContext value={value}>
          <Grid
            container
            justifyContent={"space-between"}
            alignItems={"center"}
          >
            <Grid item>
              <Box>
                <Grid container>
                  <Grid item md={"auto"}>
                    <TabList
                      scrollButtons="On"
                      variant="scrollable"
                      orientation={{ xs: "horizontal", md: "none" }}
                      onChange={handleChange}
                      className={editorstyle.tablisted}
                      aria-label="lab API tabs example"
                    >
                      <Tab
                        sx={{ textTransform: "none", padding: "0px" }}
                        label="FAQ"
                        value={1}
                      />
                      <Tab
                        sx={{ textTransform: "none", padding: "0px" }}
                        label="Terms of Services"
                        value={2}
                      />
                      <Tab
                        sx={{ textTransform: "none", padding: "0px 20px" }}
                        label="Privacy Policy"
                        value={3}
                      />
                    </TabList>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>

          {/* tab 1 */}
          <TabPanel value={1} sx={{ padding: "2rem 0rem 1rem 0rem" }}>
            <Grid container>
              <DragDropContext onDragEnd={handleDragEnd}>
                <Droppable droppableId="faqs">
                  {(provided) => (
                    <Grid
                      item
                      md={5.5}
                      p={1}
                      height={"100vh"}
                      className={editorstyle.accdev}
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                    >
                      <Grid item container justifyContent={"end"} mb={2}>
                        <Modifiedbutton
                          data="Save"
                          swidth={"5rem"}
                          styles={{ borderRadius: "10px" }}
                          onClick={handleSaveFaqOrder}
                        />
                      </Grid>
                      {faqs.map((faq, index) => (
                        <Draggable
                          key={faq.id}
                          draggableId={String(faq.id)}
                          index={index}
                        >
                          {(provided) => (
                            <Accordion
                              className={editorstyle.accordianStyles}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <AccordionSummary
                                className={editorstyle.accordionSummaryStyles}
                                expandIcon={
                                  <KeyboardArrowRightIcon
                                    sx={{ color: color_exports.primary_color }}
                                  />
                                }
                                aria-controls={`panel${index}-content`}
                                id={`panel${index}-header`}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: faq.question,
                                  }}
                                ></div>
                                <div>
                                  <IconButton
                                    size="small"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                      handleEditFaq(faq);
                                    }}
                                  >
                                    <img src={editicon} alt="edit" />
                                  </IconButton>
                                  <IconButton
                                    size="small"
                                    onClick={() => handleOpenDeleteModal(faq)}
                                  >
                                    <img src={deleteicon} alt="delete" />
                                  </IconButton>
                                </div>
                              </AccordionSummary>
                              <AccordionDetails
                                className={editorstyle.accordionDetailsStyles}
                              >
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: faq.answer,
                                  }}
                                ></div>
                              </AccordionDetails>
                            </Accordion>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Grid>
                  )}
                </Droppable>
              </DragDropContext>

              <Grid item container direction={"column"} md={6} pl={3}>
                <Grid item fontSize={"20px"} fontWeight={700}>
                  {isEditing ? "Edit FAQ" : "Add New"}
                </Grid>
                <Grid item mt={2} fontSize={"15px"} fontWeight={700}>
                  Question
                </Grid>
                <Grid item mt={2} maxWidth={"100% !important"}>
                  <ReactQuill
                    className={editorstyle.editorbodyQuestion}
                    theme="snow"
                    value={faq.Question}
                    onChange={handleQuestionChange}
                    modules={modules}
                    formats={formats}
                  />
                </Grid>
                <Grid item mt={2} fontSize={"15px"} fontWeight={700}>
                  Answer
                </Grid>
                <Grid item mt={2}>
                  <ReactQuill
                    className={editorstyle.editorbody}
                    theme="snow"
                    value={faq.Answer}
                    onChange={handleAnswerChange}
                    modules={modules}
                    formats={formats}
                  />
                </Grid>
                <Grid item container justifyContent={"end"} mt={2}>
                  <Modifiedbutton
                    data={isEditing ? "Update" : "Save"}
                    swidth={"3rem"}
                    styles={{ borderRadius: "10px" }}
                    onClick={handleSaveFaq}
                  />
                </Grid>
              </Grid>
            </Grid>
          </TabPanel>

          {/* tab 2 */}
          <TabPanel value={2} sx={{ padding: "2rem 0rem 1rem 0rem" }}>
            <Grid container>
              <Grid item md={12} className={editorstyle.outercontainer}>
                <Box>
                  <Box className={editorstyle.innercontainer}>
                    <Box className={editorstyle.textbox}>
                      <ReactQuill
                        className={editorstyle.editorbodyterms}
                        theme="snow"
                        value={termsOfService}
                        onChange={handleTermsChange}
                        modules={modules}
                        formats={formats}
                        placeholder={"Enter the Terms of Service"}
                      />
                    </Box>
                    <Grid item container px={3} justifyContent={"end"} mt={2}>
                      <Modifiedbutton
                        data={"Save"}
                        swidth={"2rem"}
                        styles={{ borderRadius: "10px" }}
                        onClick={handleSaveTerms}
                      />
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>

          {/* tab 3 */}
          <TabPanel value={3} sx={{ padding: "2rem 0rem 1rem 0rem" }}>
            <Grid item container>
              <Grid item md={12} className={editorstyle.outercontainer}>
                <Box>
                  <Box className={editorstyle.innercontainer}>
                    <Box className={editorstyle.textbox}>
                      <ReactQuill
                        className={editorstyle.editorbodyterms}
                        theme="snow"
                        value={privacyPolicy}
                        onChange={handlePrivacyChange}
                        modules={modules}
                        formats={formats}
                        placeholder={"Enter the Privacy Policy"}
                      />
                    </Box>
                    <Grid item container px={3} justifyContent={"end"} mt={2}>
                      <Modifiedbutton
                        data={"Save"}
                        swidth={"2rem"}
                        borderRadius={"10px"}
                        onClick={handleSavePrivacy}
                      />
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </TabPanel>
        </TabContext>
      </Box>
      <Deletefaq
        open={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        onConfirm={handleConfirmDelete}
      />
    </Grid>
  );
};

export default Content_management;
