import { Dialog, Grid } from "@mui/material";
import React from "react";
import { color_exports } from "../Styles/Color_exports";
import { useNavigate } from "react-router-dom";

const Logout = (props) => {
  const { open, close } = props;
  const navigate = useNavigate();
  const handleLogout = () => {
    console.log("Admin logged out");
    localStorage.removeItem("token");
    navigate("/");
    close();
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={close}
        PaperProps={{
          sx: {
            m: 0,
            width: 310,
            maxWidth: 310,
            borderRadius: "1rem",
            "::-webkit-scrollbar": { display: "none" },
            "@media screen and (min-width: 200px) and (max-width: 599px)": {
              width: "90%",
              maxWidth: "90%",
            },
          },
        }}
      >
        <Grid
          container
          direction={"column"}
          m={2}
          gap={1}
          justifyContent={"center"}
        >
          <Grid
            item
            sx={{ color: color_exports.tex_shade_color, paddingLeft: "25px" }}
          >
            Are you sure, you want to Logout?
          </Grid>
          <Grid container item justifyContent={"flex-end"} gap={1}>
            <Grid
              item
              sm={2}
              sx={{
                color: color_exports.primary_color,
                fontWeight: "700",
                cursor: "pointer", // Add cursor pointer here
              }}
              onClick={close}
            >
              No
            </Grid>
            <Grid
              item
              sm={2}
              sx={{
                color: color_exports.primary_color,
                fontWeight: "700",
                cursor: "pointer", // Add cursor pointer here
              }}
              onClick={handleLogout}
            >
              Yes
            </Grid>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default Logout;
