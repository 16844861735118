import axios from './Apiservices/Janathaclient';
import { refreshtokenApi } from './Apiservices/Commonservices';
import './App.css';
import Router from './Routes/Router';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { isAccessTokenExpired, StorageClient } from './Helpers/Helperfunctions';
import { Envexports } from './Exports/Envexports';
const theme = createTheme({
  typography: {
    fontFamily: "'Open Sans', sans-serif",
  },
});

function App() {
  axios.interceptors.request.use(async (request) => {
    try{
      const token = StorageClient.getItem("token");
      let authorizationkey = token ? token :  Envexports?.defaultauthkey;
    if(isAccessTokenExpired()){
      const accesstoken = await refreshtokenApi()
      authorizationkey = accesstoken;
    }
    request.headers["Authorization"] = authorizationkey;
    return request;
  }
  catch(err){
    console.log(err);
    return Promise.reject(err);
  }});
  return (
    <>
    <ThemeProvider theme={theme}>
      <Router/>       
    </ThemeProvider>
    </>
  );
}

export default App;
