import React from 'react'
import Loginroutes from './Loginroutes'

const Router = () => {
  return (
    <Loginroutes/>
  )
}

export default Router;
