import { makeStyles } from "@mui/styles";
import { color_exports } from "./Color_exports";
const sub_head = "16px";
export const dashboardstyles = makeStyles({
  piechartsty: {
    width: "250px",
    marginLeft: "3rem",
  },
  chipsize: {
    // width: "100%",
    "@media screen and (min-width: 300px) and (max-width: 399px)": {
      width: "80px",
    },
    "@media screen and (min-width: 600px) and (max-width: 899px)": {
      width: "110px",
    },
    "@media screen and (min-width: 900px) and (max-width: 1099px)": {
      width: "130px",
    },
  },
  resimage: {
    width: "100%",
    "@media screen and (min-width: 300px) and (max-width: 599px)": {
      width: "30px",
    },
    "@media screen and (min-width: 600px) and (max-width: 699px)": {
      width: "40px",
    },
  },
  textsize: {
    fontSize: "12px",
    "@media screen and (min-width: 300px) and (max-width: 319px)": {
      fontSize: "9px",
    },
    "@media screen and (min-width: 320px) and (max-width: 599px)": {
      fontSize: "10px",
    },
  },
  ellipsisText: {
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    overflow: "hidden",
    WebkitLineClamp: 2, // Number of lines to show
  },
  textnowarp: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  responsiveavatar: {
    "& .MuiAvatar-root": {
      "@media screen and (min-width: 600px) and (max-width: 650px)": {
        width: "28px !important",
        height: "28px !important",
      },
    },
  },
  favouritecardtext: {
    fontSize: "28px",
    "@media screen and  (min-width: 900px) and (max-width: 1015px)": {
      fontSize: "19px",
    },
    "@media screen and  (min-width: 1016px) and (max-width: 1142px)": {
      fontSize: "22px",
    },
    "@media screen and  (min-width: 1143px) and (max-width: 1270px)": {
      fontSize: "25px",
    },
  },
  cardhead: {
    fontSize: `${28}px`,
    fontWeight: 700,
  },
  cardsub: {
    fontSize: `${sub_head}`,
    color: color_exports?.shade_color,
    "@media screen and (min-width: 900px) and (max-width: 1200px)": {
      fontSize: "10px",
    },
  },
  cardsub_txt: {
    fontSize: `${sub_head}`,
    fontWeight: 700,
    "@media screen and (min-width: 900px) and (max-width: 1200px)": {
      fontSize: "12px",
    },
  },
  msgs_style: {
    backgroundColor: color_exports.orange_color,
    borderRadius: "1rem",
  },
  msgs_head: {
    fontSize: `${sub_head}`,
    fontWeight: 700,
  },
  table_container: {
    maxWidth: "100%",
    overflowX: "scroll",
    paddingBottom: "0.5rem",
  },
  table_sub_container: {
    "@media screen and (max-width: 1520px)": {
      maxWidth: "1166px",
      minWidth: "1166px",
    },
  },
  table_head: {
    fontSize: sub_head,
    color: color_exports.shade_color,
    // "@media screen and (min-width: 900px) and (max-width: 1200px)": {
    //   fontSize: "12px",
    // },
  },
  table_headtxt: {
    paddingLeft: "6px",
    borderRight: "2px solid rgba(51, 51, 51, 0.3)",
    // height: "4rem",
    "& .MuiStack-root": {
      maxWidth: "100%",
      minWidth: "100%",
    },
    "& .MuiGrid-item": {
      // height: "4rem",
      maxWidth: "100%",
      wordWrap: "break-word",
    },
  },
  poppertext: {
    fontSize: "1rem",
    color: color_exports.skyblue_color,
    fontFamily: "sans",
  },
  // completed project details styles
  movie_details_sub1: {
    fontSize: "17px",
    color: color_exports.shade_color,
    opacity: "50%",
  },
  movie_details_sub2: {
    fontSize: "17px",
    fontWeight: 700,
  },
  artist_title: {
    fontSize: "28px",
    fontWeight: 700,
    width: "100%",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  artist_price: {
    color: color_exports.skyblue_color,
    fontWeight: 700,
    fontSize: "20px",
  },
  /* Rating given styles*/
  bottomborder_style: {
    fontWeight: "700",
    fontSize: "28px",
    display: "inline-block",
    padding: "0 10px 10px 10px", // Adjust the distance from the text as needed
    textAlign: "center",
    position: "relative",
    borderRadius: "0.5rem", // Red border at the center
    "&::after": {
      content: '""',
      position: "absolute",
      bottom: 0,
      left: "50%",
      transform: "translateX(-50%)",
      width: "20px", // Adjust the width of the border as needed
      borderBottom: `3px solid ${color_exports.skyblue_color}`,
    },
  },
  rating_subhead: {
    fontSize: "18px",
    fontWeight: 700,
  },
  // My favourite stylings
  favorite: {
    "& .MuiTab-root.Mui-selected": {
      color: `${color_exports.primary_color}!important`,
      backgroundColor: `${color_exports.iconactive}!important`,
      borderRadius: "0.5rem 0.5rem 0rem 0rem",
      fontWeight: "700 !important",
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
    "& .MuiTab-root": {
      padding: "0px",
      minWidth: "fit-content",
    },
    "& .MuiTabs-flexContainer": {
      // gap:'0.5rem',
    },
  },
  dashusersstyles: {
    backgroundImage: `linear-gradient(to top, #FFFFFF, #F9CDDC)`,
    padding: "1rem",
    paddingBottom: "0rem",
    fontWeight: "700",
    fontSize: "16px",
  },
  dashmembershipstyles: {
    borderRadius: "1rem",
    overflow: "hidden",
    height: "100%",
  },
  dashmessagesstyles: {
      marginTop: "0.5rem",
      padding: "1.5rem",
      borderRadius: "1rem",
      height: "calc(100% - 3rem)",
  },
  tabbtns: {
    color: "#333333 !important",
    backgroundColor: `${color_exports.iconactive}!important`,
    borderRadius: "0.5rem!important",
    textTransform: "none!important",
    // width:'151px',
    height: "2.2rem!important",
    minHeight: "1rem!important",
    fontWeight: "400!important",
    marginLeft: "0.5rem!important",
  },
  text_shadecolor: {
    color: "#828282",
    // width: '100%',
    // whiteSpace: "nowrap",
    // overflow: "hidden",
    // textOverflow: "ellipsis",
  },
  casting_text: {
    color: "#333333",
    fontWeight: 700,
    fontSize: "26px",
    maxWidth: "100% !important",
    overflow: "hidden",
    wordWrap: "break-word",
    "@media screen and (min-width: 100px) and (max-width: 599px)": {
      fontSize: "16px !important",
    },
  },
  ratingstyle: {
    margin: "0px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
});
