import React, { useState, useEffect } from "react";
import { Grid, Paper, CircularProgress, IconButton } from "@mui/material";
import subadmin1 from "../assets/Images/subadmin1.png";
import { subadminstyles } from "../Styles/Contentmanagement_styles";
import editicon from "../assets/Icons/editicon.png";
import deleteicon from "../assets/Icons/deleteicon.png";
import { retrievesubadmins_Api, deletesubadmin_api } from "../Apiservices/Adminservices";
import Deletesubadmin from "../Models/Deletesubadmin";

const SubadminInfo = ({ onEdit, refresh}) => {
  const subadminstyle = subadminstyles();
  const [subAdmins, setSubAdmins] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedSubadmin, setSelectedSubadmin] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const fetchSubAdmins = async () => {
    try {
      const req = {
        min: 0,
        max: 50,
      };
      const retrievesubadminres = await retrievesubadmins_Api(req);

      if (
        retrievesubadminres?.message ===
        "Sub Admin details retrieved successFully"
      ) {
        const subAdminData = retrievesubadminres.data;
        const subAdminArray = Object.values(subAdminData.rows).filter(
          (item) => typeof item === "object"
        );
        setSubAdmins(subAdminArray);
        setLoading(false);
      } else {
        console.log("Failed:", retrievesubadminres?.message);
        setError(retrievesubadminres?.message);
      }
    } catch (err) {
      console.error("Error:", err);
      setError(err.message);
    }
  };
  useEffect(() => {
    
    fetchSubAdmins();
  }, []);
  useEffect (() => {
    // if (refresh) {
      fetchSubAdmins();
    // }
  }, [refresh]);

  const handleOpenDeleteModal = (subadmin) => {
    setSelectedSubadmin(subadmin);
    setIsDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  const handleDeleteSubadmin = async () => {
    try {
      const response = await deletesubadmin_api({ adminId: selectedSubadmin.adminId });
      if (response?.message === "Sub Admin deleted") {
        setSubAdmins((prevSubAdmins) =>
          prevSubAdmins.filter((item) => item.adminId !== selectedSubadmin.adminId)
        );
      } else {
        console.error("Failed to delete subadmin:", response?.message);
      }
    } catch (error) {
      console.error("Error:", error);
    }
    handleCloseDeleteModal();
  };

  return (
    <div>
      {loading && <CircularProgress />}
      {error && <div>Error: {error}</div>}
      {!loading && !error && (
        <Grid container spacing={2}>
          {subAdmins.map((subAdmin) => (
            <Grid item md={12} key={subAdmin.adminId} mb={1}>
              <Paper elevation={5} className={subadminstyle.paper}>
                <Grid container direction="column" spacing={1}>
                  <Grid item container justifyContent="space-between">
                    <Grid item md="auto">
                      <img src={ subAdmin.profilePic ? `${process.env.REACT_APP_IMAGES}/subAdminProfiles/${subAdmin.profilePic}` : subadmin1} alt="Subadmin" className={subadminstyle.subadminimg} />
                    </Grid>
                    <Grid item md container ml={1} spacing={1}>
                      <Grid item md={12} className={subadminstyle.subadminname}>
                        {subAdmin.name}
                      </Grid>
                      <Grid item container md={12} className={subadminstyle.phnnum}>
                        <Grid item md={4}>Phone Number</Grid>
                        <Grid item md={1}></Grid>
                        <Grid item md={7}>Location</Grid>
                      </Grid>
                      <Grid item container md={12} justifyContent={"space-between"} className={subadminstyle.location} mt={-0.5}>
                        <Grid item md={4}>{subAdmin.phoneNumber}</Grid>
                        <Grid item md={1}></Grid>
                        <Grid item md={7}> {subAdmin.cityName}, {subAdmin.countryName}</Grid>
                      </Grid>
                      <Grid item md={12} className={subadminstyle.phnnum} mt={1}>
                        Member Since
                      </Grid>
                      <Grid item md={12} mt={-0.5}>
                        {new Date(subAdmin.createdAt).toLocaleDateString()}
                      </Grid>
                      <Grid item md={12} className={subadminstyle.phnnum} mt={1}>
                        Privileges
                      </Grid>
                      <Grid item md={12} mt={-0.5} pb={1.5}>
                        {subAdmin.privileges &&
                          Object.keys(subAdmin.privileges)
                            .filter((key) => subAdmin.privileges[key] === 1)
                            .map((key) =>
                              key
                                .replace(/_/g, " ")
                                .replace(/^\w/, (c) => c.toUpperCase())
                            )
                            .join(",  ")}
                      </Grid>
                    </Grid>
                    <Grid item md="auto">
                      <img src={editicon} alt="Edit" onClick={() => onEdit(subAdmin)} style={{ cursor: "pointer" }}/>
                    </Grid>
                    <Grid item md="auto" mt={-1}>
                      <IconButton onClick={() => handleOpenDeleteModal(subAdmin)}>
                        <img src={deleteicon} alt="Delete" />
                      </IconButton>
                    </Grid>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          ))}
        </Grid>
      )}
      <Deletesubadmin open={isDeleteModalOpen} onClose={handleCloseDeleteModal} onConfirm={handleDeleteSubadmin}/>
    </div>
  );
};

export default SubadminInfo;
