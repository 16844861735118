import React from "react";

const LeftArrow = (props) => {
  return (
    <>
      <svg
        width="11"
        height="19"
        viewBox="0 0 7 12"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.72801 0.999042L0.941162 5.78588L5.72801 10.5727"
          stroke="#B8003E"
          stroke-width="1.19422"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </>
  );
};

export default LeftArrow;
