import { makeStyles } from "@mui/styles";
import { color_exports } from "./Color_exports";

export const chatstyles = makeStyles({
  chatbox: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 800,
    height: 500,
    bgcolor: "background.paper",
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
    outline: 0,
    borderRadius: 5,
  },
  paper1: {
    height: "65vh",
    display: "flex",
    flexDirection: "column",
    overflowY: "scroll",
  },
  typo: {
    fontSize: 14,
    textAlign: "center",
    fontWeight: "bold",
    color: "#000",
  },
});

export const viewstyles = makeStyles({
  paper: {
    padding: "1.5rem",
    borderRadius: "1rem",
    height: "calc(100% - 3rem)",
    paddingBottom: "3rem",
  },
  viewstoryprflimg: {
    width: "100px",
    height: "100px",
    borderRadius: "10px",
    objectFit: "cover",
  },
  happymomentimages: {
    width: "100px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "15px",
    marginRight: "6rem"
  }
});
export const storystyles = makeStyles({
  paper: {
    padding: "1rem",
    borderRadius: "1rem",
    cursor: "pointer",
    height: "100px !important",
  },
  prflimg: {
    width: "50px",
    height: "50px",
    borderRadius: "50%",
  },
  storyname: {
    color: color_exports.tex_shade_color,
    fontSize: "12px",
  },
  successstory: {
    fontSize: "12px",
    maxHeight: "80px",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
  MuiResponsiveChart: {
    marginRight: "5px",
    width: "245px",
  }
});

export const postsuccessstorystyles = makeStyles({
  paper: {
    padding: "1.5rem",
    borderRadius: "1rem",
    height: "calc(100% - 3rem)",
  },
});

export const modifiedtablestyles = makeStyles({
  tablecontainer: {
    borderRadius: "1rem",
    overflow: "auto",
  },
  tablecell: {
    width: "fit-content",
    backgroundImage: `linear-gradient(to top, #FFFFFF, #F9CDDC)`,
    padding: "0px 0px 0px 10px",
    borderTopLeftRadius: "1rem",
    borderBottom: "2px solid #fff",
  },
  tablecell1: {
    backgroundImage: `linear-gradient(to top, #FFFFFF, #F9CDDC)`,
    borderBottom: "3px solid #fff",
    padding: "0px 16px",
    color: "#333333",
    fontWeight: 400,
    fontSize: "16px",
    height: "46px",
  },
  "&::before": {
    content: '""',
    position: "absolute",
    inset: 0,
    borderRadius: 0,
    background: "linear-gradient(to top, #FFFFFF, #B8003E)",
    zIndex: -1,
    width: "1px",
    right: "0px !important",
    left: "unset",
  },
  tablecell2: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  tablehead1: {
    width: "fit-content",
                  backgroundImage: `linear-gradient(to top, #FFFFFF, #F9CDDC)`,
                  padding: "0px 0px 0px 10px",
                  borderTopLeftRadius: "1rem",
                  borderBottom: "2px solid #fff",
  },
  tablehead2: {
    backgroundImage: `linear-gradient(to top, #FFFFFF, #F9CDDC)`,
    borderBottom: "3px solid #fff",
    padding: "0px 16px",
    color: "#333333",
    fontWeight: 400,
    fontSize: "16px",
    height: "46px",
  }
});

export const CircleCheckboxstyles = makeStyles({
  icon: {
    borderRadius: "50%",
    backgroundColor: "gray",
    width: 20,
    height: 20,
  },
  checkedicon: {
    borderRadius: "50%",
    backgroundColor: "#b8003e",
    width: 20,
    height: 20,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "white",
  },
});

export const searchbtnstyles = makeStyles({
  searchbtn: {
    "& .MuiInputBase-root": {
      paddingRight: "0px",
      paddingLeft: "0px",
    },
    padding: "0px",
    // height: height===undefined?"3rem":height,
    background: "#F1F1F1 !important",
    borderRadius: "3rem",
    "& fieldset": {
      border: "none",
    },
  },
  searchButtonStyle: {
    "&:hover": {
      backgroundColor: "darkred",
    },
  }
});

