import React, { useState, useEffect, useRef } from "react";
import { db, storage } from "../../Firebase/Firebaseconfig";
import { v4 as uuidv4 } from "uuid";
import {
  filetype_checking,
  handleVideoUpload,
} from "../../Helpers/Helperfunctions";
import chataddimg from "../../assets/Images/chataddimg.png";
import { getDownloadURL, ref, uploadBytesResumable } from "firebase/storage";
import FullViewMedia from "../../Models/FullViewMedia";
import {
  update_ownroom_badgecount,
  create_update_message,
} from "../../Firebase/Firebaseservices";
import { enqueueSnackbar } from "notistack";
import Modifiedbutton from "../Modifiedbutton";
import { chatmessagestyles } from "../../Styles/Chatstyles";
import user1 from "../../assets/Images/user1.png";
import {
  Avatar,
  Grid,
  Typography,
  IconButton,
  Menu,
  MenuItem,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { color_exports } from "../../Styles/Color_exports";
import { handleblockusers } from "../../Firebase/Firebaseservices";
import {
  collection,
  onSnapshot,
  query,
  orderBy,
  where,
  serverTimestamp,
} from "firebase/firestore";

const formatTimestamp = (timestamp) => {
  if (!timestamp) {
    return { date: "Unknown date", time: "Unknown time" }; // Return default messages if timestamp is null
  }
  const date = timestamp.toDate(); // Convert Firestore timestamp to JavaScript Date object
  return {
    date: date.toLocaleDateString(), // Format the date as a readable string
    time: date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" }), // Format the time as HH:MM
  };
};

const Chat = ({ selected, chatroomchange }) => {
  const chatstyle = chatmessagestyles();
  const name = selected?.user_details?.name ?? "";
  // const content_type = selected?.contentType ?? "";
  const receiver_image = selected?.user_details?.photo ?? "";
  const [display_messages, setDisplay_messages] = useState([]);
  const [inputtext, setInputtext] = useState("");
  const [file, setFile] = useState(null);
  const [fileprogress, setFileprogress] = useState(0);
  const [recent, setRecent] = useState("recentMessage");
  const [content_type, setContent_type] = useState("text");
  const [isSending, setIsSending] = useState(false);
  // const value = collection(db, "conversation_dev"); //statging
  const value = collection(db, "conversation"); //final
  const [fullViewMedia, setFullViewMedia] = useState({
    open: false,
    media: null,
    index: 0,
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const unSubscribe = useRef(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const recent_message =
    content_type === "text"
      ? selected?.text
      : content_type === "image"
      ? "Image"
      : content_type === "video"
      ? "Video"
      : "";

  /**
   * Handling for blocking the users
   */
  const handleblock = async () => {
    try {
      const blockres = await handleblockusers([selected.roomId]);
      console.log(blockres, "blockres");

      if (blockres?.status) {
        const message = blockres?.data?.blockedUsers?.includes("admin")
          ? "User blocked successfully"
          : "User unblocked successfully";
        enqueueSnackbar(message, { variant: "success" });

        // Update the chatroom without hiding the chat details
        chatroomchange({
          ...selected,
          blockedUsers: blockres?.data?.blockedUsers,
        });

        handleClose();
      } else {
        console.log(blockres?.message);
      }
    } catch (err) {
      console.log(err);
    }
  }; // Custom styles for chat messages

  // Function to handle opening media in full view
  const handleFullViewMedia = (data, mediaIndex) => {
    setFullViewMedia((prev) => ({
      ...prev,
      open: true,
      media: data,
      index: mediaIndex,
    }));
  };

  // Function to fetch and update chat messages
  const get_messages = async () => {
    try {
      if (unSubscribe.current) {
        unSubscribe.current();
      }

      const getmsgs_query = query(
        // collection(db, "conversation_dev"), //staging
        collection(db, "conversation"), //final
        where("roomId", "==", selected?.roomId),
        where("nonDeletedUsers", "array-contains", "admin"),
        orderBy("createdAt", "desc")
      );

      unSubscribe.current = onSnapshot(getmsgs_query, (snapshot) => {
        const messages = snapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        messages.sort((a, b) => a.createdAt - b.createdAt); // Sorting by createdAt
        update_ownroom_badgecount(selected); // Update badge count for the room
        setDisplay_messages(messages); // Set messages in state
      });
    } catch (err) {
      console.error("Error fetching messages: ", err);
    }
  };

  // Fetch messages whenever the selected room changes
  useEffect(() => {
    if (selected) {
      get_messages();
    }
  }, [selected]);

  // Fetch messages on initial render
  useEffect(() => {
    get_messages();
  }, []);

  const handlesubmit = async (props = {}) => {
    if (selected.blockedUsers.includes("admin")) {
      enqueueSnackbar("To send the messages please unblock the user", {
        variant: "warning",
      });
      return;
    }
  
    const {
      msg_type = "text",
      files = {},
      filenames = [],
      file_extension = "",
    } = props;
  
    let message_type = msg_type ? msg_type : content_type;
  
    // If input is empty and it's text message type, return early
    if (inputtext === "" && message_type === "text") return;
  
    let file_url = files?.fileurl ?? "";
    let file_size = files?.filesize ?? "";
    let thumbnail_url = files?.thumbnile ?? "";
    let filename = files?.filename ?? "";
    let storefilename = filenames !== undefined && filenames.length > 0 ? filenames[0] : "";
  
    // Immediately clear the input field before sending the message
    setInputtext(""); // Clear input text immediately
  
    const message = {
      messageID: new Date().getTime().toString(),
      contentType: msg_type,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
      text: inputtext, // The input is cleared, so it won't include any text
      fileUrl: file_url,
      fileName: filename,
      duration: "",
      senderID: "admin",
      roomId: selected?.roomId,
      isReply: false,
      replyMessageFileName: "",
      replyMessageId: "",
      replyMessageUserName: "",
      replyMessageUserId: "",
      replyMessageText: "",
      replyMessageContentType: "",
      replyMessageThumbUrl: "",
      replyMessageFileUrl: "",
      deletedUsers: [],
      fileSize: file_size,
      storeFileName: storefilename,
      thumbUrl: thumbnail_url,
      fileType: "",
      nonDeletedUsers: [
        selected.memberIds.filter((id) => id !== "admin")[0],
        "admin",
      ],
      blockedUsers: [],
      nonBlockedUsers: [
        selected.memberIds.filter((id) => id !== "admin")[0],
        "admin",
      ],
      pinUserIds: [],
      platform: "web",
      isEdited: false,
      receiverId: selected.memberIds.filter((id) => id !== "admin")[0],
      senderName: "admin",
    };
  
    try {
      // Create or update the message
      await create_update_message({ message, selected });
  
      // Reset other states after message sending
      setContent_type("text");
      setRecent("recentMessage");
      setIsSending(false); // Reset sending state
  
    } catch (err) {
      console.log(err);
      setIsSending(false); // Reset sending state on error
    }
  };
  
  

  const handlefilechange = async (e) => {
    try {
      if (selected.blockedUsers.includes("admin")) {
        enqueueSnackbar("To send the messages please unblock the user", {
          variant: "warning",
        });
        return;
      }
      let uploaded_file = e.target.files[0];
      let file_type = await filetype_checking(uploaded_file);
      let video_thumbnail;
      if (file_type === "video") {
        video_thumbnail = await handleVideoUpload(uploaded_file, 1.5);
      }
      if (file_type === "video" && uploaded_file.size > 10 * 1024 * 1024) {
        alert("Video upload should not be greater than 5Mb");
        e.target.value = "";
        return;
      }
      if (uploaded_file) {
        setFile(uploaded_file);
        let uploads;
        let uniquefile_names;
        if (file_type === "video") {
          uploads = [uploaded_file, video_thumbnail];
          let uploadfile_name = `${uuidv4()}_${uploaded_file?.name}`;
          let thumnile_name = `${uuidv4()}_${video_thumbnail?.name}`;
          uniquefile_names = [uploadfile_name, thumnile_name];
        } else {
          uploads = [uploaded_file];
          let uploadfile_name = `${uuidv4()}_${uploaded_file?.name}`;
          uniquefile_names = [uploadfile_name];
        }
        let file_extension = uploaded_file?.name
          ?.split(".")
          .pop()
          .toLowerCase();
        handleUpload(uploads, uniquefile_names, file_type, file_extension);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleUpload = async (
    files,
    uniquefile_names,
    file_type,
    file_extension
  ) => {
    let urls = {};
    for (let i = 0; i < files.length; i++) {
      let file = files[i];
      let filename = uniquefile_names[i];
      const storageRef = ref(storage, `chat/${selected?.id}/${filename}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      try {
        await new Promise((resolve, reject) => {
          uploadTask.on(
            "state_changed",
            (snapshot) => {
              const progress =
                (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              setFileprogress(progress);
            },
            (error) => {
              console.error(error);
              reject(error);
            },
            () => {
              getDownloadURL(uploadTask.snapshot.ref)
                .then((downloadURL) => {
                  const fileSizeInBytes = file.size;
                  const fileSizeInMB = fileSizeInBytes / (1024 * 1024);
                  const filesize = `${fileSizeInMB.toFixed(2)}MB`;

                  if (i === files.length - 1) {
                    if (file_type === "video") {
                      urls.thumbnile = downloadURL;
                    } else {
                      urls.fileurl = downloadURL;
                      urls.filesize = filesize;
                      urls.filename = file.name;
                    }
                    handlesubmit({
                      msg_type: file_type,
                      files: urls,
                      filenames: uniquefile_names,
                      file_extension: file_extension,
                    });
                  } else {
                    urls.fileurl = downloadURL;
                    urls.filesize = filesize;
                    urls.filename = file.name;
                  }
                  resolve();
                })
                .catch((error) => {
                  console.error(error);
                  reject(error);
                });
            }
          );
        });
      } catch (error) {
        console.error(error);
      }
    }
  };
  return (
    <>
      <Grid
        style={{
          borderTopRightRadius: "15px",
          borderTopLeftRadius: "15px",
          "::-webkit-scrollbar": { display: "none" },
          maxHeight: "80px",
          height: "80px",
        }}
        className={chatstyle.userchatinfo1}
      >
        <Grid className={chatstyle.userchat1}>
          <Grid item xs={1}>
            {receiver_image ? (
              <Avatar src={receiver_image ?? ""} alt="receiverimage" />
            ) : (
              <Avatar />
            )}
          </Grid>

          <Grid className={chatstyle.userchatinfo} xs={11}>
            <span className={chatstyle.username1}>{name}</span>
            <Typography className={chatstyle.usermessage1}>
              {recent_message}
            </Typography>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={handleClick}>
              <MoreVertIcon sx={{ color: color_exports.btntext_color }} />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <>
        {fullViewMedia.open && (
          <FullViewMedia
            open={fullViewMedia.open}
            close={() => setFullViewMedia((prev) => ({ ...prev, open: false }))}
            data={fullViewMedia.media}
            selectedIndex={fullViewMedia.index}
          />
        )}
        <Grid className={chatstyle.chatmessages}>
          {console.log(display_messages, "display messages")}
          {display_messages.map((values, index) => {
            const { date, time } = formatTimestamp(values.createdAt);
            const showDate =
              index === 0 ||
              date !==
                formatTimestamp(display_messages[index - 1]?.createdAt).date;

            return (
              <React.Fragment key={values.id}>
                {showDate && (
                  <Typography className={chatstyle.date}>{date}</Typography>
                )}
                <Grid
                  className={
                    values.senderID === "admin"
                      ? chatstyle.senderstyle
                      : chatstyle.receieverstyle
                  }
                  onClick={() => {
                    if (["image", "video"].includes(values?.contentType)) {
                      handleFullViewMedia(values);
                    }
                  }}
                >
                  <Typography>
                    {values.contentType === "text" ? (
                      <>
                        <Grid className={chatstyle.chatmsg}>
                          <Typography className={chatstyle.timestamp1}>
                            {values.text}
                          </Typography>
                          <Typography className={chatstyle.timestamp}>
                            {time}
                          </Typography>
                        </Grid>
                      </>
                    ) : values.contentType === "image" ? (
                      <>
                        <img
                          src={values.fileUrl}
                          alt="img"
                          style={{ width: "200px", borderRadius: "0.5rem" }}
                        />
                        <Typography
                          className={chatstyle.timestamp}
                          style={{ fontSize: "10px !important" }}
                        >
                          {time}
                        </Typography>
                      </>
                    ) : values.contentType === "video" ? (
                      <div style={{ width: "200px", position: "relative" }}>
                        <img
                          src={values.thumbUrl}
                          alt={values.fileName}
                          style={{
                            width: "200px",
                            aspectRatio: 16 / 10,
                            borderRadius: "0.5rem",
                          }}
                        />
                        <Grid
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            transform: "translate(-50%, -50%)",
                          }}
                        >
                          <div>
                            <svg
                              width="35"
                              height="35"
                              viewBox="0 0 85 86"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <rect
                                x="0.0585938"
                                y="0.766113"
                                width="84.9375"
                                height="84.9375"
                                rx="42.4688"
                                fill="black"
                                fillOpacity="0.5"
                              />
                              <path
                                d="M63.4625 41.2706C65.7706 42.6032 65.7706 45.9346 63.4625 47.2672L35.1385 63.6201C32.8304 64.9526 29.9453 63.2869 29.9453 60.6218L29.9453 27.916C29.9453 25.2509 32.8304 23.5852 35.1385 24.9177L63.4625 41.2706Z"
                                fill="white"
                              />
                            </svg>
                          </div>
                        </Grid>
                        <Typography
                          className={chatstyle.timestamp}
                          style={{ fontSize: "12px !important" }}
                        >
                          {time}
                        </Typography>
                      </div>
                    ) : null}
                  </Typography>
                </Grid>
              </React.Fragment>
            );
          })}
          {selected?.blockedUsers?.includes("admin") && (
            <Typography className={chatstyle.blockedMessage}>
              You blocked this user.
            </Typography>
          )}
        </Grid>
        {!selected?.blockedUsers?.includes("admin") && (
          <Grid className={chatstyle.inputstylesofchat}>
            <Grid className={chatstyle.emoji}>
              <label htmlFor={"files"}>
                <img
                  src={chataddimg}
                  alt="plus"
                  style={{ cursor: "pointer" }}
                />
              </label>
              <input
                type="file"
                id="files"
                style={{ display: "none" }}
                accept=".jpg, .png, .jpeg, .mp4, .mov, .avi, .mkv, .webm"
                onChange={handlefilechange}
              />
            </Grid>
            <textarea
              value={inputtext}
              placeholder="Enter your message"
              onChange={(e) => {
                setContent_type("text");
                setInputtext(e.target.value);
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey) {
                  // Prevent default behavior and send the message
                  e.preventDefault();
                  if (inputtext.trim() !== "") {
                    handlesubmit();
                  }
                }
              }}
              className={chatstyle.msginputfield}
            />

            <Modifiedbutton
              data={"Send"}
              swidth={"6rem"}
              onClick={handlesubmit}
              sheight={"2.5rem"}
              className={chatstyle.btnstyle}
            />
          </Grid>
        )}
      </>

      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          style: {
            maxHeight: 48 * 4.5,
            width: "20ch",
          },
        }}
      >
        <MenuItem onClick={handleblock}>
          {selected?.blockedUsers?.includes("admin") ? "Unblock" : "Block"}
        </MenuItem>
      </Menu>
    </>
  );
};

export default Chat;
