import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";

const Revenuechart = ({ data, reportBy }) => {
  if (!data) return null;

  const { revenueData = [] } = data;

  // Mapping functions for different x-axis data representations
  const mapDatePartToDay = (day) => {
    const daysOfWeek = ["Wed", "Thu", "Fri", "Sat", "Sun", "Mon", "Tue"];
    return daysOfWeek[day % 7];
  };

  const mapDatePartToDate = (day) => `${day}`;

  const mapDatePartToMonth = (month) => {
    const monthsOfYear = [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ];
    return monthsOfYear[month - 1];
  };

  // Generate the data structure for the chart
  const getCombinedDataTemplate = () => {
    if (reportBy === 2) { // Daily
      return ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map(day => ({
        name: day, Revenue: 0,
      }));
    } else if (reportBy === 4) { // Monthly
      return ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"].map((month, index) => ({
        name: mapDatePartToMonth(index + 1), Revenue: 0,
      }));
    } else { // Weekly (assuming each day of the month)
      return Array.from({ length: 31 }, (_, i) => ({
        name: mapDatePartToDate(i + 1), Revenue: 0,
      }));
    }
  };

  const combinedData = getCombinedDataTemplate();

  // Update counts in combinedData
  const updateDataCounts = (dataArray = [], dataArrayName, mapFunction) => {
    if (!Array.isArray(dataArray)) return;
    dataArray.forEach((item) => {
      const label = mapFunction(reportBy === 4 ? item.monthNumber : item.day);
      const index = combinedData.findIndex((dataItem) => dataItem.name === label);
      if (index !== -1) {
        combinedData[index][dataArrayName] += item.revenueCount;
      }
    });
  };

  if (reportBy === 2) { // Daily
    updateDataCounts(revenueData, "Revenue", mapDatePartToDay);
  } else if (reportBy === 4) { // Monthly
    updateDataCounts(revenueData, "Revenue", mapDatePartToMonth);
  } else { // Weekly
    updateDataCounts(revenueData, "Revenue", mapDatePartToDate);
  }

  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart data={combinedData} margin={{ top: 20, right: 20, left: 0, bottom: 10 }}>
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="Revenue" stroke="#8884d8" dot={false} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Revenuechart;
