import React from "react";
import { PieChart, Pie, Cell, Label } from "recharts";
import { Box, Typography } from "@mui/material";

const Modifiedpiechart2 = ({ data }) => {
  // Define static data
  const staticData = [
    { packageTitle: "Silver", total: 0 },
    { packageTitle: "Gold", total: 0 },
    { packageTitle: "Gold Plus", total: 0 },
    { packageTitle: "Diamond", total: 0 }
  ];

  // Aggregate data by packageTitle
  const aggregateData = (data) => {
    if (!Array.isArray(data) || data.length === 0) return staticData;

    const staticDataMap = staticData.reduce((acc, item) => {
      acc[item.packageTitle] = item.total;
      return acc;
    }, {});

    // Update the map with dynamic data
    data.forEach(item => {
      const { packageTitle, total } = item;
      if (staticDataMap.hasOwnProperty(packageTitle)) {
        staticDataMap[packageTitle] += parseInt(total, 10);
      }
    });

    // Convert the map back to an array
    return Object.keys(staticDataMap).map(key => ({
      packageTitle: key,
      total: staticDataMap[key]
    }));
  };

  const aggregatedData = aggregateData(data);

  // Calculate total count of all packages
  const totalSum = aggregatedData.reduce((sum, item) => sum + item.total, 0);

  // Formatting data for pie chart
  const formattedData = aggregatedData.map((item) => ({
    label: `${item.packageTitle}: ${item.total}`,
    value: item.total,
  }));

  // Define an array of colors for each segment
  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

  return (
    <Box display="flex" flexDirection="column" alignItems="center">
      <div style={{ textAlign: 'center' }} >
      {totalSum === 0 ? (
        <Typography variant="h6" color="textSecondary" mt={2}>
          No data found
        </Typography>
      ) : (
        <>
          <div style={{ position: 'relative', width: 270, height: 293, marginLeft: '-2.9rem', marginTop: '-1.5rem' }}>
            <PieChart width={350} height={280} >
              <Pie
                data={formattedData}
                innerRadius={45}
                outerRadius={83}
                fill="#8884d8"
                labelLine={false}
                dataKey="value"
              >
                {formattedData.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                ))}
                <Label
                  value={`Total: ${totalSum}`}
                  position="center"
                  fontSize={20}
                  fill="#333"
                />
              </Pie>
            </PieChart>
          </div>
          <Box style={{ marginLeft: "2.8rem", marginBottom: "10px", marginTop: '-2rem' }}>
            {formattedData.map((item, index) => (
              <div key={index} style={{ marginBottom: 10, display: 'flex', alignItems: 'center'}}>
                <div
                  style={{
                    width: 15,
                    height: 15,
                    borderRadius: '50%',
                    backgroundColor: COLORS[index % COLORS.length],
                  }}
                />
                <span style={{ marginLeft: "5px", fontSize: "12px" }}>{item.label}</span>
              </div>
            ))}
          </Box>
        </>
      )}
    </div>
    </Box>
    
  );
};

export default Modifiedpiechart2;
