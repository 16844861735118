import React, { useState, useEffect } from "react";
import { Container, Grid, Paper, Tab, TextField, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { TabContext, TabList } from "@mui/lab";
import { dashboardstyles } from "../Styles/Tablistindashboardstyle";
import Modifiedgraph from "../Components/Modifiedgraph";
import Modifiedgraph2 from "../Components/Modifiedgraph2";
import Modifiedgraph3 from "../Components/Modifiedgraph3";
import Modifiedpiechart from "../Components/Modifiedpiechart";
import Modifiedpiechart2 from "../Components/Modifiedpiechart2";
import Toplocationusers from "../Components/Toplocationusers";
import { enqueueSnackbar } from "notistack";
import { noofmatches_api, getUsersAnalytics_api, getMembershipAnalytics_api, revenuegenerated_api } from "../Apiservices/Adminservices";
import Revenuechart from "../Components/Revenuechart";
import moment from "moment";
import Matchpiechart from "../Components/Matchpiechart";
import Revenuepiechart from "../Components/Revenuepiechart";

const Analytics = () => {
  const liststyle = dashboardstyles();
  const [favourite_tab, setFavourite_tab] = useState(1); // Default to Daily tab
  const [matchesData, setMatchesData] = useState(null);
  const [revenueData, setRevenueData] = useState(null);
  const [userAnalyticsData, setUserAnalyticsData] = useState(null);
  const [membershipAnalyticsData, setMembershipAnalyticsData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [openDateDialog, setOpenDateDialog] = useState(false);
  const [dateError, setDateError] = useState(null);
  const tabsData = [
    { tabName: "Daily", value: 1, tabWidth: "60px" },
    { tabName: "Weekly", value: 2, tabWidth: "80px" },
    { tabName: "Monthly", value: 3, tabWidth: "80px" },
    { tabName: "Cumulative", value: 4, tabWidth: "100px" },
  ];

  const handle_favouritetabs = (event, newValue) => {
    setFavourite_tab(newValue);
    if (newValue === 4) {
      setOpenDateDialog(true);
    } else {
      fetchData(newValue);
    }
  };

  const fetchData = async (reportByValue) => {
    setLoading(true);
    const now = new Date();
    // const formattedNow = moment(new Date()).format("YYYY-MM-DD HH:mm:ss Z");
    const formattedNow = moment(new Date());
    try {
      const params = {
        reportBy: reportByValue,
        fromDate: reportByValue === 4 ? fromDate : formattedNow,
        toDate: reportByValue === 4 ? toDate : formattedNow,
      };

      const matchesResponse = await noofmatches_api(params);
      if (
        matchesResponse &&
        matchesResponse.message ===
          "Successfully retrived  match Analytics data"
      ) {
        setMatchesData(matchesResponse.data);
      } else {
        enqueueSnackbar(
          matchesResponse?.message || "Failed to fetch match analytics",
          { variant: "error" }
        );
        setMatchesData([]);
      }

      const revenueResponse = await revenuegenerated_api(params);
      if (
        revenueResponse?.message ===
        "Successfully retrived  revenue Analytics data"
      ) {
        if (reportByValue === 1) {
          setRevenueData({
            revenueCount:
              revenueResponse.data.revenueData[0]?.revenueCount || 0,
            currentDayCount: revenueResponse.data.currentDayCount,
            previousDayCount: revenueResponse.data.previousDayCount,
          });
        } else {
          setRevenueData(revenueResponse.data);
        }
      } else {
        enqueueSnackbar(
          revenueResponse?.message || "Failed to fetch revenue analytics",
          { variant: "error" }
        );
        setRevenueData(null);
      }

      const userAnalyticsResponse = await getUsersAnalytics_api(params);
      if (
        userAnalyticsResponse &&
        userAnalyticsResponse.message === "Successfully retrived Users data"
      ) {
        setUserAnalyticsData(userAnalyticsResponse.data);
      } else {
        enqueueSnackbar(
          userAnalyticsResponse?.message || "Failed to fetch user analytics",
          { variant: "error" }
        );
        setUserAnalyticsData(null);
      }
      const membershipAnalyticsResponse = await getMembershipAnalytics_api(
        params
      );
      if (
        membershipAnalyticsResponse &&
        membershipAnalyticsResponse.message ===
          "Successfully retrived  membership Analytics data"
      ) {
        setMembershipAnalyticsData(membershipAnalyticsResponse.data);
      } else {
        enqueueSnackbar(
          membershipAnalyticsResponse?.message ||
            "Failed to fetch membership analytics",
          { variant: "error" }
        );
        setMembershipAnalyticsData(null);
      }
    } catch (err) {
      enqueueSnackbar(err.message || "Failed to fetch analytics", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (favourite_tab !== 4) {
      fetchData(favourite_tab);
    }
  }, [favourite_tab]);

  const handleDateDialogClose = () => {
    setOpenDateDialog(false);
  };

  const handleDateDialogSubmit = () => {
    const formattedNow = moment(new Date()).format("YYYY-MM-DD HH:mm:ss Z");
    if (fromDate && toDate) {
      fetchData(4);
      handleDateDialogClose();
    } else {
      enqueueSnackbar("Please select both dates", { variant: "warning" });
    }
  };
  const validateDates = () => {
    const today = new Date().toISOString().split("T")[0];

    const from = new Date(fromDate);
    const to = new Date(toDate);

    if (from > to) {
      return "From Date cannot be later than To Date.";
    }

    if (from > new Date(today)) {
      return "From Date cannot be in the future.";
    }

    if (to > new Date(today)) {
      return "To Date cannot be in the future.";
    }

    return null; // No error
  };

  const handleSubmit = () => {
    const error = validateDates();
    if (error) {
      enqueueSnackbar(error, { variant: "error" });
    } else {
      handleDateDialogSubmit(); // Proceed with submitting dates
      handleDateDialogClose();
    }
  };
  const pieChartData = userAnalyticsData
    ? [
        { label: "Male Users", value: userAnalyticsData.maleUsersCount },
        { label: "Female Users", value: userAnalyticsData.femaleUsersCount },
        { label: "Recent Registrations", value: userAnalyticsData.recentRegistrationsCount },
        { label: "Guest Users", value: userAnalyticsData.guestUsersCount },
      ]
    : [];
  const pieChartData1 = matchesData
    ? [
        { label: "Male Accepted", value: matchesData.maleAcceptedCount || 0 },
        { label: "Female Accepted", value: matchesData.femaleAcceptedCount || 0 }
      ]
    : [];

  const pieChartData2 = revenueData
    ? [
        { label: "Revenue Count", value: revenueData.revenueCount || 0 },
      ]
    : [];

  const roundedUserPercentIncreasedBy = userAnalyticsData ? Math.round(userAnalyticsData.userPercentIncreasedBy) : null;
  const roundedMembershipPercentIncreasedBy = membershipAnalyticsData ? Math.round(membershipAnalyticsData.membershipPercentIncreasedBy) : null;
  const userPercentageText =
    roundedUserPercentIncreasedBy !== null
      ? `${Math.abs(roundedUserPercentIncreasedBy)}% ${
          roundedUserPercentIncreasedBy >= 0 ? "Increased" : "Decreased"
        }`
      : "Loading...";
  const MembershipPercentageText =
    roundedMembershipPercentIncreasedBy !== null
      ? `${Math.abs(roundedMembershipPercentIncreasedBy)}% ${
          roundedMembershipPercentIncreasedBy >= 0 ? "Increased" : "Decreased"
        }`
      : "Loading...";

  if (!membershipAnalyticsData || !membershipAnalyticsData.memberShipCounts) {
    return <div>Loading...</div>;
  }

  return (
    <Container maxWidth={"lg"}>
      <Grid container>
        <TabContext value={favourite_tab}>
          <Grid item container justifyContent={"space-between"}>
            <Grid item md={"auto"}>
              <TabList className={liststyle.favorite} onChange={handle_favouritetabs} scrollButtons="on" variant="scrollable" orientation={{ xs: "horizontal", md: "none" }}>
                {tabsData.map((data) => (
                  <Tab key={data.value} value={data.value} disableRipple sx={{ width: data.tabWidth }} className={liststyle.tabbtns} label={
                      <Grid container alignItems={"center"} padding={"0rem 0.5rem"}>
                        <Grid item md sm xs textAlign={"center"}>
                          {data.tabName}
                        </Grid>
                      </Grid>
                    }
                  />
                ))}
              </TabList>
            </Grid>
            {favourite_tab === 4 && (
              <Grid item md={"auto"}>
                <TextField id="from-date" label="From Date" type="date" value={fromDate} onChange={(e) => setFromDate(e.target.value)} InputLabelProps={{ shrink: true }} variant="standard" error={!!dateError} helperText={dateError} />
                <TextField id="to-date" label="To Date" type="date" value={toDate} onChange={(e) => setToDate(e.target.value)} InputLabelProps={{ shrink: true }} variant="standard" error={!!dateError} helperText={dateError} />
                <DialogActions>
                  <Button onClick={handleDateDialogClose}>Cancel</Button>
                  <Button onClick={handleSubmit}>Submit</Button>
                </DialogActions>
              </Grid>
            )}
          </Grid>
        </TabContext>
      </Grid>
      <Grid container spacing={2}>
        <Grid item container spacing={1}>
          {/* Users */}
          {favourite_tab === 1 && (
            <Grid item md={6}>
              <Paper elevation={5} className={liststyle.dashmembershipstyles}>
                <Grid item className={liststyle.dashusersstyles}>
                  {/* Users ({userPercentageText}) */}
                  Users
                </Grid>
                <Grid container md={12} spacing={0}>
                  <Grid item sm={12}>
                    <Modifiedpiechart outerData={pieChartData} />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}

          {/* Membership */}
          {favourite_tab === 1 && (
            <Grid item md={6}>
              <Paper elevation={5} className={liststyle.dashmembershipstyles}>
                <Grid item className={liststyle.dashusersstyles}>
                  {/* Membership ({MembershipPercentageText}) */}
                  Membership
                </Grid>
                <Grid container md={12} spacing={0}>
                  <Grid item sm={12}>
                    <Modifiedpiechart2 data={membershipAnalyticsData.memberShipCounts} />
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          )}

          {/* Users and Membership for Weekly, Monthly, and Cumulative Tabs */}
          {(favourite_tab === 2 ||
            favourite_tab === 3 ||
            favourite_tab === 4) && (
            <>
              {/* Users Pie Chart and Graph */}
              <Grid item md={6}>
                <Paper elevation={5} className={liststyle.dashmembershipstyles}>
                  <Grid item className={liststyle.dashusersstyles}>
                    {/* Users ({userPercentageText}) */}
                    Users
                  </Grid>
                  <Grid container md={12} spacing={0}>
                    <Grid item sm={4}>
                      <Modifiedpiechart outerData={pieChartData} />
                    </Grid>
                    <Grid item sm={8}>
                      <Modifiedgraph2 data={userAnalyticsData} reportBy={favourite_tab} />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>

              {/* Membership Pie Chart and Graph */}
              <Grid item md={6}>
                <Paper elevation={5} className={liststyle.dashmembershipstyles}>
                  <Grid item className={liststyle.dashusersstyles}>
                    {/* Membership({MembershipPercentageText}) */}
                    Membership
                  </Grid>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      md={4}
                      sx={{
                        "& .MuiResponsiveChart-container": {
                          width: "100% !important",
                          paddingTop: "4rem",
                        },
                      }}
                    >
                      <Modifiedpiechart2
                        data={membershipAnalyticsData.memberShipCounts}
                      />
                    </Grid>
                    <Grid item md={8}>
                      <Modifiedgraph3 data={membershipAnalyticsData} reportBy={favourite_tab} />
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </>
          )}
        </Grid>
      </Grid>
      <Grid item mt={0.8} container spacing={2} mb={3}>
        {/* Graph 1 */}

        <Grid item md={4}>
          <Paper elevation={5} className={liststyle.dashmembershipstyles}>
            <Grid item className={liststyle.dashusersstyles}>
              Revenue Generated
            </Grid>
            <Grid item>
              {favourite_tab === 1 ? (
                <Revenuepiechart outerData={pieChartData2} revenueCount={revenueData?.revenueCount || 0} />
                ) : (
                <Revenuechart data={revenueData} reportBy={favourite_tab} />
              )}
            </Grid>
          </Paper>
        </Grid>

        <Grid item md={4}>
          <Paper elevation={5} className={liststyle.dashmembershipstyles}>
            <Grid item className={liststyle.dashusersstyles}>
              Number Of Matches
            </Grid>
            <Grid item>
              {favourite_tab === 1 ? (
                <Matchpiechart outerData={pieChartData1} maleAcceptedCount={matchesData?.maleAcceptedCount || 0} femaleAcceptedCount={matchesData?.femaleAcceptedCount || 0} />
              ) : (
                <Modifiedgraph data={matchesData} reportBy={favourite_tab} />
              )}
            </Grid>
          </Paper>
        </Grid>

        {/* Location section */}
        <Grid item md={4}>
          <Paper elevation={5} className={liststyle.dashmembershipstyles}>
            <Grid item className={liststyle.dashusersstyles}>
              Top Locations of App Usage
            </Grid>
            <Grid item>
              <Toplocationusers />
            </Grid>
          </Paper>
        </Grid>
      </Grid>

      {/* Date Range Dialog */}
      <Dialog open={openDateDialog} onClose={handleDateDialogClose}>
        <DialogTitle>Select Date Range</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            id="from-date"
            label="From Date"
            type="date"
            fullWidth
            variant="standard"
            value={fromDate}
            onChange={(e) => setFromDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
          <TextField
            margin="dense"
            id="to-date"
            label="To Date"
            type="date"
            fullWidth
            variant="standard"
            value={toDate}
            onChange={(e) => setToDate(e.target.value)}
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDateDialogClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default Analytics;
