import React from 'react'
import { Grid, InputAdornment } from "@mui/material";
import ClearSharpIcon from "@mui/icons-material/ClearSharp";
import { color_exports } from '../Styles/Color_exports';
import Modifiedinputfield from '../Components/Modifiedinputfield'
import { browserfiles_styles } from '../Styles/Customcomponentstyles';

const Modifiedbrowserfiles = (props) => {
    const { name, onChange, accept, isDelete, onDelete, ...otherprops } = props;
    const filesstyles = browserfiles_styles();
  return (
    <>
      <Grid onDragOver={(e) => e.preventDefault()} onDrop={onChange}>
        <Modifiedinputfield
          fullWidth
          value={
            !["Drag & drop photos/videos", "Drag & drop files"].includes(
              props.placeholder
            )
              ? props.placeholder
              : ""
          }
          {...otherprops}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end" sx={{ gap: "0.4rem" }}>
                {isDelete &&
                  !["Drag & drop photos/videos", "Drag & drop files"].includes(
                    props.placeholder
                  ) && (
                    <ClearSharpIcon
                      sx={{ cursor: "pointer" }}
                      onClick={onDelete}
                    />
                  )}
                <Grid item 
                className={filesstyles.browsebtn}
                >
                  <label for={name === undefined ? "fileInput1" : name}>
                    Browse files
                  </label>
                  <input
                    multiple
                    type="file"
                    id={name === undefined ? "fileInput1" : name}
                    style={{ display: "none" }}
                    name
                    onChange={onChange}
                    accept={accept}
                    {...otherprops}
                  />
                </Grid>
              </InputAdornment>
            ),
            readOnly: true,
          }}
          className={filesstyles.browsebtn1}
          
        />
      </Grid>
    </>
  )
}

export default Modifiedbrowserfiles