import React, { useState, useEffect, useRef } from "react";
import {
  Grid,
  Paper,
  Stack,
  Checkbox,
  FormControlLabel,
  Select,
  MenuItem,
  TextField,
  FormControl,
  InputAdornment,
  Autocomplete,
} from "@mui/material";
import Modifiedbutton from "./Modifiedbutton";
import Modifiedinputfield from "./Modifiedinputfield";
import { subadminstyles } from "../Styles/Contentmanagement_styles";
import plusicon from "../assets/Images/plusicon.png";
import {
  addsubadmin_api,
  getcountries_api,
  getstates_api,
  getcities_api,
} from "../Apiservices/Adminservices";
import { enqueueSnackbar } from "notistack";
import Vieweye from "./Vieweye";
import Hideeye from "./Hideeye";
import { userdetail_styles } from "../Styles/Adminuser_details_styles";
import { color_exports } from "../Styles/Color_exports";

const Addsubadmin = ({ onAddSubadmin }) => {
  const subadminstyle = subadminstyles();
  const details_style = userdetail_styles();
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    phoneNumber: "",
    email: "",
    password: "",
    cityId: "",
    stateId: "",
    countryId: "",
    privileges: {
      report: 0,
      messages: 0,
      successStories: 0,
      userManagement: 0,
      packageManagement: 0,
    },
    subAdminProfileImage: null,
  });

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [errors, setErrors] = useState({});
  const fileInputRef = useRef(null);

  const handleChange = async (e) => {
    const { name, value } = e.target;

    // Update formData based on field changes
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    // Validation logic for phone number
    if (name === "phoneNumber") {
      // Regular expression for phone number validation (allow only numbers)
      const phoneNumberRegex = /^[0-9]*$/;

      if (!phoneNumberRegex.test(value)) {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: "Please enter numbers only",
        }));
      } else {
        setErrors((prevErrors) => ({
          ...prevErrors,
          phoneNumber: "",
        }));
      }
    }

    // Fetch states if countryId changes
    if (name === "countryId") {
      try {
        const response = await getstates_api({
          countryId: value,
          min: 0,
          max: 1000,
          searchText: "", // Optional: can be used for filtering
        });
        if (response && response.message === "states list") {
          setStates(response.data.rows);
          setCities([]); // Clear cities when country changes
          setFormData((prevData) => ({
            ...prevData,
            cityId: "", // Reset cityId when country changes
          }));
        } else {
          console.error(response?.message || "Failed to fetch states");
          enqueueSnackbar(response?.message || "Failed to fetch states", {
            variant: "error",
          });
        }
      } catch (err) {
        console.error("Error:", err);
        enqueueSnackbar(err.message || "Failed to fetch states", {
          variant: "error",
        });
      }
    }

    // Fetch cities if stateId changes
    if (name === "stateId") {
      try {
        const response = await getcities_api({
          stateId: value,
          min: 0,
          max: 1000,
          searchText: "", // Optional: can be used for filtering
        });
        if (response && response.message === "cities list") {
          setCities(response.data.rows);
          setFormData((prevData) => ({
            ...prevData,
            cityId: "", // Reset cityId when state changes
          }));
        } else {
          console.error(response?.message || "Failed to fetch cities");
          enqueueSnackbar(response?.message || "Failed to fetch cities", {
            variant: "error",
          });
        }
      } catch (err) {
        console.error("Error:", err);
        enqueueSnackbar(err.message || "Failed to fetch cities", {
          variant: "error",
        });
      }
    }
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      privileges: {
        ...prevState.privileges,
        [name]: checked ? 1 : 0,
      },
    }));
  };

  const handleProfilePhotoClick = () => {
    fileInputRef.current.click();
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Define regex patterns
    const phonePattern = /^[123456789]\d{9}$/;

    const passwordPattern = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d@!#$%^&*]{8,}$/; // Updated pattern for password

    // Validate form fields
    if (!formData.subAdminProfileImage) {
      enqueueSnackbar("Please provide a profile photo", { variant: "error" });
      return;
    }
    if (!formData.name) {
      enqueueSnackbar("Please provide a name", { variant: "error" });
      return;
    }
    if (!formData.phoneNumber) {
      enqueueSnackbar("Please provide a phone number", { variant: "error" });
      return;
    }
    if (!phonePattern.test(formData.phoneNumber)) {
      enqueueSnackbar("Please provide a valid phone number (10 digits)", {
        variant: "error",
      });
      return;
    }
    if (!formData.email) {
      enqueueSnackbar("Please provide an email", { variant: "error" });
      return;
    }
    if (!formData.password) {
      enqueueSnackbar("Please provide a password", { variant: "error" });
      return;
    }
    if (!passwordPattern.test(formData.password)) {
      enqueueSnackbar(
        "Please provide a valid password (minimum 8 characters, at least one letter and one number, and may include special characters)",
        { variant: "error" }
      );
      return;
    }
    if (!formData.countryId) {
      enqueueSnackbar("Please select a country", { variant: "error" });
      return;
    }
    if (!formData.stateId) {
      enqueueSnackbar("Please select a state", { variant: "error" });
      return;
    }
    if (!formData.cityId) {
      enqueueSnackbar("Please select a city", { variant: "error" });
      return;
    }
    if (Object.values(formData.privileges).every((value) => value === 0)) {
      enqueueSnackbar("Please select at least one privilege", {
        variant: "error",
      });
      return;
    }

    // Prepare data for submission
    try {
      const formDataToSend = new FormData();
      formDataToSend.append("name", formData.name);
      formDataToSend.append("phoneNumber", formData.phoneNumber);
      formDataToSend.append("email", formData.email);
      formDataToSend.append("password", formData.password);
      formDataToSend.append("cityId", formData.cityId);
      formDataToSend.append("stateId", formData.stateId);
      formDataToSend.append("countryId", formData.countryId);
      formDataToSend.append("privileges", JSON.stringify(formData.privileges));
      formDataToSend.append(
        "subAdminProfileImage",
        formData.subAdminProfileImage
      );

      const response = await addsubadmin_api(formDataToSend);

      if (response && response.message === "Sub Admin created successfully") {
        onAddSubadmin();
        enqueueSnackbar(response.message, { variant: "success" });
        setFormData({
          name: "",
          phoneNumber: "",
          email: "",
          password: "",
          cityId: "",
          stateId: "",
          countryId: "",
          privileges: {
            report: 0,
            messages: 0,
            successStories: 0,
            userManagement: 0,
            packageManagement: 0,
          },
          subAdminProfileImage: null,
        });
      } else {
        console.log("Failed:", response?.message);
        enqueueSnackbar(response?.message, { variant: "error" });
      }
    } catch (err) {
      console.error("Error:", err);
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  const handleProfilePhotoChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const extension = file.name.split(".").pop().toLowerCase();
      const allowedExtensions = ["jpg", "jpeg", "png"];
      if (allowedExtensions.includes(extension)) {
        setFormData({
          ...formData,
          subAdminProfileImage: file,
        });
      } else {
        enqueueSnackbar("Please select an image file (JPG, JPEG, PNG, GIF)", {
          variant: "error",
        });
        e.target.value = null;
      }
    }
  };

  useEffect(() => {
    const fetchCountries = async () => {
      try {
        const response = await getcountries_api();
        if (response && response.message === "countries list") {
          setCountries(response.data.rows);
          // enqueueSnackbar(response.message, { variant: "success" });
        } else {
          console.error(response?.message || "Failed to fetch countries");
          enqueueSnackbar(response?.message || "Failed to fetch countries", {
            variant: "error",
          });
        }
      } catch (err) {
        console.error("Error:", err);
        enqueueSnackbar(err.message || "Failed to fetch countries", {
          variant: "error",
        });
      }
    };
    fetchCountries();
  }, []);

  return (
    <div>
      <Paper elevation={5} sx={{ padding: "1rem", borderRadius: "1rem" }}>
        <form onSubmit={handleSubmit} autoComplete="off">
          <Grid container direction="column" spacing={2}>
            <Grid item className={details_style.mainheading}>
              <div>Add New Admin</div>
            </Grid>
            <Grid item container>
              <Grid
                item
                container
                md={4}
                mt={2}
                className={subadminstyle.cardviewimagecontainer}
                onClick={handleProfilePhotoClick}
                style={{ cursor: "pointer", textAlign: "center" }}
              >
                <Grid item mt={4}>
                  {formData.subAdminProfileImage ? (
                    <img
                      src={URL.createObjectURL(formData.subAdminProfileImage)}
                      alt="Profile"
                      className={subadminstyle.uploadimg}
                    />
                  ) : (
                    <>
                      <img src={plusicon} alt="plusicon" />
                      <div>Add Profile Photo</div>
                    </>
                  )}
                </Grid>
              </Grid>
              <input
                type="file"
                ref={fileInputRef}
                style={{ display: "none" }}
                onChange={handleProfilePhotoChange}
                accept="image/*"
              />

              <Grid item md={8}>
                <Stack spacing={2}>
                  <Grid item container spacing={2}>
                    <Grid item md={11.5}>
                      <Stack spacing={1}>
                        <Grid item sx={{ color: color_exports.primary_color }}>
                          Name
                        </Grid>
                        <Grid item>
                          <Modifiedinputfield
                            fullWidth
                            placeholder="Enter Here"
                            name="name"
                            value={formData.name}
                            onChange={handleChange}
                          />
                        </Grid>
                      </Stack>
                    </Grid>
                    <Grid item md={11}>
                      <Stack spacing={1}>
                        <Grid item sx={{ color: color_exports.primary_color }}>
                          Phone Number
                        </Grid>
                        <Grid item>
                          <Modifiedinputfield
                            fullWidth
                            placeholder="Enter Here"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            error={!!errors.phoneNumber}
                            helperText={errors.phoneNumber}
                          />
                        </Grid>
                      </Stack>
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
            <Grid item container>
              <Grid item md={12}>
                <Stack spacing={1}>
                  <Grid item sx={{ color: color_exports.primary_color }}>
                    Email
                  </Grid>
                  <Grid item>
                    <Modifiedinputfield
                      placeholder="Enter here"
                      fullWidth
                      name="email"
                      inputProps={{ autoComplete: "off" }}
                      value={formData.email}
                      onChange={handleChange}
                      autoComplete="off"
                    />
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item md={6}>
                <Stack spacing={1}>
                  <Grid item sx={{ color: color_exports.primary_color }}>
                    Password
                  </Grid>
                  <Grid item>
                    <Grid item>
                      <Modifiedinputfield
                        size={"medium"}
                        placeholder="Enter here"
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        autoComplete="off"
                        type={passwordVisible ? "text" : "password"}
                        InputProps={{
                          endAdornment: (
                            <InputAdornment
                              position="end"
                              onClick={() =>
                                setPasswordVisible(!passwordVisible)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {passwordVisible ? <Vieweye /> : <Hideeye />}
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>
                </Stack>
              </Grid>
              <Grid item md={6} sx={{ marginBottom: "0.5rem" }}>
                <Stack spacing={1}>
                  <Grid item sx={{ color: color_exports.primary_color }}>
                    Country
                  </Grid>
                  <Grid item>
                    <FormControl fullWidth>
                      <Select
                        name="countryId"
                        value={formData.countryId}
                        onChange={handleChange}
                        displayEmpty
                        className={subadminstyle.selectbg}
                        sx={{
                          "&:before": {
                            borderBottom: "none", // Remove border bottom
                          },
                          "&:after": {
                            borderBottom: "none", // Remove border bottom
                          },
                          "&:hover:not(.Mui-disabled):before": {
                            borderBottom: "none", // Remove border bottom on hover
                          },
                          "& .MuiInputBase-root": {
                            fontSize: "1rem", // Adjust font size if needed
                          },
                        }}
                      >
                        <MenuItem value="" disabled>
                          <span className={subadminstyle.selectplaceholder}>
                            Country
                          </span>
                        </MenuItem>
                        {countries.map((country) => (
                          <MenuItem
                            key={country.countryId}
                            value={country.countryId}
                          >
                            {country.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
            <Grid item container spacing={2}>
              <Grid item md={6} sx={{ marginBottom: "0.5rem" }}>
                <Stack spacing={1}>
                  <Grid item sx={{ color: color_exports.primary_color }}>
                    State
                  </Grid>
                  <Grid item>
                    <Autocomplete
                      options={states}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, value) =>
                        handleChange({
                          target: {
                            name: "stateId",
                            value: value ? value.stateId : "",
                          },
                        })
                      }
                      renderInput={(params) => (
                        <TextField {...params} placeholder="State" fullWidth />
                      )}
                    />
                  </Grid>
                </Stack>
              </Grid>

              <Grid item md={6} sx={{ marginBottom: "0.5rem" }}>
                <Stack spacing={1}>
                  <Grid item sx={{ color: color_exports.primary_color }}>
                    City
                  </Grid>
                  <Grid item>
                    <Autocomplete
                      options={cities}
                      getOptionLabel={(option) => option.name}
                      onChange={(e, value) =>
                        handleChange({
                          target: {
                            name: "cityId",
                            value: value ? value.cityId : "",
                          },
                        })
                      }
                      value={
                        cities.find(
                          (city) => city.cityId === formData.cityId
                        ) || null
                      } // Ensure the selected city is correctly shown
                      renderInput={(params) => (
                        <TextField {...params} placeholder="City" fullWidth />
                      )}
                    />
                  </Grid>
                </Stack>
              </Grid>
            </Grid>
            <Grid item container mt={2}>
              <Stack spacing={1}>
                <Grid item sx={{ color: color_exports.primary_color }}>
                  Privileges
                </Grid>
                <Grid container spacing={2} fontSize="12px">
                  {[
                    { label: "User Management", name: "userManagement" },
                    { label: "Packages Management", name: "packageManagement" },
                    { label: "Report", name: "report" },
                    { label: "Success Stories", name: "successStories" },
                    { label: "Messages", name: "messages" },
                  ].map((privilege, index) => (
                    <Grid item key={index}>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: "8px",
                        }}
                      >
                        <Checkbox
                          name={privilege.name}
                          checked={formData.privileges[privilege.name] === 1}
                          onChange={handleCheckboxChange}
                          style={{
                            color: "#b8003e",
                            width: "17px",
                            height: "17px",
                          }}
                        />

                        <span
                          onClick={(e) => e.stopPropagation()} // Prevent clicks on the label from affecting the checkbox
                          style={{
                            marginLeft: "8px",
                            cursor: "default", // Default cursor for the label
                            fontSize: "16px",
                          }}
                        >
                          {privilege.label}
                        </span>
                      </div>
                    </Grid>
                  ))}
                </Grid>
              </Stack>
            </Grid>

            <Grid item container justifyContent="flex-end">
              <Grid item>
                <Modifiedbutton
                  data={"Cancel"}
                  bgcolor={"#F2F2F2"}
                  styles={{ color: "#9F9F9F" }}
                  onClick={() =>
                    setFormData({
                      name: "",
                      phoneNumber: "",
                      email: "",
                      password: "",
                      cityId: "",
                      stateId: "",
                      countryId: "",
                      privileges: {
                        report: 0,
                        messages: 0,
                        successStories: 0,
                        userManagement: 0,
                        packageManagement: 0,
                      },
                      subAdminProfileImage: null,
                    })
                  }
                />
              </Grid>
              <Grid item>
                <Modifiedbutton
                  data={"Add"}
                  swidth={"5rem"}
                  className={subadminstyle.addbtn}
                  type="submit"
                />
              </Grid>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </div>
  );
};

export default Addsubadmin;
