import React from "react";
import { Grid, Paper } from "@mui/material";
import { color_exports } from "../Styles/Color_exports";
import { IOSSwitch } from "../Styles/Customcomponentstyles";
import { successstoryenable_api } from "../Apiservices/Adminservices";
import { enqueueSnackbar } from "notistack";
import { reporteditorstyles } from "../Styles/Contentmanagement_styles";
import Changepwd from "../Components/Changepwd";
import { useSelector, useDispatch } from "react-redux";
import { LoginActions } from "../Store/Loginslice";

const Settings = ({ email }) => {
  const reporteditorstyle = reporteditorstyles();
  const dispatch = useDispatch();

  // Access preferences and provide default values if undefined
  const preferences = useSelector(
    (state) => state.authentication.data?.preferences || {}
  );
  const adminId = useSelector((state) => state.authentication.data?.adminId);
  const successStoriesStatus = preferences.successStories || 0; // Use the correct key

  const updateNotificationPreferences = async (status) => {
    try {
      const req = {
        adminId: adminId,
        successStoriesStatus: status,
      };
      const response = await successstoryenable_api(req);
      console.log("API Response:", response);
      if (
        response?.message === "Notification Preferences Updated Successfully"
      ) {
        dispatch(LoginActions.updatePreferences({ successStories: status })); // Use the correct key
        enqueueSnackbar("Notification Preferences Updated Successfully", {
          variant: "success",
        });
      } else {
        console.error("Failed:", response?.message);
        enqueueSnackbar(response?.message || "Failed to update preferences", {
          variant: "error",
        });
      }
    } catch (err) {
      console.error("Error:", err);
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  return (
    <Grid container justifyContent={"center"}>
      <Grid container spacing={2} justifyContent={"center"}>
        <Grid item md={5}>
          <Changepwd email={email} />
        </Grid>
        <Grid item md={5}>
          <Paper elevation={5} className={reporteditorstyle.searchpaper}>
            <Grid container direction={"column"} spacing={2}>
              <Grid
                item
                fontSize={"16px"}
                sx={{ color: color_exports.primary_color }}
              >
                Notifications
              </Grid>

              <Grid item container justifyContent={"space-between"}>
                <Grid item md={"auto"}>
                  Success stories
                </Grid>
                <Grid item md={"auto"}>
                  <IOSSwitch
                    checked={successStoriesStatus === 1}
                    onChange={(e) =>
                      updateNotificationPreferences(e.target.checked ? 1 : 0)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Settings;
