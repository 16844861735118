import React from 'react';
import { Grid, Paper } from '@mui/material'; 
import Cardbgimage from '../assets/Icons/Cardbgimage';
import { storystyles } from '../Styles/Component_styles';
import user1 from '../assets/Images/user1.png';

const Storycomp = ({ stories = [], onStoryClick }) => {
  const storystyle = storystyles();
  const imageBaseUrl = process.env.REACT_APP_IMAGES;

  return (
    <Grid container spacing={2}>
      {stories.length === 0 ? (
        <Grid item xs={12}>
          <Paper elevation={5} className={storystyle.paper}>
            <Grid container direction="column" spacing={1} alignItems="center">
              <Grid item>
                <p>No stories available</p>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      ) : (
        stories.map((story) => (
          <Grid item md={6} key={story.successStoryId}>
            <Paper elevation={5} className={storystyle.paper} onClick={() => onStoryClick(story.successStoryId)} >
              <Grid container direction="column" spacing={1}>
                <Grid item container justifyContent="space-between">
                  <Grid item md container>
                    <Grid item md="auto">
                      <img
                        src={
                          story.profileImages[0]?.image1
                            ? `${imageBaseUrl}/${story.profileImages[0].image1}`
                            : user1
                        }
                        alt="profile"
                        style={{ width: 50, height: 50, borderRadius: "50%", objectFit: "cover" }}
                      />
                    </Grid>
                    <Grid item md container ml={1}>
                      <Grid item container>
                        <Grid
                          item
                          md="auto"
                          className={storystyle.storyname}
                        >
                          {story.firstName} {story.lastName},
                        </Grid>
                        <Grid item md="auto" fontSize={"12px"}>
                          &nbsp;{new Date().getFullYear() - new Date(story.dateOfBirth).getFullYear()}
                        </Grid>
                      </Grid>
                      <Grid item container>
                        <Grid
                          item
                          md="auto"
                        >
                          {story.height}ft,
                        </Grid>
                        <Grid
                          item
                          md="auto"
                        >
                          &nbsp;{story.permanentCity}
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item md="auto">
                    <Cardbgimage />
                  </Grid>
                </Grid>
                <Grid item className={storystyle.successstory}>
                  {story.successStory}
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        ))
      )}
    </Grid>
  );
};

export default Storycomp;
