import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, Grid, Stack, InputAdornment } from "@mui/material";
import { useNavigate } from "react-router-dom"; 
import Modifiedinputfield from "../Components/Modifiedinputfield";
import Modifiedbutton from "../Components/Modifiedbutton";
import { forget_styles } from "../Styles/Model_styles";
import { color_exports } from "../Styles/Color_exports";
import { admin_resetpwd } from "../Apiservices/Adminservices";
import { enqueueSnackbar } from "notistack";
import Vieweye from "../Components/Vieweye";
import Hideeye from "../Components/Hideeye";

const Reset = ({ open, close, email }) => {
  const forget = forget_styles();
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [resetSubmitted, setResetSubmitted] = useState(false);
  const [newPasswordVisible, setNewPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const navigate = useNavigate();

  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
  };

  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
  };

  // Reset component
  const handleresetpwd = async () => {
    try {
      // Check for empty fields and password match
      if (!newPassword.trim()) {
        enqueueSnackbar("The field New password is not allowed to be empty", { variant: "error" });
        return;
      }
  
      if (!confirmPassword.trim()) {
        enqueueSnackbar("The field New Confirm password is not allowed to be empty", { variant: "error" });
        return;
      }
  
      if (newPassword !== confirmPassword) {
        enqueueSnackbar("Passwords do not match", { variant: "error" });
        return;
      }
  
      const req = { email: email, password: newPassword };
      const resetpwdres = await admin_resetpwd(req);
  
      if (resetpwdres?.message === "ResetPassword Successfully") {
        enqueueSnackbar(resetpwdres?.message, { variant: "success" });
        setResetSubmitted(true);
      } else if (resetpwdres?.message === "Please Provide new password") {
        enqueueSnackbar("The new password you entered is already in use", { variant: "error" });
      } else {
        enqueueSnackbar(resetpwdres?.message, { variant: "error" });
      }
    } catch (err) {
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };
  

  useEffect(() => {
    if (resetSubmitted) {
      // Redirect to the admin page after reset
      navigate("/admin");
      close(); // Close the dialog after redirection
    }
  }, [resetSubmitted, navigate, close]);

  return (
    <Dialog
      open={open}
      onClose={close}
      PaperProps={{
        sx: {
          m: 0,
          width: 600,
          maxWidth: 600,
          borderRadius: "1.5rem",
          "::-webkit-scrollbar": { display: "none" },
          "@media screen and (min-width: 200px) and (max-width: 599px)": {
            width: "90%",
            maxWidth: "90%",
          },
        },
      }}
    >
      <DialogTitle>
        <Grid
          container
          alignItems="center"
          justifyContent="center"
          position="relative"
        >
          <Grid item xs={12} textAlign="center">
            <h4 className={forget.underline}>Reset Password</h4>
          </Grid>
        </Grid>
      </DialogTitle>
      <Grid
        container
        direction={"column"}
        rowSpacing={3}
        alignItems={"center"}
        justifyContent={"center"}
      >
        <Grid item>
          <Stack spacing={1}>
            <Grid
              item
              fontWeight={"400"}
              sx={{ color: color_exports.primary_color }}
            >
              New Password
            </Grid>
            <Grid item>
              <Modifiedinputfield
                size={"medium"}
                placeholder="Enter here"
                fullWidth
                name="newPassword"
                value={newPassword}
                onChange={handleNewPasswordChange}
                style={{ width: "500px" }}
                type={newPasswordVisible ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() => setNewPasswordVisible(!newPasswordVisible)}
                      style={{ cursor: "pointer" }}
                    >
                      {newPasswordVisible ? <Vieweye /> : <Hideeye />}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Stack>
        </Grid>

        <Grid item>
          <Stack spacing={1}>
            <Grid
              item
              fontWeight={"400"}
              sx={{ color: color_exports.primary_color }}
            >
              Confirm New Password
            </Grid>
            <Grid item>
              <Modifiedinputfield
                size={"medium"}
                placeholder="Enter here"
                fullWidth
                name="confirmPassword"
                value={confirmPassword}
                onChange={handleConfirmPasswordChange}
                style={{ width: "500px" }}
                type={confirmPasswordVisible ? "text" : "password"}
                InputProps={{
                  endAdornment: (
                    <InputAdornment
                      position="end"
                      onClick={() =>
                        setConfirmPasswordVisible(!confirmPasswordVisible)
                      }
                      style={{ cursor: "pointer" }}
                    >
                      {confirmPasswordVisible ? <Vieweye /> : <Hideeye />}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Stack>
        </Grid>

        <Grid item container alignItems={"center"} mt={2}>
          <Grid item container md={12} justifyContent={"center"} mb={2}>
            <Modifiedbutton
              fontWeight={600}
              onClick={handleresetpwd}
              swidth={"7rem"}
              sheight={"2.5rem"}
              style={{ fontSize: "20px" }}
              data="Submit"
            />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Reset;
