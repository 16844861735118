import { makeStyles } from "@mui/styles";
import { color_exports } from "./Color_exports";
// import { color_exports } from "./Color_exports";

export const adminsidebar_style = makeStyles({
  sidebarlogo: {
    background: "#b8003e !important",
    padding: '1rem !important',
  },
  inactive: {
    textDecoration: "none",
    color: color_exports.icon_color,
    opacity: "0.6",
    fontSize: "16px",
  },
  active: {
    textDecoration: "none",
    color: "#ffff",
    fontWeight: "700!important",
    fontSize: "16px",
  },
  iconactive: {
    color: "#fff",
  },
  iconinactive: {
    color: color_exports.icon_color,
  },
  admindrawer: {
    "& .MuiPaper-root": {
      backgroundColor: color_exports.tex_shade_color,
      overflow: "hidden",
    },
    "&.MuiDrawer-docked": {
      width: "250px",
    },
    "& .MuiDrawer-paper::-webkit-scrollbar": {
      width: 0, // Hide the scrollbar
    },
  },
  logoutgrid: {
    textDecoration: "none",
    color: color_exports.icon_color,
    opacity: "0.6",
    fontSize: "16px",
    marginTop: "4.5rem",
    padding: "8px 22px",
  },
  adminlist: {
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      width: "0 !important", // Hide the scrollbar
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "none", // Optional: remove the track
    },
  },
  mainoutlet: {
    flexGrow: 1,
    marginTop: "4.1rem",
    backgroundColor: "#F2F2F2",
    overflow: "hidden",
    minHeight: "calc( 100vh - 4.1rem )",
  },
  itemname: {
    color: color_exports.btntext_color,
    fontSize: "16px",
    fontWeight: 700,
  },
});
export const sidebariconstyles = {
  iconwidth: {
    width: "20px",
    padding: "1.5px",
  },
};
