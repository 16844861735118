import React from 'react'

const Cardbgimage = () => {
  return (
    <>
    <svg width="42" height="34" viewBox="0 0 42 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<g opacity="0.07">
<path d="M7.35403 6.67048C9.26978 6.67048 10.8228 5.3216 10.8228 3.65769C10.8228 1.99377 9.26978 0.644897 7.35403 0.644897C5.43828 0.644897 3.88525 1.99377 3.88525 3.65769C3.88525 5.3216 5.43828 6.67048 7.35403 6.67048Z" fill="#B8003E"/>
<path d="M34.8291 6.03217C36.7449 6.03217 38.2979 4.6833 38.2979 3.01938C38.2979 1.35546 36.7449 0.0065918 34.8291 0.0065918C32.9134 0.0065918 31.3604 1.35546 31.3604 3.01938C31.3604 4.6833 32.9134 6.03217 34.8291 6.03217Z" fill="#B8003E"/>
<path d="M38.8119 1.5882C39.5767 1.56868 40.2879 1.90961 40.6771 2.35116C41.0662 2.79272 41.1781 3.32138 41.1915 3.83352C41.2049 4.34717 41.1244 4.86082 41.1669 5.37296C41.2094 5.8851 41.3861 6.41077 41.849 6.81928C41.1199 6.82679 40.4915 6.44831 40.1493 6.01427C39.8071 5.58172 39.6774 5.0891 39.447 4.62502C39.2144 4.16094 38.8387 3.69235 38.1901 3.46706C37.8837 3.36043 37.5259 3.31237 37.2732 3.15617C36.477 2.67407 38.0246 1.60773 38.8119 1.5882Z" fill="#B8003E"/>
<path d="M33.7485 6.67053C26.5739 6.67053 20.9514 17.9512 20.9514 17.9512C20.9514 17.9512 13.9155 7.82098 7.89486 7.43199C-0.491924 6.89431 -0.0133199 12.8884 1.54327 16.1144C4.35452 21.9462 17.7421 27.3621 16.5254 31.3285C16.1519 32.5421 13.5733 32.1125 11.9228 31.6124C11.178 31.3856 10.5294 32.0194 11.1131 32.4039C12.6608 33.4237 14.7832 34.2963 17.1159 33.7466C17.1449 33.7391 17.174 33.7331 17.2031 33.7256C19.3434 33.2104 19.8175 30.8945 18.2207 28.882C14.9375 24.7443 10.4959 18.9154 9.65944 17.6854C8.46516 15.9282 7.79869 12.2786 12.5736 12.5865C15.2238 12.7577 18.867 17.0831 20.3923 19.0461C20.6495 19.3765 21.3741 19.33 21.5329 18.9725C22.4633 16.8774 24.8585 12.3056 28.0097 12.3056C30.4989 12.3056 32.6616 13.5191 31.4807 16.3832C30.7941 18.0473 27.0033 24.3643 24.3218 28.7633C23.9729 29.334 25.1314 29.7651 25.6681 29.2649C30.5951 24.6782 38.5458 16.9675 39.8429 13.827C41.9161 8.80322 38.1164 6.67053 33.7485 6.67053Z" fill="#B8003E"/>
</g>
</svg>
    </>
  )
}

export default Cardbgimage