import React, { useState, useEffect } from "react";
import { Dialog, DialogTitle, Divider, Grid, Stack } from "@mui/material";
import { color_exports } from "../Styles/Color_exports";
import Modifiedinputfield from "../Components/Modifiedinputfield";
import Modifiedbutton from "../Components/Modifiedbutton";
import CircleCheckbox from "../Components/Circlecheckbox";
import { creategroup_api, groupnameexists_api, itemnameexists_api, getpackages_api } from "../Apiservices/Adminservices";
import { enqueueSnackbar } from "notistack";
import { forget_styles } from "../Styles/Model_styles";

const Creategroup = (props) => {
  const forget = forget_styles();
  const { open, close } = props;
  const [groupName, setGroupName] = useState("");
  const [optionTitles, setOptionTitles] = useState([""]);
  const [itemData, setItemData] = useState([]);
  const [packages, setPackages] = useState([]);

  useEffect(() => {
    const fetchPackages = async () => {
      try {
        const response = await getpackages_api({ min: 0, max: 50 });
        if (response?.message === "All packages details retrieved successFully") {
          const fetchedPackages = response?.data?.rows || [];
          setPackages(fetchedPackages);

          // Initialize itemData with one empty option title and corresponding package data
          setItemData([{
            itemName: "",
            packageData: fetchedPackages.map(pkg => ({
              packageId: pkg.packageId,
              status: false,  // Initialize status to false (boolean)
              optionValue: "" // Initialize optionValue
            }))
          }]);
        } else {
          enqueueSnackbar(response?.message || "Failed to fetch packages", { variant: "error" });
        }
      } catch (error) {
        console.error("Error fetching packages:", error);
        enqueueSnackbar("Failed to fetch packages", { variant: "error" });
      }
    };

    fetchPackages();
  }, []);

  const handleInputChange = (setter) => (event) => {
    setter(event.target.value);
  };

  const handleOptionTitleChange = (index) => (event) => {
    const newOptionTitles = [...optionTitles];
    newOptionTitles[index] = event.target.value;
    setOptionTitles(newOptionTitles);

    const newItemData = [...itemData];
    newItemData[index].itemName = event.target.value;
    setItemData(newItemData);
  };

  const handleCheckboxChange = (optionIndex, packageId) => (event) => {
    const newItemData = [...itemData];
    const packageIndex = newItemData[optionIndex]?.packageData.findIndex((pkg) => pkg.packageId === packageId);
    if (packageIndex !== -1) {
      newItemData[optionIndex].packageData[packageIndex].status = event.target.checked; // Use boolean value
      setItemData(newItemData);
    }
  };

  const handleOptionValueChange = (optionIndex, packageId) => (event) => {
    const newItemData = [...itemData];
    const packageIndex = newItemData[optionIndex]?.packageData.findIndex((pkg) => pkg.packageId === packageId);
    if (packageIndex !== -1) {
      newItemData[optionIndex].packageData[packageIndex].optionValue = event.target.value; // Update option value
      setItemData(newItemData);
    }
  };

  const handleAddOption = () => {
    setOptionTitles([...optionTitles, ""]);
    setItemData([...itemData, {
      itemName: "",
      packageData: packages.map(pkg => ({
        packageId: pkg.packageId,
        status: false,  // Initialize status to false (boolean)
        optionValue: "" // Initialize optionValue
      }))
    }]);
  };

  const checkGroupAndItemNameExistence = async () => {
    try {
      const groupTitleExists = await groupnameexists_api({ groupName });
      if (groupTitleExists) {
        // enqueueSnackbar("Group Name already exists", { variant: "error" });
        // return true;
      }

      const optionTitlesExist = await itemnameexists_api({ itemNames: optionTitles });
      if (optionTitlesExist) {
        // enqueueSnackbar("Option Title already exists", { variant: "error" });
        // return true;
      }

      return false;
    } catch (err) {
      console.error("Error:", err);
      enqueueSnackbar(err.message, { variant: "error" });
      return true;
    }
  };

  const handleSubmit = async () => {
    try {
      const nameExists = await checkGroupAndItemNameExistence();
      if (nameExists) return;

      const req = {
        groupName,
        itemData: itemData.map(item => ({
          ...item,
          packageData: item.packageData.map(pkg => ({
            ...pkg,
            status: !!pkg.status // Ensure status is a boolean
          }))
        }))
      };
      console.log("Request Data:", req); // Log the request data

      const createGroupRes = await creategroup_api(req);

      if (createGroupRes?.message === "Group Data created successfully") {
        enqueueSnackbar(createGroupRes?.message, { variant: "success" });
        close();
      } else {
        console.log("Create Group failed:", createGroupRes?.message);
        enqueueSnackbar(createGroupRes?.message, { variant: "error" });
      }
    } catch (err) {
      console.error("Error:", err);
      enqueueSnackbar(err.message, { variant: "error" });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={close}
      PaperProps={{
        sx: {
          m: 0,
          width: 410,
          maxWidth: 410,
          borderRadius: "1rem",
          padding: "1rem 2rem",
          "::-webkit-scrollbar": { display: "none" },
          "@media screen and (min-width: 200px) and (max-width: 599px)": {
            width: "90%",
            maxWidth: "90%",
          },
        },
      }}
    >
      <DialogTitle>
        <Grid container direction={"column"} spacing={2}>
          <Grid item textAlign={"center"} fontSize={"16px"} fontWeight={600}>
            Create Group and Add Options
          </Grid>
        </Grid>
      </DialogTitle>
      <Grid container spacing={2} direction={"column"} justifyContent={"center"}>
        <Grid item>
          <Stack spacing={1}>
            <Grid item fontSize={"14px"} sx={{ color: color_exports.primary_color }}>
              Group Title
            </Grid>
            <Grid item>
              <Modifiedinputfield
                fullWidth
                placeholder="Enter here"
                size="small"
                value={groupName}
                onChange={handleInputChange(setGroupName)}
              />
            </Grid>
          </Stack>
        </Grid>

        {optionTitles.map((optionTitle, optionIndex) => (
          <Grid item key={optionIndex}>
            <Stack spacing={1}>
              <Grid item fontSize={"14px"} sx={{ color: color_exports.primary_color }}>
                Option Title {optionIndex + 1}
              </Grid>
              <Grid item>
                <Modifiedinputfield
                  fullWidth
                  placeholder="Enter here"
                  size="small"
                  value={optionTitle}
                  onChange={handleOptionTitleChange(optionIndex)}
                />
              </Grid>

              <Stack direction={"row"} spacing={1}>
                {packages.map((pkg) => (
                  <Grid item key={pkg.packageId}>
                    <CircleCheckbox
                      checked={itemData[optionIndex]?.packageData.find(pkgData => pkgData.packageId === pkg.packageId)?.status === true}
                      onChange={handleCheckboxChange(optionIndex, pkg.packageId)}
                    />
                    <span>{pkg.packageTitle}</span>
                    <Modifiedinputfield
                      placeholder="Enter value"
                      size="small"
                      value={itemData[optionIndex]?.packageData.find(pkgData => pkgData.packageId === pkg.packageId)?.optionValue || ""}
                      onChange={handleOptionValueChange(optionIndex, pkg.packageId)}
                      style={{ marginLeft: "8px" }}
                    />
                  </Grid>
                ))}
              </Stack>
            </Stack>
          </Grid>
        ))}

        <Divider />
        <Grid item md={"auto"} fontSize={"12px"} sx={{ color: color_exports.primary_color }} onClick={handleAddOption}>
          + Add Option
        </Grid>
        <Grid item>
          <Divider />
        </Grid>
        <Grid item container justifyContent={"center"} gap={2}>
          <Modifiedbutton
            data={"Cancel"}
            bgcolor={"#F2F2F2"}
            styles={{ color: "#9F9F9F" }}
            onClick={close}
          />
          <Modifiedbutton data={"Create"} onClick={handleSubmit} />
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default Creategroup;
