import React, { useState } from "react";
import { Grid, Paper, Stack, Typography } from "@mui/material";
import Modifiedbutton from "../Components/Modifiedbutton";
import Editstorycomp from "./Editstory";
import { viewstyles } from "../Styles/Component_styles";
import user1 from "../assets/Images/user1.png";

const Viewstorycomp = ({ color_exports, story }) => {
  
  const viewstyle = viewstyles();
  const [editMode, setEditMode] = useState(false);
  const imageBaseUrl = story.imageBaseUrl;

  const handleEditClick = () => {
    setEditMode(true);
  };

  const calculateAge = (dateOfBirth) => {
    const today = new Date();
    const birthDate = new Date(dateOfBirth);
    let age = today.getFullYear() - birthDate.getFullYear();
    const month = today.getMonth() - birthDate.getMonth();
    if (month < 0 || (month === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  return (
    <Grid container spacing={2}>
      {story ? (
        editMode ? (
          <Editstorycomp
            color_exports={color_exports}
            story={story}
            close={() => setEditMode(false)}
          />
        ) : (
          <Grid item md={12} mb={3}>
            <Paper elevation={5} className={viewstyle.paper}>
              <Grid container direction={"column"} spacing={2}>
                <Grid item container>
                  <Grid item md={"auto"}>
                    <img
                      src={
                        story.profileImages[0]?.image1
                          ? `${imageBaseUrl}/${story.profileImages[0].image1}`
                          : user1
                      }
                      alt="profile"
                      className={viewstyle.viewstoryprflimg}
                    />
                  </Grid>
                  <Grid
                    item
                    container
                    direction={"column"}
                    md
                    ml={2}
                    alignSelf={"center"}
                  >
                    <Grid item fontSize={"14px"} fontWeight={700}>
                      {story.firstName} {story.lastName}, {calculateAge(story.dateOfBirth)}
                    </Grid>
                    <Grid item fontSize={"12px"} fontWeight={"300"}>
                      {story.gender}, {story.height}ft,
                    </Grid>
                    <Grid item fontSize={"12px"} fontWeight={"300"}>
                      {story.permanentCity}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid
                    item
                    md={4}
                    sx={{ color: color_exports.opacity_text_color }}
                  >
                    Bride
                  </Grid>
                  <Grid item md={4}>
                    {story.brideName}
                  </Grid>
                  <Grid
                    item
                    md={2}
                    sx={{ color: color_exports.opacity_text_color }}
                  >
                    JMID
                  </Grid>
                  <Grid
                    item
                    md={2}
                  >
                    JM00100{story.brideJmId}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid
                    item
                    md={4}
                    sx={{ color: color_exports.opacity_text_color }}
                  >
                    Groom
                  </Grid>
                  <Grid item md={4}>
                    {story.groomName}
                  </Grid>
                  <Grid
                    item
                    md={2}
                    sx={{ color: color_exports.opacity_text_color }}
                  >
                    JMID
                  </Grid>
                  <Grid
                    item
                    md={2}
                  >
                    JM00100{story.groomJmId}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid
                    item
                    md={4}
                    sx={{ color: color_exports.opacity_text_color }}
                  >
                    Engagement Date
                  </Grid>
                  <Grid item md>
                    {story.engagementDate}
                  </Grid>
                </Grid>
                <Grid item container>
                  <Grid
                    item
                    md={4}
                    sx={{ color: color_exports.opacity_text_color }}
                  >
                    Wedding Date
                  </Grid>
                  <Grid item md>
                    {story.marriageDate}
                  </Grid>
                </Grid>
                <Grid item container mt={2}>
                  <Stack spacing={1}>
                    <Grid item sx={{ color: color_exports.opacity_text_color }}>
                      My Success Story
                    </Grid>
                    <Grid item fontSize={"12px"}>
                      {story.successStory}
                    </Grid>
                  </Stack>
                </Grid>
                <Grid item container mt={2}>
                  <Stack spacing={1}>
                    <Grid item sx={{ color: color_exports.opacity_text_color }}>
                      Our Happy Moments
                    </Grid>
                    <Grid item container spacing={1}>
                      {/* Display uploaded images */}
                      {story.successStoryImages &&
                        story.successStoryImages.map((image, index) => (
                          <Grid item md={3} key={index}>
                            <img
                              src={`${story.storyBaseUrl}/${image.image1 || image.image2 || image.image3 || image.image4 || image.image5}`}
                              alt={`Happy Moment ${index + 1}`}
                              className={viewstyle.happymomentimages}
                            />
                          </Grid>
                        ))}
                    </Grid>
                  </Stack>
                </Grid>
                <Grid item md ml={1} mt={2}>
                 { story?.isAdmin ==true? 
                 <>
                 <Modifiedbutton
                    swidth={"8rem"}
                    sheight={"2.5rem"}
                    data={"Edit"}
                    onClick={handleEditClick}
                  />
                 </>
                 :
                 <></>
                 }
                 
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        )
      ) : (
        <Grid item xs={12}>
          <Typography variant="h6" align="center">
            No story found
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};

export default Viewstorycomp;
