import { enqueueSnackbar } from "notistack";
import { Regex_exports } from "../Helpers/Regularepressions";

export const emptystringcheck = (data, name) => {
  if (data.trim() === "") {
    enqueueSnackbar(`Oops! You forgot to enter your ${name}. Please provide it.`, { variant: 'warning' });
    return true;
  }
};

export const emailchecking = (data, name) => {
  if (data.trim() === "" || !data.trim()) {
    enqueueSnackbar(`Please fill out ${name} field to proceed`, { variant: 'warning' });
    return true;
  } else if (!Regex_exports.emailRegex.test(data)) {
    enqueueSnackbar(`Oops! It looks like the ${name} you entered is not valid. Please double-check it`, { variant: 'warning' });
    return true;
  } else {
    return false;
  }
};

export const passwordchecking = (data, name, type) => {
  let message = `Oops! It looks like the ${name} you entered is not valid. Please double-check it`;
  if (type === 1) {
    message = "Your password is weak. Please create a stronger one with at least 8 characters, including numbers, letters, and special characters.";
  }
  if (data.trim() === "" || !data.trim()) {
    enqueueSnackbar(`Oops! You forgot to enter your ${name}. Please provide it.`, { variant: 'warning' });
    return true;
  } else if (!Regex_exports.passwordRegex.test(data)) {
    enqueueSnackbar(message, { variant: 'warning' });
    return true;
  } else {
    return false;
  }
};
export const namechecking = (data, name) => {
  if (data.trim() === "") {
    enqueueSnackbar(`Oops! You forgot to enter your ${name}. Please provide it.`,{variant:'warning'});
    return true;
  } else if (!data.trim()) {
    // This condition checks if the trimmed data is an empty string, which means it consists only of spaces
    enqueueSnackbar(`Invalid ${name}. Please provide a non-empty value.`,{variant:'warning'});
    return true;
  } else if (Regex_exports.string_pattern.test(data)) {
    enqueueSnackbar(`${name} should be string`,{variant:'warning'});
    return true;
  } else {
    return false;
  }
};
export const mobilechecking = (data, name) => {
  if (data.trim() === "" || !data.trim()) {
    enqueueSnackbar(`Please fill out ${name} field to proceed`,{variant:'warning'});
    return true;
  } else if (!Regex_exports.mobile_regex.test(data)) {
    enqueueSnackbar(`Oops! It looks like the ${name} you entered is not valid. Please double-check it
    `,{variant:'warning'});
    return true;
  } else {
    return false;
  }
};
export const castingcall_requirementsvalidation = (carddata) => {
  if (carddata?.gender === "" || carddata?.age === "" || Object.keys(carddata?.skinTone).length === 0 ||
    Object.keys(carddata?.height).length === 0 || carddata?.roleName === "" || carddata?.roleDescription === "") {
    enqueueSnackbar("Please fill out all the required fields", { variant: 'warning' });
    return true;
  } else {
    return false;
  }
};


