import React from "react";
import { PieChart } from "@mui/x-charts";
import { reporteditorstyles } from "../Styles/Contentmanagement_styles";

const Revenuepiechart = ({
  pieHeight = 300,
  pieWidth = 500,
  outerData = [],
  innerRadius = 45,
  outerRadius = 80,
  hideLegend = true,
  legendDirection = "row",
  legendPositionV = "bottom",
  legendPositionH = "bottom",
  revenueCount = 0,
}) => {
  const hasData = revenueCount > 0;
  const reporteditorstyle = reporteditorstyles();

  return (
    <div>
      {hasData ? (
        <div style={{ marginLeft: "-2rem" }}>
          <PieChart
            height={pieHeight}
            width={pieWidth}
            series={[
              {
                data: outerData,
                innerRadius: innerRadius,
                outerRadius: outerRadius,
              },
            ]}
            slotProps={{
              legend: {
                direction: legendDirection,
                position: { vertical: legendPositionV, horizontal: legendPositionH },
                hidden: hideLegend,
                marginLeft: "10px",
              },
            }}
          />
          <div style={{ marginLeft: "8rem", marginTop: "-3rem", marginBottom: "1rem" }}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <div
                style={{
                  width: "15px",
                  height: "15px",
                  backgroundColor: "rgb(2, 178, 175)",
                  marginRight: "8px",
                }}
              ></div>
              <div>Revenue Count: {revenueCount}</div>
            </div>
          </div>
        </div>
      ) : (
        <div className={reporteditorstyle.noresults1}>No data found</div>
      )}
    </div>
  );
};

export default Revenuepiechart;
