import React from "react";

const Calendreicon = () => {
  return (
    <>
      <svg
        width="16"
        height="17"
        viewBox="0 0 16 17"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M14.0312 1.76062H11.6562V0.57312H10.4688V1.76062H5.71875V0.57312H4.53125V1.76062H2.15625C1.50313 1.76062 0.96875 2.295 0.96875 2.94812V14.8231C0.96875 15.4762 1.50313 16.0106 2.15625 16.0106H14.0312C14.6844 16.0106 15.2188 15.4762 15.2188 14.8231V2.94812C15.2188 2.295 14.6844 1.76062 14.0312 1.76062ZM14.0312 14.8231H2.15625V6.51062H14.0312V14.8231ZM14.0312 5.32312H2.15625V2.94812H4.53125V4.13562H5.71875V2.94812H10.4688V4.13562H11.6562V2.94812H14.0312V5.32312Z"
          fill="#B8003E"
        />
      </svg>
    </>
  );
};

export default Calendreicon;
