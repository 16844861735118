/** @format */
import { jwtDecode } from "jwt-decode";

export const StorageClient = {
  getItem: (key) => {
    try {
      const serializedItem = localStorage.getItem(key);
      return serializedItem ? JSON.parse(serializedItem) : undefined;
    } catch (error) {
      console.error(`Error getting item from Storage: ${error.message}`);
      return undefined;
    }
  },
  
  setItem: (key, value) => {
    try {
      localStorage.setItem(key, JSON.stringify(value));
    } catch (error) {
      console.error(`Error setting item in Storage: ${error.message}`);
    }
  },
  //will destroy all application storage
  clear: () => {
    try {
      localStorage.clear();
    } catch (error) {
      console.error(`Error clearing Storage: ${error.message}`);
    }
  },
  // Remove specific item from storage
  removeItem: (key) => {
    try {
      localStorage.removeItem(key);
    } catch (error) {
      console.error(`Error removing item from Storage: ${error.message}`);
    }
  },
};
 /**
* Checking the filetype using mime type in files uploaded
* @param {object} file
* @returns string
*/
export const filetype_checking = async (file) => {
 // Check the file's MIME type
 const fileType = file.type;

 // Check if the file is an image
 if (fileType.startsWith("image")) {
   console.log("File is an image");
   return "image";
   // Handle image file
 }
 // Check if the file is a video
 else if (fileType.startsWith("video")) {
   console.log("File is a video");
   return "video";
   // Handle video file
 }
 // Check if the file is a PDF
 else if (fileType === "application/pdf") {
   console.log("File is a PDF");
   return 4;
   // Handle PDF file
 }
 // File type is not recognized
 else {
   console.log("Unsupported file type");
   return 5;
   // Handle unsupported file type
 }
};

/**
 * Thumbnail extraction from the video
 * @param {object} uploadfile
 * @returns
 */
export const handleVideoUpload = async (file, seekTo = 0.0) => {
  return new Promise((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement("video");
    videoPlayer.setAttribute("src", URL.createObjectURL(file));
    videoPlayer.load();
    videoPlayer.addEventListener("error", (ex) => {
      reject("error when loading video file", ex);
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener("loadedmetadata", () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject("video is too short.");
        return;
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener("seeked", () => {
        console.log("video is now paused at %ss.", seekTo);
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement("canvas");
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        // draw the video frame to canvas
        const ctx = canvas.getContext("2d");
        ctx.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // return the canvas image as a blob
        ctx.canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          "image/jpeg",
          0.75 /* quality */
        );
      });
    });
  });
};

export const isAccessTokenExpired =() => {
  const expiration = localStorage.getItem("token");
  // console.log(expiration,"expiration")
  if(!expiration){
    return false;
  }
  const authDecodedData = jwtDecode(expiration);
  const currentTimeInMilliseconds = new Date().getTime();
  const expirationTimeInSeconds = Number(authDecodedData.exp);
  const expirationTimeInMilliseconds = expirationTimeInSeconds * 1000; // Convert to milliseconds
  // Check if the token has expired
  const hasExpired = currentTimeInMilliseconds > expirationTimeInMilliseconds;
  return hasExpired;
}
