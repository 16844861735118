import axios from "./Janathaclient";
import { Envexports } from "../Exports/Envexports";
import { StorageClient } from "../Helpers/Helperfunctions";

const Adminapiservice = async (endpoint, data, method, contentType) => {
  try {
    const token = StorageClient.getItem("token");
    const refreshtoken = StorageClient.getItem("refreshtoken");
    const defaultAuthKey = "ACZTq7voBzX7jERFpHJECvqd3BWCW6BC";

    const headers = {
      "Content-Type": contentType,
    };

    // Handle authorization based on the endpoint and presence of tokens
    if (token) {
      if (["adminRefreshToken"].includes(endpoint)) {
        headers.Authorization = refreshtoken ? `Bearer ${refreshtoken}` : `${defaultAuthKey}`;
      } else {
        headers.Authorization = `Bearer ${token}`;
      }
    } else {
      headers.Authorization = `Bearer ${defaultAuthKey}`;
    }

    const response = await axios({
      method: method,
      url: `${Envexports.baseurl}/${endpoint}`,
      data: data,
      headers: headers,
    });
    return response.data;
  } catch (error) {
    if (error.response) {
      throw error.response.data;
    } else if (error.request) {
      throw new Error("No response from server");
    } else {
      throw new Error("Request failed");
    }
  }
};

export default Adminapiservice;
