import React from 'react'
import { color_exports } from '../Styles/Color_exports';
import { TextField } from '@mui/material';
import { custominputstyles } from '../Styles/Customcomponentstyles';

const Modifiedtextarea = (props) => {
    const {onChange,...otherprops}=props;
    const commoninputstyle = custominputstyles();
    const handleinputchange = (event) => {
      let newValue = event.target.value ?? "";
      newValue = newValue.charAt(0).toUpperCase()+newValue.slice(1);
      if (props.onChange) {
        props.onChange({
          ...event,
          target: { ...event.target, value: newValue,name:props?.name ?? "" },
        });
      }
    };
    return (
      <>
        <TextField
          className={commoninputstyle.logininputstyle}
          multiline
          sx={{
            "& .MuiOutlinedInput-root": {
              background: `${
                props.bg_color === undefined || !props?.bg_color
                  ? color_exports.input_color
                  : props.bg_color
              }!important`,
              borderRadius: `0.5rem`,
              "& fieldset": {
                border: "none", 
              },
            },
            "& .MuiFormHelperText-root": {
              color: "#333333",
              opacity: "0.5",
            },
          }}
          onChange={handleinputchange}
          {...otherprops}
        />
      </>
    );
}

export default Modifiedtextarea