import React, {useEffect, useState} from 'react';
import { Grid, Paper } from '@mui/material';
import { getlocatedusers_api } from '../Apiservices/Adminservices';
import { enqueueSnackbar } from 'notistack';
import { dashboardstyles } from '../Styles/Tablistindashboardstyle';

const Toplocationusers = () => {
    const liststyle = dashboardstyles();
    const [topLocations, setTopLocations] = useState([]);
  useEffect(() => {
    const fetchTopLocations = async () => {
      try {
        const response = await getlocatedusers_api();

        if (response && response.message === "Successfully retrived  top Location Users data") {
          setTopLocations(response.data); // Set topLocations state here
          // enqueueSnackbar(response.message, { variant: "success" });
        } else {
          console.error(response?.message || "Failed to fetch top locations");
          enqueueSnackbar(response?.message || "Failed to fetch top locations", {
            variant: "error",
          });
        }
      } catch (err) {
        console.error("Error:", err);
        enqueueSnackbar(err.message || "Failed to fetch top locations", {
          variant: "error",
        });
      }
    };

    fetchTopLocations();
  }, []);
    return (
        <Grid>
              
              <Grid container direction={"column"} gap={2.5} sx={{ padding: "1rem" }}>
                <Grid item container justifyContent={"space-between"}>
                  <Grid item md={6} fontWeight={700}>
                    Location
                  </Grid>
                  <Grid item md={6} container justifyContent={"end"} fontWeight={700}>
                    Users
                  </Grid>
                </Grid>
                <Grid item container direction={"column"} spacing={1}>
                  {Array.isArray(topLocations) && topLocations.map((item, index) => (
                    <Grid item container justifyContent={"space-between"} key={index}>
                      <Grid item md={6}>
                        {item.permanentCity}
                      </Grid>
                      <Grid item md={6} container justifyContent={"end"}>
                        {item.count}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </Grid>
        </Grid>
    )
}

export default Toplocationusers;