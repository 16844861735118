import React from "react";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { InputAdornment } from "@mui/material";
import Modifiedinputfield from "./Modifiedinputfield";
import Calendreicon from "../assets/Icons/Calendreicon";
import { color_exports } from "../Styles/Color_exports";

const Modifieddate = ({ placeholder, bg_color, ...otherprops }) => {
  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <MobileDatePicker
          inputFormat="dd/MM/yyyy"
          sx={{
            "& .MuiPickersToolbar-penIconButton": {
              display: "none",
            },

            "&.MuiButtonBase-root-MuiPickersDay-root.Mui-selected": {
              backGroundColor: `${color_exports.primary_color}!important`,
            },
          }}
          closeOnSelect
          {...otherprops}
          renderInput={(params) => (
            <Modifiedinputfield
              id="dateInputTag"
              {...params}
              fullWidth
              // bg_color
              bg_color={bg_color}
              placeholder={placeholder}
              autoComplete="off"
              size="medium"
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="start">
                    <Calendreicon />
                    {/* <img src={calendreicon} alt="icon" /> */}
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </LocalizationProvider>
    </>
  );
};

export default Modifieddate;
