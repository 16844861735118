import React, { useState, useEffect } from "react";
import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import viewicon from "../assets/Images/viewicon.png";
import blockicon from "../assets/Images/blockicon.png";
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import { useNavigate } from "react-router-dom";
import Block_or_Delet from "../Models/Block_or_Delet";
import { viewuser_api, viewuser_api2 } from "../Apiservices/Adminservices";
import { enqueueSnackbar } from "notistack";
import { userdetail_styles } from "../Styles/Adminuser_details_styles";
import { useSelector } from "react-redux";

const Modifiedtable = (props) => {
  const { maxheight, rows, columns, handleSort } = props;
  const [sortDirections, setSortDirections] = useState(
    Array(columns.length).fill("asc")
  );
  const [blockPopupOpen, setBlockPopupOpen] = useState(false);
  const [unblockPopupOpen, setUnblockPopupOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [action, setAction] = useState(null); // State for action
  const [userStatus, setUserStatus] = useState({}); // State to track user statuses
  const navigate = useNavigate();
  const details_style = userdetail_styles();
  const admin_data = useSelector((state) => state?.authentication?.data);

  useEffect(() => {
    // Initialize userStatus with rows data
    const status = {};
    console.log("Rows data:", rows);
    rows.forEach(row => {
      status[row.userId] = row?.blockStatus ? 1 : 0; // Ensure `accountStatus` is available
    });
    setUserStatus(status);
  }, [rows]);

  const handleBlockIconClick = (row) => {
    setSelectedRow(row);
    setAction("block"); // Set action to "block"
    setBlockPopupOpen(true);
  };

  const handleUnblockIconClick = (row) => {
    setSelectedRow(row);
    setAction("unblock"); 
    setUnblockPopupOpen(true);
  };

  const handleViewIconClick = async (userId) => {
    try {
      const response = await viewuser_api({ userId });
      if (response?.message === "User Details Retrived successfully") {
        const response2 = await viewuser_api2({ userId, period: "all" });
        if (response2?.message === "Successfully retrived dashboard list") {
          navigate("/admin/userdetails", {
            state: { userData: response.data, dashboardData: response2.data },
          });
        } else { enqueueSnackbar(response2?.message, { variant: "error" }); }
      } else { enqueueSnackbar(response?.message, { variant: "error" }); }
    } catch (error) { enqueueSnackbar(error.message, { variant: "error" }); }
  };

  const updateUserStatus = (userId, status) => {
    console.log("Updating user status:", userId, status,"userStatus",userStatus);
    setUserStatus(prev => ({
      ...prev,
      [userId]: status
    }));
  };

  return (
    <>
      <TableContainer sx={{ maxHeight: maxheight === undefined ? 440 : maxheight, borderRadius: "1rem", overflow: "auto" }}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column, index) => (
                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth, backgroundImage: `linear-gradient(to top, #FFFFFF, #F9CDDC)`, borderBottom: "3px solid #fff", padding: "0px 16px", color: "#333333", fontWeight: 700, fontSize: "16px", height: "46px",
                    borderRight: "1px solid rgba(51, 51, 51, 0.2)" }} sx={{
                    "&::before": {
                      content: '""',
                      position: "absolute",
                      inset: 0,
                      borderRadius: 0,
                      background: "linear-gradient(to top, #FFFFFF, #B8003E)",
                      zIndex: -1,
                      width: "1px",
                      right: "0px !important",
                      left: "unset",
                    },
                  }}
                >
                  <Grid container justifyContent="space-between" alignItems="center" >
                    {column.label}
                    {column.sortable && (
                      <Grid item>
                        {sortDirections[index] === "asc" ? (
                          <ArrowUpwardIcon onClick={() => { const newSortDirections = [...sortDirections]; newSortDirections[index] = "desc"; setSortDirections(newSortDirections); handleSort(column.id); }} />
                        ) : (
                          <ArrowDownwardIcon
                            onClick={() => { const newSortDirections = [...sortDirections]; newSortDirections[index] = "asc"; setSortDirections(newSortDirections); handleSort(column.id); }} />
                        )}
                      </Grid>
                    )}
                  </Grid>
                </TableCell>
              ))}
              <TableCell className={details_style.cellsty} style={{ borderRight: "1px solid rgba(51, 51, 51, 0.2)", fontWeight: "700", fontSize: "16px" }}> Action </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row) => (
              <TableRow hover key={row.userId}>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align} style={{ borderRight: "1px solid rgba(51, 51, 51, 0.2)" }}>
                    {row[column.id]}
                  </TableCell>
                ))}
                <TableCell style={{ borderRight: "1px solid rgba(51, 51, 51, 0.2)", textAlign: "center" }}>
                  <img src={viewicon} alt="View" style={{ cursor: "pointer"}} onClick={() => handleViewIconClick(row.userId)} />
                  {admin_data?.isUserAdmin && (
                    userStatus?.[row?.userId] === 1 ?(
                      <RemoveCircleOutlineIcon style={{ cursor: "pointer", paddingLeft: "4px", width: "18px", color: "#B8003E" }} onClick={() => handleUnblockIconClick(row)} /> 
                    ) : (
                      <img src={blockicon} alt="Block" style={{ cursor: "pointer", paddingLeft: "4px" }} onClick={() => handleBlockIconClick(row)} />
                    )
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Block_or_Delet open={blockPopupOpen} close={() => setBlockPopupOpen(false)} action={action} selectedRow={selectedRow} onStatusUpdated={updateUserStatus} />
      <Block_or_Delet open={unblockPopupOpen} close={() => setUnblockPopupOpen(false)} action={action} selectedRow={selectedRow} onStatusUpdated={updateUserStatus} />
    </>
  );
};

export default Modifiedtable;
